import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { omit, forOwn, filter } from 'lodash-es'
import { loadCollectionSuccess, search } from '../actions/partner.actions'

export const partnersStateFeatureKey = 'partnerState'

export interface Partner {
	id: number
	name: string
	domain: string
}

export interface PartnerState extends EntityState<Partner> {
	loading: any
	search: any
}

export const partnerAdapter: EntityAdapter<Partner> = createEntityAdapter<Partner>()

export const partnerInitialState = partnerAdapter.getInitialState({
	loading: undefined,
	search: {}
})

export const partnerReducers = createReducer(
	partnerInitialState,
	on(loadCollectionSuccess, (state, action) => {
		return partnerAdapter.upsertMany(action.data, {
			...state,
			search: {
				...state.search,
				from: action.from || 'api',
				total: action.total
			}
		})
	}),
	on(search, (state, action) => {
		return {
			...state,
			search: { ...state.search, ...action.params, from: undefined }
		}
	})
)

export const selectPartnerFeature = createFeatureSelector<PartnerState>(
	partnersStateFeatureKey
)

export const selectSearch = createSelector(
	selectPartnerFeature,
	(state: PartnerState) => state.search
)

export const selectAllPartners = createSelector(
	selectPartnerFeature,
	partnerAdapter.getSelectors().selectAll
)

export const selectPartnerById = (id: number) =>
	createSelector(
		selectPartnerFeature,
		(state: PartnerState) => state.entities[id]
	)

export const selectAllPartnersForResolver = createSelector(
	selectSearch,
	selectAllPartners,
	(search: any, partners: Partner[]) => {
		if (!search.total) {
			return { items: [], from: search.from }
		}
		return { items: partners, from: search.from }
	}
)

export const selectPartnersByParams = createSelector(
	selectSearch,
	selectAllPartners,
	(search: any, partners: Partner[]) => {
		let filteredItems = filter(partners, (item) => {
			let match: boolean = false
			forOwn(omit(search, ['from']), (val, key) => {
				if (item[key] == val) {
					match = true
				}
			})
			return match
		})

		return { items: filteredItems, from: search.from }
	}
)
