<main class="auth-container text-center d-flex flex-column">
	<div class="flex-grow-1">
		<div class="auth-form d-flex align-items-center justify-content-center">
			<router-outlet></router-outlet>
		</div>
	</div>
	<div class="footer">
		<p>©{{ currentYear }} Engage People Inc.</p>
		<span class="version">{{ version }}</span>
	</div>
</main>
