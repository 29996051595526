import { ActionReducerMap } from '@ngrx/store'
import { userReducers, UserState } from './user.reducer'
import { layoutReducers, LayoutState } from './layout.reducer'
import { routeReducer, RouteState } from './route.reducer'
import { partnerReducers, PartnerState } from './partner.reducer'
import { orderReducers, OrderState } from './order.reducer'
import { searchReducers, SearchState } from './advanced-search.reducer'
import {
	configurationTypeReducers,
	ConfigurationTypeState
} from './program-configuration-type.reducer'
import { countryReducers, CountryState } from './country.reducer'

export interface AppState {
	userState: UserState
	layoutState: LayoutState
	routerState: RouteState
	partnerState: PartnerState
	orderState: OrderState
	searchState: SearchState
	configurationTypeState: ConfigurationTypeState
	countryState: CountryState
}

export const reducers: ActionReducerMap<AppState> = {
	userState: userReducers,
	layoutState: layoutReducers,
	routerState: routeReducer,
	partnerState: partnerReducers,
	orderState: orderReducers,
	searchState: searchReducers,
	configurationTypeState: configurationTypeReducers,
	countryState: countryReducers
}
