import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: '[disableMinutes]',
})
export class DisableMinutesDirective implements AfterViewInit{

    constructor(private elementRef: ElementRef) {
    }


    ngAfterViewInit(): void {
        let minute_input = this.elementRef.nativeElement.querySelector('.ngb-tp-minute input')
        if(minute_input){
            minute_input.disabled = true
        }
    }


}
