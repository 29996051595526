<label [for]="id" [class]="isRequired ? 'required' : ''">
	{{ label }}
	<span *ngIf="isRequired" class="asterisk">*</span>
	<span *ngIf="helperText" class="helper-text">({{ helperText }})</span>
</label>
<div class="r-multi-input input-container multi-select">
	<div class="r-multi-select">
		<ul class="options-selected">
			<li *ngFor="let item of selectedItems; let i = index">
				<span id="{{ id + '-input--' + i }}">{{ item }}</span>
				<button
					[attr.aria-label]="'click to remove' | translate"
					(click)="removeItem(item)"
					[attr.aria-describedby]="id + '-input--' + i"
				>
					<span class="icon-close"></span>
				</button>
			</li>
		</ul>
		<input
			#myInput
			[id]="id"
			[type]="type"
			role="combobox"
			[placeholder]="placeholder"
			(blur)="onBlur($event)"
			(keydown)="executeKeydown($event)"
			[attr.aria-describedby]="
				id + '-multi-input__assistiveHint' + ' ' + id + '-error'
			"
		/>
	</div>
	<custom-button
		(onClick)="addValue()"
		css="last outline secondary last"
		[label]="'Add' | translate"
	></custom-button>
	<span id="{{ id + '-multi-input__assistiveHint' }}" class="sr-only">
		Press the commma key on your keyboard or the next button to add your inputs
		one by one
	</span>
</div>
<error-field
	[inputKey]="id"
	[inputName]="label | translate"
	[error]="theControl"
></error-field>
