import { Component, OnInit, Input, Self, Optional, Inject } from "@angular/core";
import {
	ControlValueAccessor,
	NgControl,
	AbstractControl
} from '@angular/forms'
import { DOCUMENT } from "@angular/common";


@Component({
	selector: 'custom-form-input',
	templateUrl: './custom-form-input.component.html',
	styleUrls: ['./custom-form-input.component.scss']
})
export class CustomFormInputComponent implements OnInit, ControlValueAccessor {
	@Input() id: string
	@Input() type: 'text' | 'email' | 'number' | 'password' = 'text'
	@Input() label: string
	@Input() errorName: string = ''
	@Input() showLabel: boolean = true
	@Input() css: string
	@Input() describedBy: string
	@Input() labelledBy: string = null
	@Input() cssLabel: string = ''
	@Input() placeholder: string = ''
	@Input() helperText: string = ''
	@Input() addon2: string = null
	@Input() disabled: boolean
	@Input() showOptional: boolean = true
	@Input() showPassword: boolean = false
	@Input() availablePlaceholders: any[]
	@Input() placeholderStyle: string = 'button'
	@Input() currentLang: string = 'en-CA'
	@Input() tooltip: string = ''
	selectedPlaceHolder: any

	value: any = ''

	constructor(
		@Inject(DOCUMENT) private document: Document,

	// Retrieve the dependency only from the local injector,
		// not from parent or ancestors.
		@Self()
		// We want to be able to use the component without a form,
		// so we mark the dependency as optional.
		@Optional()
		private ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this
		}
	}

	ngOnInit() {}

	/**
	 * Write form value to the DOM element (model => view)
	 */
	writeValue(value: any): void {
		this.value = value
	}

	/**
	 * Write form disabled state to the DOM element (model => view)
	 */
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	/**
	 * Update form when DOM element value changes (view => model)
	 */
	registerOnChange(fn: any): void {
		// Store the provided function as an internal method.
		this.onChange = fn
	}

	/**
	 * Update form when DOM element is blurred (view => model)
	 */
	registerOnTouched(fn: any): void {
		// Store the provided function as an internal method.
		this.onTouched = fn
	}

	onChange(value: any) {}

	onTouched() {}


	get theControl() {
		return this.ngControl
	}

	get theValidator() {
		if (typeof this.ngControl.control.validator === 'function') {
			return this.ngControl.control.validator({} as AbstractControl)
		}
		return null
	}

	get isRequired() {
		return this.theValidator ? this.theValidator.required : false
	}

	get isValid() {
		// when contol set to disabled, this.ngControl.valid became false
		return (
			!this.ngControl.touched || this.ngControl.valid || this.ngControl.disabled
		)
	}

	get isDisabled() {
		return this.ngControl.disabled
	}

	get labelClass() {
		let theClass: string = this.cssLabel
		if (this.isRequired) {
			theClass += ' required '
		}

		if (!this.showLabel) {
			theClass += 'sr-only '
		}
		return theClass
	}

	addPlaceholder(value=null){
		let selectionStart = this.document.querySelector("#"+this.id)['selectionStart']
		this.value=(this.value?.substr(0,selectionStart) || "") + '{{' + (value || this.selectedPlaceHolder) + '}}' +(this.value?.substr(selectionStart,this.value?.length) || "");
		setTimeout(()=>{this.selectedPlaceHolder = null},0)
	}

	changeType(){
		switch (this.type){
			case 'text':
				this.type = 'password'
				break;
			case 'password':
				this.type = 'text'
				break;
		}

	}
}
