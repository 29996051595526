export namespace JQueryBuilder {

  export interface Options {
    filters: Array<Filters>;
    plugins?: object;
    rules?: object;
    optgroups?: object;
    default_filter?: string;
    sort_filters?: boolean;
    allow_groups?: boolean;
    allow_empty?: boolean;
    display_errors?: boolean;
    conditions?: Array<string>;
    default_condition?: string;
    inputs_separator?: string;
    display_empty_filter?: boolean;
    select_placeholder?: string;
    operators?: Array<object>;
    lang_code?: string;
    lang?: object;
    icons?: object;
    templates?: object;
  }

  export interface Filters {
    id: string;
    type: FILTER_TYPES;
    values?: Array<string> | object;
    field?: string;
    label?: string;
    optgroup?: string | object;
    input?:  FILTER_INPUTS;
    value_separator?: string;
    default_value?: any;
    input_event?: string;
    size?: number;
    rows?: number;
    multiple?: boolean;
    vertical?: boolean;
    placeholder?: string;
    validation?: object;
    operators?: Array<OPERATORS>;
    plugin?: string;
    plugin_config?: object;
    data?: object;
    valueSetter?: (rule: string, value: string) => void;
    valueGetter?: (rule: string) => void;
  }

  export interface Group {
    condition: CONDITIONS;
    filters?: any //Array<Group | Rule > ;
    rules: any; //Array<Group | Rule > ;
  }

  export interface Rule {
    field: string;
    id: string;
    input: FILTER_INPUTS;
    operator: OPERATORS;
    operator_name?: OPERATORS;
    type: FILTER_TYPES;
    value: Array<any> | string;
  }

  export interface APISegmentationRule {
    group_filter_id: number,
    operator: string,
    gate: CONDITIONS,
    values?: Array<any>;
    value?: any;
  }

  export interface APISegmentations {
    gate: APISegmentationRule;
    rules: Array<APISegmentations>;
  }

  export interface APIGroup {
    gate: CONDITIONS;
    filters: Array<APIRule> ;
    children: Array<APIGroup>;
  }

  export interface APIRule {
    id?: string;
    product_filterable_id: string;
    column: string;
    operator?: string;
    operator_name?: string;
    values: Array<any>;
    value: any;
  }

  export enum CONDITIONS {
    AND = 'AND',
    OR = 'OR'
  }

  export enum FILTER_INPUTS {
    TEXT = 'text',
    NUMBER = 'number',
    TEXTAREA = 'textarea',
    RADIO = 'radio',
    CHECKBOX = 'checkbox ',
    SELECT = 'select',
  }
  export enum FILTER_TYPES {
    STRING = 'string',
    INTEGER = 'integer',
    DOUBLE = 'double',
    SELECT = 'select',
    DATE = 'date',
    TIME = 'time ',
    DATETIME = 'datetime',
    BOOLEAN = 'boolean',
  }
  export enum OPERATORS {
    EQUAL = 'equal',
    NOT_EQUAL = 'not_equal',
    LESS = 'less',
    LESS_OR_EQUAL = 'less_or_equal',
    GREATER = 'greater',
    GREATER_OR_EQUAL = 'greater_or_equal',
    IN = 'in',
    NOT_IN = 'not_in',
    CONTAINS = 'contains',
    NOT_CONTAINS = 'not_contains',



    // BETWEEN = 'between',
    // NOT_BETWEEN = 'not_between',
    // BEGINS_WITH = 'begins_with',
    // NOT_BEGINS_WITH = 'not_begins_with',

    // NOT_CONTAINS = 'not_contains',
    // ENDS_WITH = 'ends_with',
    // NOT_ENDS_WITH = 'not_ends_with',
    // IS_EMPTY = 'is_empty',
    // IS_NOT_EMPTY = 'is_not_empty',
    // IS_NULL = 'is_null',
    // IS_NOT_NULL = 'is_not_null',

  }

}
