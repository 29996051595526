<div role="radiogroup" [attr.aria-labelledby]="group_id">
	<label
		#labels
		*ngFor="let item of radioGroup; let i = index"
		(click)="selectItem($event, item)"
		(blur)="onBlur($event)"
		(keydown)="executeKeydown($event, item)"
		role="radio"
		[class.disabled]="isDisabled"
		[tabIndex]="isDisabled ? -1 : selectedIndex === i ? 0 : -1"
		[attr.aria-checked]="selectedItem === item.value"
		[attr.aria-labelledby]="ariaLabelledby ? ariaLabelledby : null"
		for="{{ item.id }}"
	>
	<span id="{{ labelId(item.id) }}">
		{{ item.label | translate}}
	</span>
		<span *ngIf='item.content' [innerHTML]='item.content | safeHtml'></span>
		<input
			id="{{ item.id }}"
			type="radio"
			tabindex="-1"
			[disabled]="isDisabled"
			[checked]="selectedItem === item.value"
			value="{{ item.value }}"
		/>
		<span class="check"></span>
	</label>
</div>

