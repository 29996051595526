import {Directive, HostListener} from '@angular/core'

@Directive({
	selector: '[formErrorFocus]',
})
export class FormErrorFocusDirective{

	//TODO probably need to add more selector based on specific components
	private selectors: string[] = [
		"input.is-invalid",
		"select.ng-invalid",
		"multi-input.ng-invalid input",
		"input.ng-invalid",
		"textarea.ng-invalid",
		"ng-select.is-invalid input",
		".multi-input.ng-invalid input.ng-invalid",
		"div[tabindex='0'].is-invalid",
		"angular-editor.is-invalid div[tabindex='0']"

	]
	constructor() {}

	@HostListener('submit', ['$event'])
	onFormSubmit($event) {
		let form = $event.target
		//Check if form is invalid
		if(form.classList.contains('ng-invalid')){
			//Do this to let this part of code triggered after submit event
			setTimeout(()=>{
				//If there are no items to focus, try to check if a language tab has errors
				let nav = form.querySelectorAll('ul.nav-tabs .invalid-lang a')
				nav.forEach((n,index)=>{
					//Focus only the first tab with error
					if(index == 0){
						n.click()
						n.scrollIntoView()
					}
				})
				//Get the first item that is invalid
				let items = form.querySelector(this.selectors.toString())
				if(items){
					setTimeout(()=>{items.focus()},200)
				}

			},0)

		}
	}
}
