import { Pipe, PipeTransform } from '@angular/core'
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: 'langSelector' })
export class LangSelectorPipe implements PipeTransform {

    constructor(
        private translateService: TranslateService
    ) {
    }

    transform(value,param): any {

        let current_lang = this.translateService.currentLang

        //If null or string returns it
        if(!value || typeof value === 'string'){
            return value
        }
        //First try to display userLang value
        if(value[current_lang]){
            return value[current_lang]
        } else {
            return value
        }

    }
}
