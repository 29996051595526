<label class="switch">
	<input
		id="id"
		[attr.aria-describedby]="describedBy ? describedBy : null"
		[(ngModel)]="inputModel"
		(ngModelChange)="inputModelChange.emit(inputModel)"
		type="checkbox"
	/>
	<span class="slider round"></span>
	<span class="display-label">
		{{ (inputModel ? enableLabel : disableLabel) | translate }}
	</span>
</label>
