<div class="{{ 'table-responsive' + (css ? ' ' + css : '') }}">
	<div class="sr-only" aria-live="polite">
		{{ getSortDirection(ths[sort_index]?.sorting) | translate }}
	</div>
	<table #table class="table r-table">
		<caption class="sr-only">{{ description }}</caption>
		<tr>
			<td *ngIf="!noThumbnail" id="actions_column">
				<ng-content select="[header]"></ng-content>
			</td>
			<th
				*ngFor="let th of ths; let i = index"
				[attr.id]="th.header"
				[attr.aria-sort]="getSortDirection(th.sorting)"
			>
				<span *ngIf="th.label" [id]="th.header + '-label'">{{ th.label | translate }}</span>
				<span *ngIf="!th.label" [id]="th.header + '-label'"><span class="sr-only">{{ 'Action Column' | translate }}</span></span>
				<button
					*ngIf="th.sortKey"
					type="button"
					(click)="sortBy(th.sortKey, i)"
					class="table-sort"
					[attr.aria-describedby]="th.header + '-label'"
				>
					<span
						class="material-icons"
						aria-hidden="true"
						[ngClass]="{
							'align-middle': !th.sorting,
							'align-top': th.sorting === 'asc',
							'align-bottom': th.sorting === 'desc'
						}"
					>
						{{
							th.sorting
								? th.sorting === 'desc'
									? 'expand_more'
									: 'expand_less'
								: 'unfold_more'
						}}
					</span>
					<span class="sr-only" translate>Sort By</span>
				</button>
			</th>
			<td *ngIf="!infinite" id="items_count_column">
				<span
					translate
					[translateParams]="{
						from: (collection | async).length,
						to: sync ? (collection | async)?.length : (total | async) || 0
					}"
				>
					SHOWING_SHORT
				</span>
			</td>
		</tr>
		<ng-content select="[body]"></ng-content>
	</table>
	<div class="table-footer d-flex flex-column mt-20">
		<custom-button
			*ngIf="
				((collection | async).length &&
					infinite &&
					(collection | async).length ===
						(currentPage | async) * (pageSize | async)) ||
				((total | async) &&
					(currentPage | async) * (pageSize | async) < (total | async)) ||
				(loading | async)
			"
			[ladda]="loading"
			[ariaDescribedby]="'showing-total'"
			[label]="'SHOW_MORE' | translate: { value: pageSize | async }"
			(onClick)="addMore()"
		></custom-button>

		<div
			translate
			id="no_results"
			*ngIf="!(total | async) && !(collection | async).length"
		>
			No results to show
		</div>
		<span
			id="showing-total"
			*ngIf="
				(total | async) &&
				(currentPage | async) * (pageSize | async) < (total | async)
			"
			translate
			[translateParams]="{
				from: (collection | async).length,
				to: total | async
			}"
		>
			SHOWING
		</span>
		<span
			*ngIf="!(total | async)"
			translate
			[translateParams]="{
				from: (collection | async).length
			}"
		>
			SHOWING_UNKNOWN
		</span>
	</div>
</div>
