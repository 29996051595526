import {
	Directive,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	HostListener
} from '@angular/core'

@Directive({
	selector: 'form[lostFocus]'
})
export class LostFocusDirective {
	@Output() lostFocus = new EventEmitter<void>()

	constructor(private elementRef: ElementRef) {}

	@HostListener('focusout', ['$event.relatedTarget'])
	public focusout(target) {
		const focusInside = this.elementRef.nativeElement.contains(target)

		if (!focusInside && target) {
			this.lostFocus.emit()
		}
	}
}
