import { Component, Input } from '@angular/core'

@Component({
	selector: 'confirm-modal',
	templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
	@Input() modal: any
	@Input() title: string
	@Input() showConfirm: boolean = true
	@Input() showCancel: boolean = true

	constructor() {}

}
