<div class="auth-container text-center d-flex flex-column">
	<div class="flex-grow-1">
		<div class="auth-form d-flex align-items-center justify-content-center">
			<div class="form-signin">
				<img
					class="mb-20 no-max"
					src="/assets/images/podium_loyalty_logo_white.svg"
					[alt]="'Podium brand logo' | translate"
				/>
				<h1>
					<span [innerHTML]="text | safeHtml"></span>
				</h1>
			</div>
		</div>
	</div>
	<div class="footer">
		<p>©{{ currentYear }} Engage People Inc.</p>
		<span class="version">{{ version }}</span>
	</div>
</div>
