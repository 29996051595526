import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { AppService } from '../../app.service'
import { AppState } from '../../store/reducers'
import {
	selectLoading,
	selectPermission,
	selectSuccess
} from '../../store/reducers/layout.reducer'
import {
	selectItemNoteById,
	selectItemStatusById
} from '../../store/reducers/order.reducer'
import { TranslateService } from '@ngx-translate/core'
import * as _ from 'lodash-es'
import { ValidateAllFormFields } from '../helper.functions'
import { loading } from '../../store/actions/app.actions'
import {
	addNoteToOrder,
	addOrderItemNoteToOrder
} from '../../store/actions/order.actions'
import moment from 'moment'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'order-item',
	templateUrl: './order-item.component.html',
	styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
	public _ = _
	@Input() item: any
	@Input() currency: string
	@Input() orderId: number
	@Input() orderDescription: string | Observable<string>

	collapsed: any = {
		status: true,
		notes: true
	}

	noteForm: FormGroup
	maxNoteLength: number = 255

	statusArray$: Observable<any>
	notesArray: Observable<any>
	canItemNotes$: Observable<boolean>
	arrayNotesLadda: Observable<boolean>
	updateOrderItemNoteSuccess$: Observable<boolean>
	supplierInfoTree: any[]
	openedModal: any
	openNoteForm: boolean = false

	@ViewChild('supplierModal') supplierModal: any

	constructor(
		private formBuilder: FormBuilder,
		public appService: AppService,
		public translateService: TranslateService,
		private modalService: NgbModal,
		private store$: Store<AppState>
	) {}

	ngOnInit() {
		this.statusArray$ = this.store$.pipe(
			select(selectItemStatusById(this.orderId, this.item.id))
		)

		this.notesArray = this.store$.pipe(
			select(selectItemNoteById(this.orderId, this.item.id))
		)

		this.noteForm = this.formBuilder.group({
			note: ['', [Validators.maxLength(this.maxNoteLength),Validators.required]]
		})

		this.canItemNotes$ = combineLatest([
			this.store$.pipe(
				select(
					selectPermission(
						'Fulfillment Management.children.Order Management.children.Order Item Notes'
					)
				)
			),
			this.store$.pipe(
				select(selectPermission('Order Management.children.Order Item Note'))
			)
		]).pipe(
			map(([canItemNoteLong, canItemNoteShort]) => {
				return canItemNoteLong ? canItemNoteLong : canItemNoteShort
			})
		)

		this.updateOrderItemNoteSuccess$ = this.store$.pipe(
			select(selectSuccess('save_item_note'))
		)

		this.arrayNotesLadda = this.store$.pipe(
			select(selectLoading('arrayNotesLadda'))
		)

		//Process additional_fees data
		_.map(this.item.additional_fees, (fee)=>{
			return  this.displayFeeValue(fee)
		})

	}

	get statusArray() {
		const statusArray = [...this.item.order_status]
		return statusArray.reverse()
	}

	get showTrackAndCarrierLabel() {
		const statusArray = [...this.item.order_status].reverse()
		const [latestStatus] = statusArray
		const hideTrackingCarrierStatuses = [
			'Initialized',
			'Awaiting Authorization',
			'Charged',
			'Declined',
			'Hold',
			'Hold Cancelled',
			'New',
			'Received'
		]
		return !hideTrackingCarrierStatuses.includes(latestStatus.name)
	}

	get isFinancialRedemption() {
		return (
			this.item.payload &&
			this.item.payload.product_group_code &&
			this.item.payload.product_group_code === 'FINANCIAL-REWARDS'
		)
	}

	get productName() {
		if (this.item.product_snapshot) {
			return this.item.product_snapshot.translated_names
		} else if (this.item.payload && this.item.payload.item_name) {
			return this.item.payload.item_name
		} else {
			return this.orderDescription
		}
	}

	get sku() {
		if (this.item.product_snapshot && this.item.product_snapshot.sku) {
			return this.item.product_snapshot.sku
		}
		return null
	}

	get po() {
		return this.item.id
	}

	get originalTransactionDescription() {
		if (
			this.item.payload &&
			this.item.payload.data &&
			this.item.payload.data.original_transaction_description
		) {
			return this.item.payload.data.original_transaction_description
		}
		return null
	}

	get isEgift() {
		return (
			this.item.sale_channel_type && this.item.sale_channel_type.code === 'EGIF'
		)
	}
	get eGiftRecipientEmail() {
		if (
			this.isEgift &&
			this.item.shipping_address &&
			this.item.shipping_address.email
		) {
			return this.item.shipping_address.email
		}
		return null
	}

	get itemPrice() {
		return !this.item.is_travel
			? (this.item.product_snapshot && this.item.product_snapshot.price) ||
					this.item.price ||
					0
			: this.item.payload.point_total || 0
	}

	get savings() {
		if (this.item.promotions && this.item.promotions.length) {
			const theSavingPromo = this.item.promotions.find((promotion) => {
				return promotion.promo_code === null
			})
			return theSavingPromo && theSavingPromo.value
				? theSavingPromo.value
				: null
		}
		return null
	}

	get promoArray() {
		if (this.item.promotions && this.item.promotions.length) {
			return this.item.promotions.filter((promotion) => {
				return promotion.promo_code !== null
			})
		}
		return null
	}

	get itemFinalPrice() {
		let itemPrice = this.item.price
		if (this.item.promotions && this.item.promotions.length) {
			this.item.promotions.map((promotion) => {
				if (promotion.value) {
					itemPrice -= promotion.value
				}
			})
		}

		return itemPrice
	}

	// get itemStatus() {
	// 	const statusArray = this.item.order_status.reverse()
	// 	return statusArray
	// }

	statusOnClick() {
		if (this.statusArray.length > 1) {
			this.collapsed.status = !this.collapsed.status
		} else {
			this.collapsed.status = true
		}
	}

	setFocus() {
		this.openNoteForm = true
		//Do this to let screen reader announce new position also when move down
		setTimeout(()=>{
			document.getElementById(this.po + '-note')?.focus()
		},20)
	}

	onSubmitNotes() {
		if (!this.noteForm.valid) {
			ValidateAllFormFields(this.noteForm)
			return
		}
		this.store$.dispatch(loading({ loadingKey: 'arrayNotesLadda' }))
		this.store$.dispatch(
			addOrderItemNoteToOrder({
				orderId: this.orderId,
				orderItemId: this.item.id,
				note: this.noteForm.value.note
			})
		)
		// clear notes form when success
		this.updateOrderItemNoteSuccess$.subscribe(() => {
			this.noteForm.reset()
		})
	}

	showSupplierInfo(payload) {
		if (!payload) {
			return
		}
		const uiTreePayload = [
			{
				nodes: [],
				type: 'object'
			}
		]

		const recursiveSupplierInfo = (
			data = payload,
			container: any = uiTreePayload[0],
			arrayParent = false
		) => {
			_.forOwn(data, (val, key) => {
				if (val) {
					if (typeof val === 'object') {
						const currentObject: any = {
							nodes: []
						}
						if (!arrayParent) {
							currentObject.title = key
						}
						if (!_.isArray(val)) {
							currentObject.type = 'object'
						} else {
							currentObject.type = 'array'
						}
						container.nodes.push(currentObject)
						recursiveSupplierInfo(
							val,
							currentObject,
							currentObject.type === 'array'
						)
					} else {
						const valType: string = this.stringIsDate(val) ? 'date' : 'string'

						const title = key
						let name = val
						const valInt = parseInt(val, 10)
						// when the key starts with 'is_', and value is 0 || 1,
						// for example: 'is_cancelled': '0' => 'is_cancelled': 'false'
						if (key.startsWith('is_') && (valInt === 0 || valInt === 1)) {
							name = valInt ? 'true' : 'false'
						}
						container.nodes.push({
							title,
							name,
							type: valType
						})
					}
				}
			})
		}

		recursiveSupplierInfo()
		this.supplierInfoTree = uiTreePayload

		this.openedModal = this.modalService
			.open(this.supplierModal, { size: 'lg' })
			.result.then(
				(result) => {
				},
				() => {}
			)
	}
	stringIsDate(date) {
		// when the date is pure number string, it also pass the moment isValid()
		return moment(date, moment.ISO_8601, true).isValid() && !/^\d+$/.test(date)
	}

	getProductType(item,property){
		return (item?.product_snapshot?.product_type && item?.product_snapshot?.product_type[property]) || item?.product_snapshot?.product_type
	}

	displayFeeValue(fee){
		//fee.is_points = true

		fee['display_fee'] = {
			show: true,
			currency: '',
			value: '',
			symbol: '',
			is_points: fee.is_points
		}
		if(!fee.fee_transaction?.currency || fee.is_points == null){
			// fee.display_fee.show = false
			fee.display_fee.value = 0
			fee.display_fee.currency = fee.currency?.name
			return fee
		}

		if(fee.is_points){
			fee.display_fee.currency = fee.fee_transaction?.currency.name
			if(fee.currency?.name.toLowerCase() == 'points'){
				fee.display_fee.value = fee.amount
			} else {
				fee.display_fee.value = fee.converted_amount
			}
		} else {
			fee.display_fee.currency = fee.fee_transaction?.currency.code
			fee.display_fee.symbol = fee.fee_transaction?.currency.symbol
			if(fee.currency?.name.toLowerCase() == 'points'){
				fee.display_fee.value = fee.converted_amount
			} else {
				fee.display_fee.value = fee.amount
			}
		}


		return fee

	}
}
