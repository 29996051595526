import { Component, OnInit} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Observable, of } from "rxjs";
import {
	customValidators,
	MustMatch,
	ValidateAllFormFields
} from '../../helpers/helper.functions'
import { AppService } from '../../app.service'
import { AuthService } from '../auth.service'
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/reducers";
import { appError } from "../../store/actions/app.actions";
import { marker as tr } from '@biesbjerg/ngx-translate-extract-marker'


@Component({
	selector: 'auth-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	newPassForm: FormGroup<any>
	ladda$: Observable<boolean>
	step: number = 0
	tooltipText = 'pw-tooltip'
	email: string
	token: string
	constructor(
		private formBuilder: FormBuilder,
		public appService: AppService,
		public auth: AuthService,
		private route: ActivatedRoute,
		private store: Store<AppState>,
		private router: Router,
	) {


	}

	ngOnInit(): void {
		this.token = this.route.snapshot.paramMap.get('token')
		this.email = this.route.snapshot.paramMap.get('email')

		this.newPassForm = this.formBuilder.group({
				password: ['', [Validators.required, customValidators.password]],
				password_confirmation: ['', [Validators.required, customValidators.password]]

			},
			{ validator: MustMatch('password', 'password_confirmation') }
		)
	}


	onSubmit() {
		if (!this.newPassForm.valid) {
			ValidateAllFormFields(this.newPassForm)
			return
		}
		let  payload = {...this.newPassForm.value}
		payload['email'] = this.email;
		payload['token'] = this.token;

		this.ladda$ = of(true)
		this.auth.newPasswordLegacy(payload).subscribe(res=>{
				this.step = 1
				this.ladda$ = of(false)
			},
			(err)=>{
				this.ladda$ = of(false)
				this.store.dispatch(appError({
					error:{
						reset_pw: tr('This link is invalid, please restart the reset password process.')
					},
					loadingKey:'loginLoading'
				}))
			}
		)
	}

}
