import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from 'lodash-es'
import {TranslateService} from "@ngx-translate/core";

@Directive({
    selector: '[selectAccessible]',
})
export class NgSelectAccessibilityDirective implements AfterViewInit, OnChanges{

    private isFocused = true;
    @Input() multiple: boolean = false
    @Input() placeholder: string;
    @Input() id: string;
    @Input() model: string;
    constructor(private elementRef: ElementRef,
                private translateService: TranslateService) {
        this.translateService.onLangChange.subscribe(()=>{
            this.applyAccessibility()
        })
    }

    @HostListener('change', ['$event'])
    onChange($event) {
        this.applyAccessibility()
    }

    ngAfterViewInit(): void {
        this.applyAccessibility()
    }

    applyAccessibility(){
        let selector = this.multiple ? '.ng-value-label' : '.ng-value'
        setTimeout(()=>{
            // //Do this to let screen reader announce the value selected
            // let span_value = this.elementRef.nativeElement.querySelectorAll(selector)
            // //Add id to each selected value
            // _.forEach(span_value,(i,index)=>{
            //     i.setAttribute('id',this.id + '_value_' +index)
            // })

            //Add id to wrapping div
            let div_elem = this.elementRef.nativeElement.querySelectorAll('.ng-value-container')
            _.forEach(div_elem,(i,index)=>{
                i.setAttribute('id',this.id + '_select_value')
            })
            //Add aria-describedby to reference the selected value
            let select_input = this.elementRef.nativeElement.querySelector('input')
            let value_described = this.id + '_select_value '

            // _.forEach(span_value,(i,index)=>{
            //     value_described += this.id + '_value_' +index + ' '
            // })

            select_input.setAttribute('aria-describedby',value_described + this.id + '-error ' + this.id + '-description ' + 'no-items-found-' + this.id)

            // //Add the selected value to the input value attribute
            // let text = []
            // let span_value = this.elementRef.nativeElement.querySelectorAll(selector)
            // _.forEach(span_value,(i)=>{
            //     text.push(i.innerText)
            // })
            // let select_input = this.elementRef.nativeElement.querySelector('input')
            // select_input.value = text.toString()
            // //Add aria-describedby to simulate placeholder
            // if(this.placeholder){
            //     let placeholder = this.elementRef.nativeElement.querySelector('.ng-placeholder')
            //     placeholder.setAttribute('id',this.id + '_ph')
            //     if(!select_input.value){
            //         select_input.setAttribute('aria-describedby',this.id + '_ph ' + this.id + '-error ' + this.id + '-description')
            //     }
            // }
        },500)
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.model){
            this.applyAccessibility()
        }
    }
}
