import {Component, OnInit, Input, Self, Optional, ChangeDetectorRef, forwardRef} from '@angular/core'
import {
  ControlValueAccessor,
  NgControl,
  AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors
} from '@angular/forms'
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {select, Store} from "@ngrx/store";
import {selectUserDateFormat, UserState} from "../../store/reducers/user.reducer";
const now = new Date();

@Component({
  selector: 'custom-form-datepicker',
  templateUrl: './custom-form-datepicker.component.html',
  styleUrls: ['./custom-form-datepicker.component.scss'],


})
export class CustomFormDatepickerComponent implements OnInit, ControlValueAccessor {
  @Input() id: string
  @Input() label: string
  @Input() showLabel: boolean = true
  @Input() css: string
  @Input() describedBy: string
  @Input() placeholder: string = ''
  @Input() helperText: string = ''
  @Input() disabled: boolean
  @Input() showOptional: boolean = true
  @Input() value: any = ''
  @Input() minDate: any
  @Input() maxDate: any
  @Input() readOnly: boolean = false
  @Input() popoverText: string = ''

  date_format$:any
  public toDay: any = new Date();

  constructor(
    // Retrieve the dependency only from the local injector,
    // not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form,
    // so we mark the dependency as optional.
    @Optional()
    private ngControl: NgControl,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    private userStore$: Store<UserState>,
  ) {
    let d = this.toDay;
    this.toDay =  {year: d.getUTCFullYear(), day: d.getUTCDate(), month: d.getUTCMonth() + 1};
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
    this.date_format$ = this.userStore$.pipe(select(selectUserDateFormat('short', false)))

  }

  ngOnInit() {
  }

  // Datepicker
  onDateSelection(date: any) {
    this.value = date
    this.onChangeFn(this.value);
  }



  writeValue(value: any): void {
    if ( typeof value == 'string') {
      let d = new Date(value);
      this.value = {year: d.getUTCFullYear(), day: d.getUTCDate(), month: d.getUTCMonth() + 1};
    } else {
      this.value = value
    }

  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  public onChangeFn = (_: any) => {
  };

  public onTouchedFn = () => {
  };

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public onChange($event) {

    this.onChangeFn(this.value);
  }

  get theControl() {
    return this.ngControl
  }

  get theValidator() {
    if (typeof this.ngControl.control.validator === 'function') {
      return this.ngControl.control.validator({} as AbstractControl)
    }
    return null
  }

  get isRequired() {
    return this.theValidator ? this.theValidator.required : false
  }

  get isValid() {
    // when contol set to disabled, this.ngControl.valid became false
    return (
      !this.ngControl.touched || this.ngControl.valid || this.ngControl.disabled
    )
  }

  get isDisabled() {
    return this.ngControl.disabled
  }

  get labelClass() {
    let theClass: string = ''
    if (this.isRequired) {
      theClass += 'required '
    }

    if (!this.showLabel) {
      theClass += 'sr-only '
    }
    return theClass
  }





}
