import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ErrorFields } from './error-fields/error-field.component'
import { TranslateModule } from '@ngx-translate/core'
import { NameThumbnail } from './pipes/name-thumbnail.pipe'
import { CapitalizeFirst } from './pipes/capitalizefirst.pipe'
import { ButtonComponent } from './button/button.component'
import { ToggleButtonComponent } from './toggle-button/toggle-button.component'
import { CustomFormInputComponent } from './custom-form-input/custom-form-input.component'
import { CustomFormToggleComponent } from './custom-form-toggle/custom-form-toggle.component'
import { CustomFormSelectComponent } from './custom-form-select/custom-form-select.component'
import { AlertComponent } from './alert/alert.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RadioComponent } from './custom-radio/radio.component'
import { MultiInputComponent } from './custom-multi-input/multi-input.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslatedLinks } from './pipes/translated-links.pipe'
import { TableComponent } from './table/table.component'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { FileValueAccessor } from './directives/file-value.accessor'
import { FileValidator } from './directives/file-input.validator'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { KeysPipe } from './pipes/key-ngFor.pipe'
import { AdvancedSearch } from './advanced-search/advanced-search.component'
import { SourceSelector } from './source-selectors/source-selectors.component'
import { OrderItemComponent } from './order-item/order-item.component'
import { FormatDatePipe } from './pipes/format-date.pipe'
import { MiddleclickDirective } from './directives/middle-click'
import { LangParamPipe } from './pipes/lang-param.pipe'
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component'
import { ModalAlertComponent } from './modal-alert/modal-alert.component'
import { SafeHtmlPipe } from './pipes/safe.pipe'
import {LostFocusDirective} from "./directives/lost-focus.directive";
import {FormErrorFocusDirective} from "./directives/form-error-focus";
import {NgSelectAccessibilityDirective} from "./directives/ng-select-accessibility";
import {CustomFormDatepickerComponent} from "./custom-form-datepicker/custom-form-datepicker.component";
import { QueryBuilderComponent } from "./query-builder/query-builder.component";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { AngularEditorAccessibilityDirective } from "./directives/angular-editor-accessibility";
import { DateValidatorDirective } from "./directives/date-format.validator";
import { AbsPipe } from "./pipes/positive-number.pipe";
import { DisableMinutesDirective } from "./directives/disable-minutes";
import { LangSelectorPipe } from "./pipes/lang-selector.pipe";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		RouterModule,
		ReactiveFormsModule,
		CKEditorModule,
		NgbModule,
		FormsModule,
		NgSelectModule,
		NgOptionHighlightModule
	],
	declarations: [
		ErrorFields,
		NameThumbnail,
		TranslatedLinks,
		MultiInputComponent,
		FileValueAccessor,
		FileValidator,
		MiddleclickDirective,
		ClickOutsideDirective,
		LostFocusDirective,
		FormErrorFocusDirective,
		NgSelectAccessibilityDirective,
		AngularEditorAccessibilityDirective,
		RadioComponent,
		ButtonComponent,
		ToggleButtonComponent,
		CustomFormInputComponent,
		CustomFormDatepickerComponent,
		CustomFormToggleComponent,
		CustomFormSelectComponent,
		AlertComponent,
		AdvancedSearch,
		TableComponent,
		ConfirmModalComponent,
		ModalAlertComponent,
		SourceSelector,
		OrderItemComponent,
		KeysPipe,
		FormatDatePipe,
		LangParamPipe,
		CapitalizeFirst,
		SafeHtmlPipe,
		QueryBuilderComponent,
		DateValidatorDirective,
		AbsPipe,
		DisableMinutesDirective,
		LangSelectorPipe
	],
	exports: [
		ErrorFields,
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NameThumbnail,
		CKEditorModule,
		FileValueAccessor,
		FileValidator,
		MiddleclickDirective,
		ClickOutsideDirective,
		LostFocusDirective,
		FormErrorFocusDirective,
		NgSelectAccessibilityDirective,
		AngularEditorAccessibilityDirective,
		TranslatedLinks,
		MultiInputComponent,
		RadioComponent,
		SourceSelector,
		ButtonComponent,
		ToggleButtonComponent,
		CustomFormInputComponent,
		CustomFormDatepickerComponent,
		CustomFormToggleComponent,
		CustomFormSelectComponent,
		AlertComponent,
		NgSelectModule,
		NgOptionHighlightModule,
		AdvancedSearch,
		TableComponent,
		ConfirmModalComponent,
		ModalAlertComponent,
		OrderItemComponent,
		NgbModule,
		KeysPipe,
		FormatDatePipe,
		LangParamPipe,
		CapitalizeFirst,
		SafeHtmlPipe,
		QueryBuilderComponent,
		DateValidatorDirective,
		AbsPipe,
		DisableMinutesDirective,
		LangSelectorPipe
	]
})
export class SharedModule {}
