import { Component, Input } from '@angular/core'

@Component({
	selector: 'modal-alert',
	templateUrl: './modal-alert.component.html'
})
export class ModalAlertComponent {
	@Input() modal: any
	@Input() title: string
	@Input() description: string
	@Input() actions: any = {}

	constructor() {}
}
