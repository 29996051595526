<div *ngIf="success$ | async">
	<div *ngFor="let alert of alerts">
		<!-- when user focus on close button, need to use 'aria-label', thats why set dismissible to false and overrided the close button -->
		<ngb-alert [type]="alert.type" [dismissible]="false">
			<button
				*ngIf="alert.showClose"
				type="button"
				class="close"
				#closeButtons
				(click)="onClosed(alert)"
				[attr.aria-describedby]="alert.type + '-' + alert.key + '-content'"
				[attr.aria-label]="'Close Alert' | translate"
			>
				<span aria-hidden="true">&times;</span>
			</button>
			<span
				*ngIf="alert.type === 'danger'"
				aria-hidden="true"
				class="material-icons-outlined icon"
			>
				do_disturb_on
			</span>
			<span
				*ngIf="alert.type === 'warning'"
				aria-hidden="true"
				class="material-icons-outlined icon"
			>
				error_outline
			</span>

			<span
				*ngIf="alert.type === 'success'"
				aria-hidden="true"
				class="material-icons-outlined icon"
			>
				check_circle
			</span>
			<div class="content" [id]="alert.type + '-' + alert.key + '-content'">
				<span class="h2">
					{{
						(isObject(alert.title)
							? (alert.title | getLangParam | async)
							: (alert.title | translate)
						) | capitalizeFirst
					}}
				</span>
				<div class="mt-10">
					{{
						isObject(alert.description)
							? (alert.description | getLangParam | async)
							: (alert.description | translate)
					}}
					<span *ngIf="alert.html">
						<div [innerHtml]="alert.html"></div>
					</span>
					<span *ngIf="alert.actionText">
						<a [routerLink]="alert.url | translateLink" class="action">
							{{ alert.actionText }}
						</a>
						»
					</span>
				</div>
			</div>
		</ngb-alert>
	</div>
</div>
