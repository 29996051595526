import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from '../store/reducers'
import { AppService } from '../app.service'
import * as partnerActions from '../store/actions/partner.actions'
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs'
import * as fromAppActions from '../store/actions/app.actions'
import { TranslatedLinks } from '../helpers/pipes/translated-links.pipe'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { map, tap, catchError, mergeMap } from 'rxjs/operators'
import { OrdersService } from '../dashboard/orders/orders.service'
import packageInfo from '../../../package.json'

@Component({
	selector: 'sso',
	templateUrl: './sso.component.html',
	styleUrls: ['./sso.component.scss']
})
export class SSOComponent implements OnInit {
	public currentYear: number
	public text: string
	public version: string

	constructor(
		private store$: Store<AppState>,
		private activeRoute: ActivatedRoute,
		private appService: AppService,
		private orderService: OrdersService,
		private router: Router,
		private pipe: TranslatedLinks
	) {
		this.currentYear = new Date().getFullYear()
		this.version =  packageInfo.version
	}

	handleError() {
		this.text = _(
			'<h1>Login Error<h1><p style="font-size: 1.5rem">There was an issue with your login.<br> Please contact customer support.</p>'
		)
	}

	ngOnInit(): void {
		// when user has already logged in
		if (this.appService.getFromLocal('token')) {
			this.appService.clear()
		}

		const queryParams: any = this.activeRoute.snapshot.queryParamMap

		this.activeRoute.paramMap.subscribe((params) => {
			if (params.get('token')) {
				this.text = _(
					'Attempting authentication through Single Sign-On (SSO)<br>Please wait'
				)

				const token = params.get('token')
				const programId = queryParams.params.program_id
				this.appService
					.loginWithToken({ token, programId })
					.pipe(
						map((data: any) => {
							this.store$.dispatch(
								fromAppActions.loginSuccess({
									token: {
										access_token: data.token,
										expires_in: data.expires_in
									}
								})
							)
							return data.token
						}),
						tap((data: string) => {
							if (data) {
								this.appService.saveToLocal('isSSO', 1)
								this.appService.saveToLocal('token', data)
							}
						}),
						catchError(() => {
							this.handleError()
							return EMPTY
						})
					)
					.subscribe(() => {
						if (queryParams.params.action === 'login-as') {
							this.appService
								.getSources(['user'], {
									filter: JSON.stringify({
										search: queryParams.params.member_id
									})
								})
								.pipe(
									mergeMap((res: any) => {
										if (res.data.length) {
											return this.appService.getSources([
												'admin/program',
												queryParams.params.program_id,
												'user',
												res.data[0].id,
												'get-member-token'
											])
										}
									}),
									catchError(() => {
										this.handleError()
										return EMPTY
									})
								)
								.subscribe((response: any) => {
									location.href = `${response.url}?token=${response.token}&account_id=${queryParams.params.account_id}&member_id=${queryParams.params.member_id}&user_account=${queryParams.params.member_id}&medium_id=${queryParams.params.medium_id}`
								})
						} else {
							this.appService.current = {
								partner: queryParams.params.partner_id,
								customer: queryParams.params.customer_id,
								program: queryParams.params.program_id
							}
							this.appService.saveToLocal(
								'current_partner',
								this.appService.current.partner
							)
							this.appService.saveToLocal(
								'current_customer',
								this.appService.current.customer
							)
							this.appService.saveToLocal(
								'current_program',
								this.appService.current.program
							)

							const obs: Observable<any>[] = [
								this.appService.getModules(),
								this.appService.getSources('partner', { with_paginator: 0 }),
								this.appService.getSources('customer', {
									with_paginator: 0,
									partner_id: queryParams.params.partner_id
								}),
								this.appService.getSources('program', {
									with_paginator: 0,
									customer_id: queryParams.params.customer_id
								})
							]

							forkJoin(obs)
								.pipe(
									catchError(() => {
										this.handleError()
										return EMPTY
									})
								)
								.subscribe(([modules, partners, customers, programs]) => {
									const details: any = modules.detail
									this.store$.dispatch(
										fromAppActions.loadModulesSuccess({
											system_permissions: details.roles[0].system_permissions,
											permissions: details.permissions
										})
									)
									this.store$.dispatch(
										fromAppActions.userUpdated({
											user: {
												id: details.id,
												email: details.email,
												can_login_as: details.can_login_as,
												team: details.roles[0].team,
												is_owner: details.is_owner,
												first_name: details.first_name,
												last_name: details.last_name,
												datepicker_time: details.datepicker_time,
												datepicker_short: details.datepicker_short,
												datepicker_long: details.datepicker_long,
												phone: details.phone,
												title: details.title,
												preferredLanguage: details.preferredLanguage,
												partner_id: details.partner_id
											}
										})
									)

									this.appService.saveToLocal('partner', partners.data)
									this.store$.dispatch(
										partnerActions.loadCollectionSuccess({
											data: partners.data
										})
									)
									this.appService.saveToLocal('customers', customers.data)
									this.appService.saveToLocal('programs', programs.data)

									switch (queryParams.params.action) {
										case 'members':
											if (queryParams.params.member_id) {
												this.appService
													.getSources(['user'], {
														filter: JSON.stringify({
															search: queryParams.params.member_id
														})
													})
													.pipe(
														catchError(() => {
															this.handleError()
															return EMPTY
														})
													)
													.subscribe((response: any) => {
														if (response.data.length) {
															this.router.navigate([
																`members/${response.data[0].id}/addresses`
															])
														}
													})
											} else {
												this.router.navigate(['members'])
											}
											break
										case 'orders':
											if (queryParams.params.reference_id) {
												this.orderService
													.getOrders(
														{ activeIndex: 1, pageSize: 1 },
														{
															order_reference_identifier:
																queryParams.params.reference_id,
															program_id: this.appService.current.program
														}
													)
													.pipe(
														catchError(() => {
															this.handleError()
															return EMPTY
														})
													)
													.subscribe((res: any) => {
														if (res.data?.length) {
															const orderId = res.data[0]?.id
															this.router.navigate([`orders/${orderId}`])
														}
													})
											} else {
												this.router.navigate(['orders'])
											}
											break
										default:
											this.router.navigate(['orders'])
											break
									}
								})
						}
					})
			} else {
				this.handleError()
			}
		})
	}
}
