<div class="modal-header pt-30 pb-20">
    <h1 class='modal-title' id='modal-confirm-title' [innerHTML]="title ?? 'Session Expired' | translate"></h1>

    <button *ngIf="actions.close"
            type="button"
            class="close"
            [attr.aria-label]="'Close' | translate"
            (click)="modal.dismiss('Cross click')"
    >
        <span class="icon-close" aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body p-5">
    <h2 [innerHTML]="description ?? 'Your session expired due to inactivity. Please log in again to resume your session.'  | translate"></h2>
</div>
<div class="modal-footer justify-content-around pt-20 pb-30">
    <custom-button *ngIf="actions.close"
                   type="submit"
                   (onClick)="(actions.close.onClick ? actions.close.onClick(modal) : modal.dismiss())"
                   css="outline primary"
                   [label]="actions.close.label ?? 'Cancel' | translate"
    ></custom-button>
    <custom-button *ngIf="actions.confirm"
                   (onClick)="(actions.confirm.onClick ? actions.confirm.onClick(modal) : modal.close())"
                   css="full primary large"
                   [label]="actions.confirm.label ?? 'Continue' | translate"
    ></custom-button>
</div>
