import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { omit, orderBy, filter, size, isEmpty, cloneDeep } from 'lodash-es'
import { Pagination, TableActions } from '../../helpers/shared.interfaces'
import {
	addNoteSuccess,
	addPage,
	changePage,
	clearOrder,
	loadOrdersSuccess,
	loadOrderSuccess,
	saveOrderSuccess,
	searchOrders,
	sortTableBy,
	toggleLoading,
	updateNotesPagination,
	updateOrderSuccess,
	submitActionSuccess,
	updateOrderAddressSuccess,
	updateActions,
	addOrderItemNoteSuccess
} from '../actions/order.actions'
import { Member } from '../../dashboard/members/member.interfaces'
import { mergeDeep } from '../../helpers/helper.functions'
import { selectUser, User } from "./user.reducer";

export const ordersStateFeatureKey = 'orderState'

export interface Order {
	id: number
	name: string
	description: string
	created_at: Date
	order_number: number
	actions?: any[]
	member: Member
	order_items: any[]
	notes: any[]
	currentNotePage?: number
	shipping_address: any
	can_edit_address: boolean
	loading?: boolean
	order_pending_operation?: any
}

export interface OrderState extends EntityState<Order> {
	loading: any
	pagination: Pagination
}

export const orderAdapter: EntityAdapter<Order> = createEntityAdapter<Order>()

export const orderInitialState = orderAdapter.getInitialState({
	loading: {},
	pagination: {
		pageSize: 15,
		activeIndex: 1,
		from: undefined,
		search: {},
		action: undefined,
		total: undefined,
		sortBy: {
			column: 'created_at',
			dir: 'desc'
		}
	}
})

export const orderReducers = createReducer(
	orderInitialState,
	on(loadOrdersSuccess, (state, action) => {
		if (
			state.pagination.action === TableActions.SORTING ||
			state.pagination.action === TableActions.CLEARING ||
			state.pagination.action === TableActions.SEARCHING
		) {
			return orderAdapter.setAll(action.orders, {
				...state,
				pagination: {
					...state.pagination,
					total: action.total,
					action: undefined,
					from: 'api',
					sortBy: {
						...state.pagination.sortBy
					}
				}
			})
		} else {
			return orderAdapter.addMany(action.orders, {
				...state,
				loading: omit(state.loading, 'pageLoading'),
				pagination: {
					...state.pagination,
					total: action.total,
					from: 'api',
					action: undefined,
					sortBy: {
						...state.pagination.sortBy
					}
				}
			})
		}
	}),
	on(updateOrderAddressSuccess, (state, { orderId, changes }) => {
		return orderAdapter.updateOne(
			{
				id: orderId,
				changes: {
					shipping_address: cloneDeep({
						...state.entities[orderId].shipping_address,
						...changes,
						updated_at: new Date().toUTCString(),
						region: changes.region_code
					})
				}
			},
			state
		)
	}),

	on(updateNotesPagination, (state, { orderId }) => {
		return orderAdapter.updateOne(
			{
				id: orderId,
				changes: {
					currentNotePage: (state.entities[orderId].currentNotePage || 1) + 1
				}
			},
			state
		)
	}),
	on(addNoteSuccess, (state, { orderId, note }) => {
		return orderAdapter.updateOne(
			{
				id: orderId,
				changes: {
					notes: state.entities[orderId].notes.concat(note)
				}
			},
			state
		)
	}),
	on(addOrderItemNoteSuccess, (state, { orderId, orderItemId, note }) => {
		let order_item_idx = state.entities[orderId].order_items.findIndex(
			(item) => {
				return item.id == orderItemId
			}
		)
		let order_items = cloneDeep(state.entities[orderId].order_items)

		order_items[order_item_idx].notes.push(note)

		return orderAdapter.updateOne(
			{
				id: orderId,
				changes: {
					order_items: [...order_items]
				}
			},
			state
		)
	}),
	on(loadOrderSuccess, (state, action) => {
		return orderAdapter.upsertOne(action.order, state)
	}),
	on(updateOrderSuccess, (state, action) => {
		return orderAdapter.updateOne(
			{ id: action.id, changes: action.changes },
			state
		)
	}),
	on(updateActions, (state, action) => {
		return orderAdapter.updateOne(
			{
				id: action.order,
				changes: {
					actions: action.actions
				}
			},
			state
		)
	}),
	on(submitActionSuccess, (state, action) => {
		let orderItems = cloneDeep(state.entities[action.id].order_items)

		let order_instant_refund_length = 0

		orderItems.forEach((item, index) => {
			const itemChanged = action.changes.order_items?.find((targetItem) => {
				return targetItem.id === item.id
			})

			if (itemChanged) {
				mergeDeep(orderItems[index], itemChanged)
			}

			if (action.action === 'cancelItems') {
				if (itemChanged) {
					orderItems[index] = omit(item, [
						'sale_channel_type.allowed_actions.order_instant_refund'
					])
				} else if (
					orderItems[index].sale_channel_type.allowed_actions
						.order_instant_refund &&
					orderItems[index].sale_channel_type.allowed_actions
						.order_instant_refund.is_enabled
				) {
					order_instant_refund_length++
				}
			}
		})

		const payload: any = {
			order_items: [...orderItems]
		}

		switch (action.action) {
			case 'cancelItems':
				if (!order_instant_refund_length) {
					payload.actions = state.entities[action.id].actions.filter(
						(action) => action.value !== 'order_instant_refund'
					)
				}
				break
			default:
				break
		}

		return orderAdapter.updateOne(
			{
				id: action.id,
				changes: payload
			},
			state
		)
	}),
	on(saveOrderSuccess, (state, { order }) => {
		return orderAdapter.addOne(order, state)
	}),
	on(sortTableBy, (state, action) => {
		return {
			...state,
			pagination: {
				...state.pagination,
				from: undefined,
				activeIndex:
					state.ids.length !== state.pagination.total
						? 1
						: state.pagination.activeIndex,
				action: TableActions.SORTING,
				sortBy: {
					column: action.column,
					dir: state.pagination.sortBy.dir === 'asc' ? 'desc' : 'asc'
				}
			}
		}
	}),
	on(addPage, (state) => {
		return {
			...state,
			pagination: {
				...state.pagination,
				from: undefined,
				action: TableActions.ADDINGPAGE,
				activeIndex: state.pagination.activeIndex + 1
			},
			loading: { ...state.loading, pageLoading: true }
		}
	}),
	on(searchOrders, (state, action) => {
		return {
			...state,
			pagination: {
				...state.pagination,
				action: action.action,
				from: undefined,
				search: action.search,
				activeIndex:
					state.ids.length !== state.pagination.total
						? 1
						: state.pagination.activeIndex
			}
		}
	}),
	on(toggleLoading, (state, { orderId, loading }) => {
		return orderAdapter.updateOne(
			{
				id: orderId,
				changes: {
					loading: loading
				}
			},
			state
		)
	}),
	on(changePage, (state, action) => {
		return {
			...state,
			pagination: {
				action: TableActions.ADDINGPAGE,
				from: undefined,
				...state.pagination,
				activeIndex: action.page
			},
			loading: { ...state.loading, pageLoading: true }
		}
	}),
	on(clearOrder, (state, action) => {
		if (size(state.entities) === 1 && isEmpty(state.pagination.search)) {
			return orderAdapter.removeOne(action.orderId, state)
		}
		return state
	})
)

export const selectOrderFeature = createFeatureSelector<OrderState>(
	ordersStateFeatureKey
)

export const selectAllOrders = createSelector(
	selectOrderFeature,
	orderAdapter.getSelectors().selectAll
)

export const selectPagination = createSelector(
	selectOrderFeature,
	(state: OrderState) => state.pagination
)

export const selectPageLoading = createSelector(
	selectOrderFeature,
	(state: OrderState) => state.loading.pageLoading
)

export const selectOrderById = (orderId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId]
	})
}

export const selectOrderActions = (orderId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId].actions
	})
}

export const selectItemStatusById = (orderId: number, itemId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId].order_items
			.find((item) => {
				return item.id === itemId
			})
			.order_status.slice()
			.reverse()
	})
}

export const selectItemNoteById = (orderId: number, itemId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId].order_items.find((item) => {
			return item.id === itemId
		}).notes
	})
}

export const selectOrderLoading = (orderId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId]?.loading
	})
}

export const selectOrdersByMemberId = (memberId: number) =>
	createSelector(selectOrderFeature, (state: OrderState) => {
		return filter(state.entities, (order) => {
			return order.member.id == memberId
		})
	})

function paginate(pagination, orders) {
	let response: any = {
		action: pagination.action,
		from: pagination.from,
		orders
	}

	if (
		pagination.action === TableActions.SEARCHING ||
		(pagination.action === TableActions.SORTING &&
			orders.length !== pagination.total) ||
		pagination.action === TableActions.CLEARING ||
		pagination.action === TableActions.ADDINGPAGE
	) {
		response.orders = []
	} else if (
		pagination.action === TableActions.SORTING &&
		orders.length === pagination.total
	) {
		let sort = pagination.sortBy.column,
			order = pagination.sortBy.dir

		if (sort === 'created_at') {
			response.orders = orders.sort((a, b) => {
				let dateA: any = new Date(a.created_at)
				let dateB: any = new Date(b.created_at)

				if (order === 'asc') {
					return dateB - dateA
				} else {
					return dateA - dateB
				}
			})
		} else {
			response.orders = orderBy(orders, [sort], [order])
		}
	}

	return response
}

export const selectOrderNotes = (orderId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return cloneDeep(state.entities[orderId].notes)
			.sort((a, b) => {
				let dateA: any = new Date(a.created_at)
				let dateB: any = new Date(b.created_at)

				return dateB - dateA
			})
			.slice(0, (state.entities[orderId].currentNotePage || 1) * 25)
	})
}

export const selectSearch = createSelector(
	selectOrderFeature,
	(state: OrderState) => state.pagination.search
)

export const selectMemberOrdersPage = (memberId: number) =>
	createSelector(
		selectPagination,
		selectOrdersByMemberId(memberId),
		(pagination: Pagination, orders) => {
			return paginate(pagination, orders)
		}
	)

export const selectOrdersPage = createSelector(
	selectPagination,
	selectAllOrders,
	(pagination: Pagination, allOrders: Order[]) => {
		return paginate(pagination, allOrders)
	}
)

export const selectShowPendingOperations = ({ orderId, isSuperAdmin }) => {
	return createSelector(selectOrderFeature,selectUser, (order: OrderState, user: User) => {

		const can_retry_orders = user.team?.can_retry_orders
		const hasPendingOperations =
			order.entities[orderId]?.order_pending_operation
				?.orderPendingOperationDetails?.length > 0

		return isSuperAdmin || can_retry_orders
	})
}

export const selectPendingOperations = (orderId: number) => {
	return createSelector(selectOrderFeature, (state: OrderState) => {
		return state.entities[orderId]?.order_pending_operation
			?.orderPendingOperationDetails
	})
}
