import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import { forOwn } from 'lodash-es'
import { searching, updateList } from '../actions/app.actions'

export const searchStateFeatureKey = 'searchState'

export interface SearchState {
	items: any
	search: any
}

const initialSearchState: SearchState = {
	items: {},
	search: {}
}

export const searchReducers = createReducer(
	initialSearchState,
	on(searching, (state, action) => {
		return {
			...state,
			search: {
				...state.search,
				[action.key]: {
					...action.params,
					fromApi: false,
					noSearch: action.noSearch
				}
			}
		}
	}),
	on(updateList, (state, action) => {
		return {
			...state,
			search: {
				...state.search,
				[action.key]: {
					...state.search[action.key],
					fromApi: true
				}
			},
			items: {
				...state.items,
				[action.key]: action.list
			}
		}
	})
)

export const selectSearchFeature = createFeatureSelector<SearchState>(
	searchStateFeatureKey
)

export const selectItems = (key) =>
	createSelector(selectSearchFeature, (state: SearchState) => state.items[key])

export const selectSearch = (key) =>
	createSelector(selectSearchFeature, (state: SearchState) => state.search[key])

export const filterItems = (key) =>
	createSelector(
		selectItems(key),
		selectSearch(key),
		(items: any[], search: any) => {
			let filteredItems = []

			function filterItems() {
				items.forEach((item) => {
					let valid
					forOwn(JSON.parse(search.filter), (val, key) => {
						if (key === 'search') {
							valid = item.name.toLowerCase().includes(val.toLowerCase())
						} else {
							valid = item[key] == val
						}
						if (!valid) {
							return false
						}
					})
					if (!valid) {
						return false
					}
					filteredItems.push(item)
				})
			}

			if (search && search.fromApi) {
				if (search.noSearch) {
					filterItems()
				} else {
					filteredItems = items
				}
			} else if (search && items) {
				filterItems()
			}

			return { items: filteredItems, search }
		}
	)
