import { createAction, props } from '@ngrx/store'
import { Member } from '../member.interfaces'
import { TableActions } from '../../../helpers/shared.interfaces'

export const loadMembers = createAction('[Member List] Load Members')

export const loadMembersSuccess = createAction(
	'[Member Effect] Load Members Success',
	props<{ members: Member[]; total?: number }>()
)

export const saveMember = createAction(
	'[Member Effect] Save Members',
	props<{ payload: any }>()
)

export const updateLastBalances = createAction(
	'[Member Effect] Update Account Last Balances',
	props<{ account: any; memberId: number }>()
)

export const toggleLoading = createAction(
	'[Member Effect] Load Member',
	props<{ memberId: number; loading: boolean }>()
)

export const addNoteToMember = createAction(
	'[Member Effect] Add Note To Member',
	props<{ memberId: number; note: string }>()
)

export const addNoteSuccess = createAction(
	'[Member Effect] Add Note Success',
	props<{ memberId: number; note: any }>()
)

export const clearMember = createAction(
	'[Member Effect] Clear Member',
	props<{ memberId: number }>()
)

export const saveMemberSuccess = createAction(
	'[Member Effect] Save Members Success',
	props<{ member: Member }>()
)

export const updateMemberSuccess = createAction(
	'[Member Edit] Edit Member Success',
	props<{ id: number; changes: any }>()
)

export const updateMember = createAction(
	'[Member Edit] Edit Member',
	props<{ payload: any }>()
)

export const updateMemberPassword = createAction(
	'[Member Edit] Edit Member Password',
	props<{ memberId: number; payload: any }>()
)

export const loadMemberAccounts = createAction(
	'[Member Edit] Load Member Accounts',
	props<{ memberId: number }>()
)

export const loadMemberAccountsSuccess = createAction(
	'[Member Edit] Load Member Accounts Success',
	props<{ accounts: any[]; memberId: number }>()
)

export const updateMemberAccounts = createAction(
	'[Member Edit] Update Member Accounts Currencies',
	props<{ memberId: number; currencies: any[] }>()
)

export const clearMembers = createAction(
	'[Member] Clear Members',
	props<{ search: any }>()
)

export const searchMembers = createAction(
	'[Member] Search Members',
	props<{ search: any; action?: TableActions }>()
)

export const clearMembersSearch = createAction('[Member] Clear Search')

export const changePage = createAction(
	'[Member Pages] Change Page',
	props<{ page: number }>()
)

export const addPage = createAction('[Member Pages] Add Page')

export const sortTableBy = createAction(
	'[Member Pages] Sort By',
	props<{ column: string }>()
)

export const loadMember = createAction(
	'[Member Edit] Load Member',
	props<{ memberId: number }>()
)

export const updateMemberAddressSuccess = createAction(
	'[Member Effect] Edit Member Address Success',
	props<{ memberId: number; changes: any }>()
)

export const createMemberAddressSuccess = createAction(
	'[Member Effect] Add Member Address Success',
	props<{ memberId: number; changes: any }>()
)

export const validateAddress = createAction(
	'[Member Effect] Validate Member Address',
	props<{ address: any }>()
)

export const addMemberAddress = createAction(
	'[Member Effect] Add Member Address',
	props<{ memberId: number; address: any }>()
)

export const removeAddress = createAction(
	'[Member Effect] Remove Member Address',
	props<{ memberId: number; addressId: number }>()
)

export const removeAddressSuccess = createAction(
	'[Member Effect] Remove Member Address Success',
	props<{ memberId: number; addressId: number }>()
)

export const toggleAddressLoading = createAction(
	'[Member Effect] Load Member Address',
	props<{ memberId: number; addressId: number; loading: boolean }>()
)

export const loadMemberSuccess = createAction(
	'[Member Effect] Load Member Success',
	props<{ member: Member }>()
)

export const loginAs = createAction(
	'[Member Effect] Login As',
	props<{ memberId: number; userAccount: string }>()
)
