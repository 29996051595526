import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'nameThumbnail' })
export class NameThumbnail implements PipeTransform {
	transform(value: string): string {
		// John Doe Smith => JS
		return value
			.match(/(\b\S)?/g)
			.join('')
			.match(/(^\S|\S$)?/g)
			.join('')
			.toUpperCase()

		// John => JO and "John Doe Smith" => "JS"
		// return value
		// 	.match(/(^\S\S?|\b\S)?/g)
		// 	.join('')
		// 	.match(/(^\S|\S$)?/g)
		// 	.join('')
		// 	.toUpperCase()

		// "John Doe Smith" => "JDS"
		// return value
		// 	.match(/(\b\S)?/g)
		// 	.join('')
		// 	.toUpperCase()
	}
}
