import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { UserState } from '../../store/reducers/user.reducer'
import { filter, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { selectLang } from '../../store/reducers/layout.reducer'
import { AppService } from '../../app.service'

@Pipe({
	name: 'getLangParam'
})
export class LangParamPipe implements PipeTransform {
	constructor(
		private store$: Store<UserState>,
		private appService: AppService
	) {}

	transform(value: string,onlyProgramLanguage: boolean = false): Observable<string> {
		return this.store$.pipe(
			select(selectLang),
			map((userLang) => {
				//If null or string returns it
				if(!value || typeof value === 'string'){
					return value
				}
				//Check onlyProgramLanguage flag, if true display the userLang value only if that is a language of the program
				//Example onlyProgramLanguage= true, language of Admin is en-CA, program selected HSBC(en-US),
				// even if a value has en-CA property i need to show the en-US value (Example of this usage is on Teams list section)
				if(!onlyProgramLanguage){
					//First try to display userLang value
					if(value[userLang]){
						return value[userLang]
					}
				}

				//Add appService program check since in the program section program is not select
				if(!!this.appService.getFromLocal('program') && this.appService.current.program){
					let currentProgram = JSON.parse(
						this.appService.getFromLocal('program')
					).find((program) => {
						return program.id == this.appService.current.program
					})
					//Check if current program has the user language
					let programHasUserLang = currentProgram.languages.find(
						(lang) => lang.code === userLang
					)
					//If not then use one of the program language
					if (!programHasUserLang) {
						let availableLanguages = currentProgram.languages.filter((lang) =>
							lang.code.includes(userLang.split('-')[0])
						)
						return value[
						availableLanguages.find((lang) => lang.is_default === 1)?.code ||
						currentProgram.languages.find((lang) => lang.is_default === 1)?.code
							] || this.fallbackTry(value)
					} else {
						//Use the user language
						return value[userLang] || this.fallbackTry(value)
					}
				} else {
					return value[userLang] || this.fallbackTry(value)
				}
			})
		)
	}

	fallbackTry(value){
		//Get available langs of current item
		let available_langs = Object.keys(value)

		//Find the similar one compared to the current selected
		let similar = available_langs.filter((lang) =>
			lang.includes(localStorage.getItem('lang').split('-')[0])
		)
		let lang_to_use = null;
		//If exist use the similar one
		if(similar.length){
			lang_to_use = similar[0]
		} else if(available_langs.length) {
			lang_to_use = available_langs[0] //Use the first from the object lang property
		}
		return lang_to_use ? value[lang_to_use] : null
	}
}
