import { Component, OnInit } from '@angular/core'
// import * as fromActions from '../store/actions/app.actions'
import { Store } from '@ngrx/store'
import { AppState } from '../store/reducers'
import packageInfo from '../../../package.json'


@Component({
	selector: 'app-root',
	templateUrl: `./auth.component.html`,
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
	currentYear: number
	version: string

	constructor(private store: Store<AppState>) {
		this.currentYear = new Date().getFullYear()
		this.version = packageInfo.version
	}

	ngOnInit(): void {
		//this.store.dispatch(fromActions.changeBodyClass({class: 'text-center'}))
	}
}
