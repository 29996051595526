<div class="input-container">
	<label [id]="'label-'+id" [for]="id" [class]="labelClass">
		{{ label }} <span *ngIf="showOptional && !isRequired && label" translate>(optional)</span>
		<span *ngIf="isRequired && label" class="asterisk">* <span class="sr-only">{{' required field' | translate}}</span></span>
		<span *ngIf="helperText" class="helper-text"> ({{ helperText }}) </span>
	</label>
	<div class="input-group" 	[ngClass]="{ 'mb-3': !availablePlaceholders?.length }"
	>
		<input
			[id]="id"
			[type]="type"
			[placeholder]="placeholder || ('Enter ' | translate) + label"
			[(ngModel)]="value"
			(input)="onChange($event.target.value)"
			(blur)="onTouched()"
			[class]="(isValid ? ' ' : 'is-invalid ') + css"
			[disabled]="isDisabled"
			[attr.aria-invalid]="!isValid"
			[attr.aria-describedby]="
				describedBy ? describedBy + ' ' + id + '-error' : id + '-error'
			"
			[attr.aria-labelledby]="labelledBy"
		/>
		<div *ngIf="showPassword" class="show-pw">
			<span role="button" tabindex="0" (click)="changeType()" class="material-icons-outlined">
				<span aria-hidden="true"> visibility</span>
				<span class="sr-only" > Show or hide password </span>
			</span>
		</div>
		<div *ngIf="tooltip != ''" class="tooltip-input">
		<ng-template #tipContent>
			<h2 translate>Password Requirements</h2>
			<p translate>New password must contain:</p>
			<ul>
				<li translate>At least 8 characters</li>
				<li translate>At least 1 uppercase letter (A-Z)</li>
				<li translate>At least 1 lowercase letter (a-z)</li>
				<li translate>At least 1 digit (0-9)</li>
				<li translate>At least 1 special character (!@#$%^&*()-[]?.)</li>
			</ul>
		</ng-template>
			<div placement="right-top" [triggers]="'hover focus'" [ngbTooltip]="tooltip === 'pw-tooltip' ? tipContent : tooltip" [tooltipClass]="tooltip === 'pw-tooltip' ? 'my-custom-tooltip' : ''">
					<span class="material-icons-outlined">
						info
				</span>
			</div>
		</div>
		<div *ngIf="addon2" class="input-group-append">
			<span class="input-group-text" [id]="id + '-addon2'">
				{{ addon2 }}
			</span>
		</div>
	</div>
	<div class="available-placeholders-wrapper" *ngIf="availablePlaceholders?.length">
		<div *ngIf="placeholderStyle == 'select'" class="row">
			<div class="col-4">
				<div class="input-container multi-select custom-ng-select">
					<custom-form-select
						[id]="'function'"
						[items]="availablePlaceholders"
						[bindValue]="'key'"
						[bindLabel]="'name'"
						[showLabel]="false"
						[label]="'Insert Placeholders' | translate"
						[inputName]="'Function' | translate"
						(change)="addPlaceholder()"
						[(ngModel)]="selectedPlaceHolder"
						[placeholder]="'Insert Placeholders' | translate"
					></custom-form-select>
				</div>
			</div>
		</div>
		<div *ngIf="placeholderStyle == 'button'">
			<span class="mr-3" [id]="'insert-placeholder-'+ id" translate>Insert Placeholder(s):</span>
			<button [attr.aria-describedby]="'insert-placeholder-'+ id + ' label-'+id" type="button" class="mr-3 mb-2 btn btn-outline" *ngFor="let p of availablePlaceholders; let i = index" (click)="addPlaceholder(p.field || p.key)">{{p.name || p.display_name[currentLang]}}</button>
		</div>
	</div>

	<error-field
		class="error-field"
		[inputName]="label || errorName"
		[inputKey]="id"
		[error]="theControl"
	></error-field>
</div>
