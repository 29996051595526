import { createAction, props } from '@ngrx/store'
import { Country } from '../reducers/country.reducer'

export const loadCountries = createAction(
	'[Country List] Load Countries',
	props<{ params?: any }>()
)

export const loadCollectionSuccess = createAction(
	'[Country Effect] Load Countries Success',
	props<{ data: Country[]; total?: number; from?: string }>()
)
