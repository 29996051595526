import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { AppService } from '../../app.service'
import { forOwn, isArray } from 'lodash-es'

@Injectable()
export class MembersService {
	private baseUrl: string = `${environment.apiUrl}/user`
	private _previousMemberSelected: number

	constructor(private http: HttpClient, private appService: AppService) {}

	getMember(memberId, params) {
		return this.http.get(`${this.baseUrl}/${memberId}`, { params })
	}

	get previousMemberSelected(): number {
		return this._previousMemberSelected
	}

	set previousMemberSelected(value: number) {
		this._previousMemberSelected = value
	}

	addTransaction({ account_id, payload, transaction_type }) {
		return this.http.post(
			`${environment.apiUrl}/admin/account/${account_id}/${transaction_type}`,
			payload
		)
	}

	removeAddress(memberId, addressId) {
		return this.http.delete(`${environment.apiUrl}/admin/address/${addressId}`)
	}

	addAddress(memberId, payload, orderId?) {
		if (orderId) {
			return this.http.put(`${environment.apiUrl}/admin/order/${orderId}`, {
				shipping_address: payload,
				action: 'update_shipping_address'
			})
		} else if (payload.id) {
			return this.http.put(
				`${environment.apiUrl}/admin/address/${payload.id}`,
				payload
			)
		} else {
			return this.http.post(`${environment.apiUrl}/admin/address`, {
				user_id: memberId,
				...payload
			})
		}
	}

	validateAddress(payload) {
		return this.http.post(
			`${environment.apiUrl}/admin/validateAddress`,
			payload
		)
	}

	saveNote(memberId, payload) {
		return this.http.post(`${this.baseUrl}/member/${memberId}/note`, payload)
	}

	updateMember(payload) {
		if (payload.id) {
			return this.http.put(`${this.baseUrl}/${payload.id}`, payload)
		} else {
			return this.http.post(`${this.baseUrl}`, payload)
		}
	}

	updatePassword(userId, payload) {
		return this.http.put(`${this.baseUrl}/${userId}/reset-password`, payload)
	}

	getAllMembers(pagination) {
		let params: any = {
			count: pagination.pageSize,
			page: pagination.activeIndex,
			filter: JSON.stringify(pagination.search)
		}

		return this.http.get(`${this.baseUrl}`, { params })
	}

	getAccount(account_id) {
		return this.http.get(`${environment.apiUrl}/admin/account/${account_id}`, {
			params: {
				program_id: this.appService.current.program
			}
		})
	}

	getMemberAccounts(memberId, accountId) {
		return this.http.get(`${environment.apiUrl}/admin/account`, {
			params: {
				is_primary_medium: '1',
				program_id: this.appService.current.program,
				member_id: memberId,
				account_id: accountId
			}
		})
	}

	getAccountCurrency(currency_id) {
		return this.http.get(`${environment.apiUrl}/admin/currency/${currency_id}`)
	}

	getAllTransactions(pagination) {
		let payload: HttpParams = new HttpParams()
			.set('count', pagination.pageSize)
			.set('page', pagination.activeIndex)

		forOwn({ ...pagination.search }, (val, key) => {
			if (isArray(val)) {
				val.forEach((item) => {
					payload = payload.append(`${key}[]`, item)
				})
			} else {
				payload = payload.append(key, val)
			}
		})

		if (pagination.sortBy.column) {
			payload = payload.append('sort_field', pagination.sortBy.column)
			payload = payload.append('sort_direction', pagination.sortBy.dir)
			payload = payload.append(
				'sorting',
				JSON.stringify({
					[payload.get('sort_field')]: payload.get('sort_direction')
				})
			)
		}

		return this.http.get(`${environment.apiUrl}/admin/account/transaction`, {
			params: payload
		})
	}

	getAllHistoricalTransactions(pagination) {
		let params: any = {
			count: pagination.pageSize,
			page: pagination.activeIndex,
			...pagination.search
		}

		return this.http.get(`${environment.apiUrl}/admin/member/transaction`, {
			params
		})
	}

	loginAs(params) {
		// return this.http.get(`${environment.apiUrl}/admin/currency/${currency_id}`)

		return this.appService.getSources([
			'admin/program',
			params.program_id,
			'user',
			params.member_id,
			'get-member-token'
		])
		// .subscribe((response: any) => {
		// 	console.log(response)
		// 	// location.href = `${response.url}?token=${response.token}&account_id=${queryParams.params.account_id}&member_id=${queryParams.params.member_id}&medium_id=${queryParams.params.medium_id}`
		// })
	}
}
