<div class="input-container">
	<input
		[id]="id"
		type="checkbox"
		[(ngModel)]="value"
		(ngModelChange)="onChange(value)"
		(blur)="onTouched()"
		[class]="(isValid ? ' ' : 'is-invalid ') + css"
		[disabled]="isDisabled"
		[attr.aria-invalid]="!isValid"
		[attr.aria-labelledBy]="labelledBy ? labelledBy : null"
		[attr.aria-describedby]="describedBy ? describedBy : null"
	/>
	<label [for]="id" class="toggle-button">
		<span class="slider round"></span>
		<span class="display-label">
			{{ (value ? enableLabel : disableLabel) | translate }}
		</span>
	</label>
</div>
