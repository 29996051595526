import {NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap'
import {Injectable} from '@angular/core'
import {TranslateService} from "@ngx-translate/core";
import * as _ from "lodash";
import moment from 'moment'
import {selectUserDateFormat, UserState} from "../store/reducers/user.reducer";
import {select, Store} from "@ngrx/store";
import { TranslationWidth } from "@angular/common";


function toInteger(value: any): number {
	return parseInt(`${value}`, 10)
}

function isNumber(value: any): value is number {
	return !isNaN(toInteger(value))
}

function padNumber(value: number) {
	if (isNumber(value)) {
		return `0${value}`.slice(-2)
	} else {
		return ''
	}
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

	private date_format = null

	constructor(private store$: Store<UserState>) {
		super();
		this.store$.pipe(select(selectUserDateFormat('short', false))).subscribe((f)=>{
			this.date_format = f
		})
	}

	parse(value: string): NgbDateStruct {
		if (value) {
			let date = moment(value,this.date_format)
			return { day: date.date(), month: date.month()+1, year: date.year() }
		}
		return null
	}

	format(date: NgbDateStruct): string {
		if(date){
			let d = moment({ year: date.year, month: date.month-1, day: date.day});
			return d.locale(localStorage.getItem('lang')).format(this.date_format)
		} else {
			return ''
		}
	}
}

@Injectable()
export class DateI18nFormatter extends NgbDatepickerI18n {

	private momentData = {}

	constructor(private translateService: TranslateService,
				private datepickerConfig: NgbDatepickerConfig) {
		super();
		//Set first day of week
		datepickerConfig.firstDayOfWeek = 1

		//Populate the data with the localized values from moment (for extra customization can provide own list)
		let languages = ['en-CA','fr-CA']
		_.forEach(languages,(l)=>{
			//For each language retrieve the weekdays/months from moment
			moment.locale(l)
			this.momentData[l] = {weekdays: moment.weekdaysShort(false), months: moment.months()}
		})
		//Set moment locale to current language
		moment.locale(this.translateService.currentLang)
	}

	getMonthShortName(month: number): string {
		return this.momentData[this.translateService.currentLang].months[month-1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.year}-${date.month}-${date.day}`;
	}

	getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		//Workaround to display the sunday as the last day of the week
		if(weekday>6){
			weekday = 0
		}
		return this.momentData[this.translateService.currentLang].weekdays[weekday];
	}
}
