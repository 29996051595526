import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import {
	loginSuccess,
	logoutSuccess,
	saveProfileSuccess,
	updateUserTeam,
	userUpdated
} from '../actions/app.actions'

export const userStateFeatureKey = 'userState'

export interface User {
	id: number
	email: string
	can_login_as: boolean
	first_name: string
	last_name: string
	datepicker_time: string
	team?: any
	datepicker_short: string
	datepicker_long: string
	phone: string
	title: string
	is_owner: boolean
	preferredLanguage: string
	partner_id: number
}

export interface UserState {
	isLoggedIn: boolean
	user: User
}

const initialUserState: UserState = {
	isLoggedIn: !!localStorage.getItem('token'),
	user: undefined
}

export const userReducers = createReducer(
	initialUserState,
	on(loginSuccess, (state) => {
		return {
			...state,
			isLoggedIn: true,
			error: null
		}
	}),
	on(userUpdated, (state, action) => {
		return {
			...state,
			user: {
				...action.user,
				datepicker_time: action.user.datepicker_time || 'hh:mm a',
				datepicker_short: action.user.datepicker_short || 'yyyy-MM-DD',
				datepicker_long: action.user.datepicker_long || 'MMMM DD, YYYY',
				preferredLanguage: action.user.preferredLanguage || 'en-CA'
			}
		}
	}),
	on(updateUserTeam, (state, action) => {
		return {
			...state,
			user: {
				...state.user,
				team: {
					...state.user.team,
					allowed_actions: action.allowed_actions
				}
			}
		}
	}),
	on(logoutSuccess, (state) => {
		return {
			...state,
			isLoggedIn: false,
			error: null
		}
	}),
	on(saveProfileSuccess, (state, action) => {
		const user = action.user
		return {
			...state,
			user: {
				id: user.id,
				email: user.email,
				can_login_as: user.can_login_as,
				first_name: user.first_name,
				last_name: user.last_name,
				datepicker_time: user.datepicker_time,
				datepicker_short: user.datepicker_short,
				datepicker_long: user.datepicker_long,
				phone: user.phone,
				title: user.title,
				is_owner: user.is_owner,
				preferredLanguage: user.preferredLanguage,
				partner_id: user.partner_id
			}
		}
	})
)

export const selectFeature = createFeatureSelector<UserState>(
	userStateFeatureKey
)

export const selectIsLoggedIn = createSelector(
	selectFeature,
	(state: UserState) => state.isLoggedIn
)

export const selectUserDateFormat = (
	dateFormat: string,
	showTime: boolean = false
) => {
	return createSelector(selectFeature, (state: UserState) => {
		// when show time is true, need to return 'long/short, time'
		// else just return 'long/short'
		if(state.user){
			return showTime
				? `${state.user[`datepicker_${dateFormat}`]}, ${
					state.user.datepicker_time
				}`
				: state.user[`datepicker_${dateFormat}`]
		} else {
			return null
		}


	})
}

export const selectUser = createSelector(
	selectFeature,
	(state: UserState) => state.user
)

