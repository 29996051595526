import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from 'lodash-es'
import {TranslateService} from "@ngx-translate/core";

@Directive({
    selector: '[editorAccessible]',
})
export class AngularEditorAccessibilityDirective implements AfterViewInit{

    @Input() labelledBy: string = null;
    @Input() describedBy: string = null;

    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        setTimeout(()=>{
            let button_list = document.querySelectorAll('.angular-editor-toolbar-set button')
            button_list.forEach((elem)=>{
                elem.setAttribute('tabindex','0')
            })
            let select_list = document.querySelectorAll('.ae-picker-options button')
            select_list.forEach((elem)=>{
                elem.setAttribute('tabindex','0')
            })

            let text_area = this.elementRef.nativeElement.querySelector('.angular-editor-textarea')
            if(this.labelledBy && text_area){
                text_area.setAttribute('aria-labelledby',this.labelledBy)
            }
            if(this.describedBy && text_area){
                text_area.setAttribute('aria-describedby',this.describedBy)
            }

        },0)
    }


}
