import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-login',
	templateUrl: './link-expired.component.html'
})
export class LinkExpiredComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
