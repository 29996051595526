import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../store/reducers'
import {appSuccess, appWarning, loading, loginSuccess, resolveLoading} from "../../store/actions/app.actions";
import { Observable } from 'rxjs'
import { ValidateAllFormFields } from '../../helpers/helper.functions'
import { AppService } from '../../app.service'
import { selectLoading, selectError } from '../../store/reducers/layout.reducer'
import {authError, login} from '../store/auth.actions'
import { tap } from 'rxjs/operators'
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import {Actions, ofType} from "@ngrx/effects";
import {saveSystemSuccess, updateSystemSuccess} from "../../dashboard/system_users/store/system.actions";
import {marker as tr} from "@biesbjerg/ngx-translate-extract-marker/lib/ngx-translate-extract-marker";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup
	ladda$: Observable<boolean>
	loginError$: Observable<string>
	alert: any = {
		type: 'danger',
		title: '',
		actionText: 'Reset your password',
		url: '/auth/forgot'
	}

	constructor(
		private formBuilder: FormBuilder,
		public appService: AppService,
		public _actions$: Actions,
		private store: Store<AppState>,
		private route: ActivatedRoute,
		public router: Router,
	) {}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			user_account: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		})
		this.ladda$ = this.store.pipe(select(selectLoading('loginLoading')))
		this.loginError$ = this.store.pipe(
			select(selectError('login')),
			tap((res) => {
				this.alert.title = res
			})
		)
		const isExpired  = this.route.snapshot.queryParamMap.get('session_expired');

		if(isExpired){
			this.store.dispatch(
				appWarning({
					warning: {
						logout: {
							text: _('Session Expired'),
							description: _(
								'Your session expired due to inactivity. Please log in again to resume your session.'
							),
							showClose: false
						}
					}
				})
			)
		}

	}

	onSubmit() {
		if (!this.loginForm.valid) {
			ValidateAllFormFields(this.loginForm)
			return
		}
		this.store.dispatch(loading({ loadingKey: 'loginLoading' }))
		this.store.dispatch(login(this.loginForm.value))
		this._actions$.pipe(ofType(authError)).subscribe((res) => {
			this.router.navigateByUrl('auth/new-password').then(()=>{
				this.store.dispatch(resolveLoading({loadingKey: 'loginLoading'}))
			});
		})
	}

	onClose() {
		this.alert = {}
	}
}
