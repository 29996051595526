import { Pipe, PipeTransform } from '@angular/core'
import { map } from 'lodash-es'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

@Pipe({ name: 'translateLink' })
export class TranslatedLinks implements PipeTransform {
	transform(value: string): string {
		let urls = value.split('/')
		urls = map(urls, (url) => {
			return !isNaN(url) ? url : _(url)
		})
		return urls.join('/')
	}
}
