import { createAction, props } from '@ngrx/store'
import { Partner } from '../reducers/partner.reducer'

export const search = createAction(
	'[Partners Search] Load Partners Success',
	props<{ params: any; selected?: any[] }>()
)

export const loadCollectionSuccess = createAction(
	'[Partners Effect] Load Partners Success',
	props<{ data: Partner[]; total?: number; from?: string }>()
)
