<div id="main-select-container" [class.empty]="!allowList">
	<div>
		<div class="select-wrapper">
			<label for="partner-id" translate>Partner</label>
			<ng-select
				[id]="'partner-select-id'"
				[class.no-border-select]="!allowList"
				class="custom"
				[items]="resources.partner.list"
				bindLabel="name"
				[labelForId]="'partner-id'"
				(change)="onChange('partner')"
				bindValue="id"
				selectAccessible
				[searchable]="false"
				[(ngModel)]="resources.partner.selected"
				[model]="resources.partner.selected"
				[clearable]="false"
				placeholder="{{ 'Select Partner' | translate }}"
			></ng-select>
			<!-- [labelForId]="'partner-id'" -->
			<!-- [attr.aria-labelledby]="'partner-label'"
				[attr.aria-label]="'partner-label'" -->
		</div>
		<div *ngIf="!resources.customer.hidden" class="select-wrapper">
			<label for="customer-id" translate>Customer</label>
			<ng-select
				[id]="'customer-select-id'"
				[class.no-border-select]="!allowList"
				class="custom"
				[items]="resources.customer.list"
				bindLabel="name"
				selectAccessible
				[searchable]="false"
				[labelForId]="'customer-id'"
				(change)="onChange('customer')"
				bindValue="id"
				[(ngModel)]="resources.customer.selected"
				[model]="resources.customer.selected"
				[clearable]="false"
				placeholder="{{ 'Select Customer' | translate }}"
			></ng-select>
		</div>
		<div *ngIf="!resources.program.hidden" class="select-wrapper">
			<label for="program-id" translate>Program</label>
			<ng-select
				[id]="'program-select-id'"
				[class.no-border-select]="!allowList"
				class="custom"
				[items]="resources.program.list"
				[searchable]="false"
				bindLabel="name"
				selectAccessible
				[labelForId]="'program-id'"
				(change)="onChange('program')"
				bindValue="id"
				[(ngModel)]="resources.program.selected"
				[model]="resources.program.selected"
				[clearable]="false"
				placeholder="{{ 'Select Program' | translate }}"
			></ng-select>
		</div>
	</div>
	<custom-button
		css="full primary d-block large dark ml-auto mr-auto mt-30 mb-30"
		*ngIf="!allowList"
		[ariaDescribedby]="!resources.program.selected ? 'source-selector-title': null"
		[label]="('Show' | translate) + ' ' + title"
		(onClick)="showContent()"
	></custom-button>
</div>
