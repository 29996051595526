<div>
	<img
		class="mb-20 no-max"
		src="/assets/images/podium_loyalty_logo_white.svg"
		[alt]="'Podium brand logo' | translate"
	/>
	<h1 translate>THANK YOU</h1>
	<h2 class="mb-30 h3" translate>
		If an account exists with that username, we will send you
		<br />
		instructions to reset your password.
	</h2>
	<a
		[routerLink]="'/auth/login' | translateLink"
		class="light underline"
		translate
	>
		Return to login screen
	</a>
</div>
