<a
	*ngIf="link"
	[attr.id]="id ? id : null"
	class="{{ 'r-button' + (css ? ' ' + css : '') }}"
	[routerLink]="link"
	[ngClass]="{ loading: ladda | async, disabled:  disabled}"
	[attr.aria-describedby]="ariaDescribedby ? ariaDescribedby : null"
	[attr.aria-labelledby]="ariaLabelledby ? ariaLabelledby : null"
>
	<span class="spinner-border spinner-border-sm" role="status">
		<span class="sr-only" *ngIf="ladda | async">
			{{ 'Loading' | translate }}...
		</span>
	</span>
	<span *ngIf="label" class="label" [id]="id + '-label'">
		{{ label | translate}}
	</span>
</a>
<button
	*ngIf="!link"
	[attr.id]="id ? id : null"
	[type]="type || 'button'"
	class="{{ 'r-button' + (css ? ' ' + css : '') }}"
	[ngClass]="{ loading: ladda | async }"
	(middleclick)="onMiddleClick.emit()"
	(click)="onClick.emit()"
	[disabled]="(ladda | async) || disabled"
	[attr.aria-describedby]="ariaDescribedby ? ariaDescribedby : null"
	[attr.aria-labelledby]="ariaLabelledby ? ariaLabelledby : null"
	[attr.role]="role ? role : null"
>
	<span class="spinner-border spinner-border-sm" role="status">
		<span class="sr-only" *ngIf="ladda | async">
			{{ 'Loading' | translate }}...
		</span>
	</span>
	<ng-content *ngIf="!label"></ng-content>
	<span *ngIf="label" class="label" [attr.id]="id ? id + '-label' : null">
		{{ label }}
	<span *ngIf="chevron" class="material-icons-outlined chev-down" >
     expand_more
  </span>
	<span *ngIf="srOnlyText" class="sr-only" >
		 {{srOnlyText}}
	</span>
	</span>


</button>
