<div class="input-container">
  <label [for]="id" [class]="labelClass">
    {{ label }} <span *ngIf="showOptional && !isRequired && label" translate>(optional)</span>
    <span *ngIf="isRequired" class="asterisk">* <span class="sr-only">{{' required field' | translate}}</span></span>
    <span *ngIf="helperText" class="helper-text">({{ helperText }})</span>
    <span *ngIf="popoverText" class="d-inline-block">
      <button
        type="button"
        class="btn btn-outline-secondary datepicker-popover"
        placement="top"
        [ngbPopover]="popoverText"
      ><span class="material-icons-outlined primary" aria-hidden="true">question_mark</span></button>
    </span>
  </label>
  <div class="input-group mb-3">
    <input
           class="custom-dp"
           ngbDatepicker
           [class]="(isValid ? ' ' : 'is-invalid ') + css"
           #dp
           placeholder="{{date_format$ | async }}"
           name="dp"
           type="text"
           [readOnly]="readOnly"
           (blur)="onTouchedFn()"
           (ngModelChange)="onChange($event)"
           [(ngModel)]="value"
           (keydown.enter)="datepicker.toggle()"
           (input)="onChange($event)"
           [id]="id"
           [disabled]="isDisabled"
           [attr.aria-describedby]="
				    describedBy ? describedBy + ' ' + id + '-error' : id + '-error'
			     "
    >
    <div  tabindex="0"  role="button" class="input-group-append" [ngClass]="{ 'disabled': isDisabled }"  (click)="!isDisabled ? datepicker.open() : null" (keydown.enter)="!isDisabled ? datepicker.open() : null">
      <span class="input-group-text">
        <span class="material-icons-outlined">event
        <span class="sr-only">{{label}} Open Calendar</span>
      </span>
      </span>
    </div>
  </div>
  <!-- popup calendar -->
  <div class="dp-hide">
    <input
      name="datepicker"
      class="custom-datepicker form-control mt-20"
      ngbDatepicker
      #datepicker="ngbDatepicker"
      (dateSelect)="onDateSelection($event)"
      [startDate]="value"
      [minDate]="minDate"
      [maxDate]="maxDate"
      outsideDays="hidden"
      tabindex="-1"
      aria-hidden="true"
    />
  </div>
  <error-field
    class="error-field"
    [inputName]="label"
    [inputKey]="id"
    [error]="theControl"
  ></error-field>
</div>
