import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { AuthComponent } from './auth.component'
import { ForgotComponent } from './forgot/forgot.component'
import { ForgotSuccessComponent } from './forgot-success/forgot-success.component'
import { NewPasswordComponent } from './enter-new-password/new-password.component'
import { LinkExpiredComponent } from './link-expired/link-expired.component'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { ChangePasswordComponent } from "./change-password-from-email/change-password.component";

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: _('login'),
				component: LoginComponent,
				title: _('Login')
			},
			{
				path: _('forgot'),
				component: ForgotComponent,
				title: _('Forgot Password')
			},
			{
				path: _('forgot-success'),
				component: ForgotSuccessComponent
			},
			{
				path: _('new-password'),
				component: NewPasswordComponent
			},
			{
				path: _('change-password/:token/:email'),
				component: ChangePasswordComponent
			},
			{
				path: _('link-expired'),
				component: LinkExpiredComponent
			}
		]
	}
]

@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
	declarations: []
})
export class AuthRoutingModule {}
