import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { AppState } from '../../store/reducers'
import { Observable } from 'rxjs'
import { ValidateAllFormFields } from '../../helpers/helper.functions'
import { selectError, selectLoading } from '../../store/reducers/layout.reducer'
import { map } from 'rxjs/operators'
import { forgotPassword } from '../store/auth.actions'
import { AppService } from '../../app.service'
import { loading } from '../../store/actions/app.actions'

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
	forgotForm: FormGroup
	ladda$: Observable<boolean>
	forgotError$: Observable<any>

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		public appService: AppService,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.forgotForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]]
		})
		this.ladda$ = this.store.pipe(select(selectLoading('forgotLoading')))
		this.forgotError$ = this.store.pipe(
			select(selectError('forgot')),
			map((res) => {
				return {
					type: 'danger',
					title: res
				}
			})
		)
	}

	onSubmit() {
		if (!this.forgotForm.valid) {
			ValidateAllFormFields(this.forgotForm)
			return
		}
		this.store.dispatch(loading({ loadingKey: 'forgotLoading' }))
		this.store.dispatch(forgotPassword(this.forgotForm.value))
	}
}
