import { Validators } from '@angular/forms'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

export interface Sorting {
	column: string
	dir: string
}

export const orderActions: any = {
	order_fee: _('Add Fee'),
	order_request_return: _('Return Request'),
	order_request_refund: _('Refund Request'),
	order_instant_refund: _('Instant Refund'),
	order_trace_request: _('Trace Request'),
	order_cancel: _('Cancel Item(s)'),
	order_resend_egift_card: _('Resend eGift Card')
}

const TYPE_DEFAULT = _('default')
const TYPE_SHIPPING = _('shipping')
const TYPE_BILLING = _('billing')
const TYPE_DIGITAL = _('digital')
const TYPE_UNSAVED = _('unsaved')
const TYPE_ALTERNATE = _('alternate')

export interface Token {
	access_token: string
	expires_in?: number
}

export enum Can {
	EDIT = 2,
	SHOW = 1
}

export enum TableActions {
	ADDINGPAGE = 'pagination',
	CLEARING = 'clearing',
	DELATING = 'delating',
	SORTING = 'sorting',
	SEARCHING = 'searching'
}

export const currencies = [
	{
		code: 'AUD',
		value: `AUD / ${_('Australian Dollar')}`
	},
	{
		code: 'BRL',
		value: `BRL / ${_('Brazilian Real')}`
	},
	{
		code: 'CAD',
		value: `CAD / ${_('Canadian Dollar')}`
	},
	{
		code: 'CHF',
		value: `CHF / ${_('Swiss Franc')}`
	},
	{
		code: 'CNY',
		value: `CNY / ${_('Yuan Renminbi')}`
	},
	{
		code: 'DKK',
		value: `DKK / ${_('Danish Krone')}`
	},
	{
		code: 'EUR',
		value: `EUR / ${_('Euro')}`
	},
	{
		code: 'GBP',
		value: `GBP / ${_('Pound Sterling')}`
	},
	{
		code: 'HKD',
		value: `HKD / ${_('Hong Kong Dollar')}`
	},
	{
		code: 'INR',
		value: `INR / ${_('Indian Rupee')}`
	},
	{
		code: 'JPY',
		value: `JPY / ${_('Yen')}`
	},
	{
		code: 'KRW',
		value: `KRW / ${_('Won')}`
	},
	{
		code: 'MXN',
		value: `MXN / ${_('Mexican Peso')}`
	},
	{
		code: 'MYR',
		value: `MYR / ${_('Malaysian Ringgit')}`
	},
	{
		code: 'NOK',
		value: `NOK / ${_('Norwegian Krone')}`
	},
	{
		code: 'NZD',
		value: `NZD / ${_('New Zealand Dollar')}`
	},
	{
		code: 'PLN',
		value: `PLN / ${_('Zloty')}`
	},
	{
		code: 'RUB',
		value: `RUB / ${_('Russian Ruble')}`
	},
	{
		code: 'SEK',
		value: `SEK / ${_('Swedish Krona')}`
	},
	{
		code: 'SGD',
		value: `SGD / ${_('Singapore Dollar')}`
	},
	{
		code: 'THB',
		value: `THB / ${_('Baht')}`
	},
	{
		code: 'TRY',
		value: `TRY / ${_('Turkish Lira')}`
	},
	{
		code: 'TWD',
		value: `TWD / ${_('New Taiwan Dollar')}`
	},
	{
		code: 'USD',
		value: `USD / ${_('US Dollar')}`
	},
	{
		code: 'ZAR',
		value: `ZAR / ${_('Rand')}`
	}
]

export const TRANSACTION_TYPES = [
	{
		name: _('Purchase'),
		code: 'PUR'
	},
	{
		name: _('Refund'),
		code: 'REF'
	},
	{
		name: _('Bonus'),
		code: 'BON'
	},
	{
		name: _('Fee'),
		code: 'FEE'
	},
	{
		name: _('Reversal'),
		code: 'REV'
	},
	{
		name: _('Other'),
		code: 'OTH'
	},
	{
		name: _('Transfer'),
		code: 'TRA'
	},
	{
		name: _('Redemption'),
		code: 'RED'
	},
	{
		name: _('Incentive'),
		code: 'EINC'
	},
	{
		name: _('Earn Reward'),
		code: 'EREW'
	},
	{
		name: _('Adjustment'),
		code: 'ADJ'
	},
	{
		name: _('Bonus Base Earn'),
		code: 'BBE'
	}
]

export const GROUP_FILTER_SYSTEM_USER_STATUSES = [
	{ id: 'active', name: _('Active') },
	{ id: 'nonActive', name: _('Non-Active') }
]

export const dateFormat = {
	datepicker_time: [
		{ id: 'hh:mm a', label: '04:02 pm', value: 'hh:mm a' },
		{ id: 'HH:mm', label: '16:02', value: 'HH:mm' }
	],
	datepicker_short: [
		{
			id: 'YYYY-MM-DD',
			datePickerFormat: 'yyyy-MM-dd',
			label: '2021-02-19',
			value: 'YYYY-MM-DD'
		},
		{
			id: 'DD-MM-YYYY',
			datePickerFormat: 'dd-MM-yyyy',
			label: '19-02-2021',
			value: 'DD-MM-YYYY'
		},
		{
			id: 'MM/DD/YYYY',
			datePickerFormat: 'MM/dd/yyyy',
			label: '02/19/2021',
			value: 'MM/DD/YYYY'
		},
		{
			id: 'DD-MMM-YYYY',
			datePickerFormat: 'dd-MMM-yyyy',
			label: '19-Feb-2021',
			value: 'DD-MMM-YYYY'
		}
	],
	datepicker_long: [
		{
			id: 'MMMM DD, YYYY',
			label: 'February 19, 2021',
			value: 'MMMM DD, YYYY'
		},
		{
			id: 'DD MMMM YYYY',
			label: '19 February 2021',
			value: 'DD MMMM YYYY'
		}
	]
}

export const COUNTRIES = [
	{ id: 'AT', name: _('Austria') },
	{ id: 'AU', name: _('Australia') },
	{ id: 'BE', name: _('Belgium') },
	{ id: 'BR', name: _('Brazil') },
	{ id: 'CA', name: _('Canada') },
	{ id: 'CN', name: _('China') },
	{ id: 'CO', name: _('Colombia') },
	{ id: 'CZ', name: _('Czech Republic') },
	{ id: 'DK', name: _('Denmark') },
	{ id: 'EG', name: _('Egypt') },
	{ id: 'FI', name: _('Finland') },
	{ id: 'FR', name: _('France') },
	{ id: 'DE', name: _('Germany') },
	{ id: 'HK', name: _('Hong Kong') },
	{ id: 'IE', name: _('Ireland') },
	{ id: 'IN', name: _('India') },
	{ id: 'IT', name: _('Italy') },
	{ id: 'MX', name: _('Mexico') },
	{ id: 'NZ', name: _('New Zealand') },
	{ id: 'NL', name: _('Netherlands') },
	{ id: 'NO', name: _('Norway') },
	{ id: 'PH', name: _('Philippines') },
	{ id: 'PT', name: _('Portugal') },
	{ id: 'PR', name: _('Puerto Rico') },
	{ id: 'RO', name: _('Romania') },
	{ id: 'RU', name: _('Russian Federation') },
	{ id: 'SA', name: _('Saudi Arabia') },
	{ id: 'SG', name: _('Singapore') },
	{ id: 'SI', name: _('Slovenia') },
	{ id: 'ES', name: _('Spain') },
	{ id: 'SE', name: _('Sweden') },
	{ id: 'CH', name: _('Switzerland') },
	{ id: 'TR', name: _('Turkey') },
	{ id: 'AE', name: _('UAE') },
	{ id: 'GB', name: _('United Kingdom') },
	{ id: 'US', name: _('USA') }
]

export const PROVINCES = {
	AU: [
		{ id: 'NSW', name: _('New South Wales') },
		{ id: 'QLD', name: _('Queensland') },
		{ id: 'SA', name: _('South Australia') },
		{ id: 'TAS', name: _('Tasmania') },
		{ id: 'VIC', name: _('Victoria') },
		{ id: 'WA', name: _('Western Australia') },
		{ id: 'ACT', name: _('Australian Capital Territory') },
		{ id: 'NT', name: _('Northern Territory') }
	],
	CA: [
		{ id: 'AB', name: _('Alberta') },
		{ id: 'BC', name: _('British Columbia') },
		{ id: 'MB', name: _('Manitoba') },
		{ id: 'NB', name: _('New Brunswick') },
		{ id: 'NL', name: _('Newfoundland and Labrador') },
		{ id: 'NT', name: _('Northwest Territories') },
		{ id: 'NS', name: _('Nova Scotia') },
		{ id: 'NU', name: _('Nunavut') },
		{ id: 'ON', name: _('Ontario') },
		{ id: 'PE', name: _('Prince Edward Island') },
		{ id: 'QC', name: _('Quebec') },
		{ id: 'SK', name: _('Saskatchewan') },
		{ id: 'YT', name: _('Yukon') }
	],
	NZ: [
		{ id: 'AUK', name: _('Auckland') },
		{ id: 'BOP', name: _('Bay of Plenty') },
		{ id: 'CAN', name: _('Canterbury') },
		{ id: 'GIS', name: _('Gisborne') },
		{ id: 'HKB', name: _("Hawke's Bay") },
		{ id: 'MBH', name: _('Marlborough') },
		{ id: 'MWT', name: _('Manawatu-Wanganui') },
		{ id: 'NSN', name: _('Nelson') },
		{ id: 'NTL', name: _('Northland') },
		{ id: 'OTA', name: _('Otago') },
		{ id: 'STL', name: _('Southland') },
		{ id: 'TAS', name: _('Tasman') },
		{ id: 'TKI', name: _('Taranaki') },
		{ id: 'WKO', name: _('Waikato') },
		{ id: 'WGN', name: _('Wellington') },
		{ id: 'WTC', name: _('West Coast') },
		{ id: 'CIT', name: _('Catham Islands Territory') }
	],
	US: [
		{ id: 'Al', name: _('Alabama') },
		{ id: 'AK', name: _('Alaska') },
		{ id: 'AZ', name: _('Arizona') },
		{ id: 'AR', name: _('Arkansas') },
		{ id: 'CA', name: _('California') },
		{ id: 'CO', name: _('Colorado') },
		{ id: 'CT', name: _('Connecticut') },
		{ id: 'DE', name: _('Delaware') },
		{ id: 'FL', name: _('Florida') },
		{ id: 'GA', name: _('Georgia') },
		{ id: 'HI', name: _('Hawaii') },
		{ id: 'ID', name: _('Idaho') },
		{ id: 'IL', name: _('Illinois') },
		{ id: 'IN', name: _('Indiana') },
		{ id: 'IA', name: _('Iowa') },
		{ id: 'KS', name: _('Kansas') },
		{ id: 'KY', name: _('Kentucky') },
		{ id: 'LA', name: _('Louisiana') },
		{ id: 'KS', name: _('Kansas') },
		{ id: 'ME', name: _('Maine') },
		{ id: 'MD', name: _('Maryland') },
		{ id: 'MA', name: _('Massachusetts') },
		{ id: 'MI', name: _('Michigan') },
		{ id: 'MN', name: _('Minnesota') },
		{ id: 'MS', name: _('Mississippi') },
		{ id: 'MO', name: _('Missouri') },
		{ id: 'MT', name: _('Montana') },
		{ id: 'NE', name: _('Nebraska') },
		{ id: 'NV', name: _('Nevada') },
		{ id: 'NH', name: _('New Hampshire') },
		{ id: 'NJ', name: _('New Jersey') },
		{ id: 'NM', name: _('New Mexico') },
		{ id: 'NY', name: _('New York') },
		{ id: 'NC', name: _('North Carolina') },
		{ id: 'ND', name: _('North Dakota') },
		{ id: 'OH', name: _('Ohio') },
		{ id: 'OK', name: _('Oklahoma') },
		{ id: 'OR', name: _('Oregon') },
		{ id: 'PA', name: _('Pennsylvania') },
		{ id: 'RI', name: _('Rhode Island') },
		{ id: 'SC', name: _('South Carolina') },
		{ id: 'SD', name: _('South Dakota') },
		{ id: 'TN', name: _('Tennessee') },
		{ id: 'TX', name: _('Texas') },
		{ id: 'UT', name: _('Utah') },
		{ id: 'VT', name: _('Vermont') },
		{ id: 'VA', name: _('Virgina') },
		{ id: 'WA', name: _('Washington') },
		{ id: 'WV', name: _('West Virginia') },
		{ id: 'WI', name: _('Wisconsin') },
		{ id: 'GU', name: _('Guam') },
		{ id: 'PR', name: _('Puerto Rico') },
		{ id: 'VI', name: _('Virgin Islands') }
	]
}

export const GROUP_FILTER_STATUSES = [
	{ id: 'active', name: _('Active') },
	{ id: 'nonActive', name: _('Non-Active') },
	{ id: 'leaveOfAbsence', name: _('On Leave') },
	{ id: 'terminated', name: _('Terminated') },
	{ id: 'lockedForFraud', name: _('Locked') },
	{ id: 'retired', name: _('Retired') },
	{ id: 'registered', name: _('Registered') },
	{ id: 'notRegistered', name: _('Not Registered') },
	{ id: 'sso', name: _('SSO') },
	{ id: 'validated', name: _('Validated') }
]

export interface AdvancedSearchType {
	label: string
	single?: boolean // for multiselect. [true] to return a single option instead of an Array
	type: string // multiselect | checkbox | text | number
	placeholder: string
	val?: string // [name] as default. to define for multiselect when the option param to show is different from the default one
	value?: string | string[] // populated value
	key?: string // [id] as default. to define for multiselect when the value of the option to return is different from the default one
	all?: any[] // for multiselect. to define when the the collection is stored locally
	main?: boolean
}

export interface Program {
	id?: number
	name: string
	slug: string
	group_id: number
	created_at?: Date
	customer_id?: number
	program_configurations?: any[]
	active?: boolean | string
	languages?: any[]
	product_type_settings?: any[]
	available_languages?: string[]
	default_language?: string
	order_prefix?: string
	loading?: boolean
	programURL?: string
	countries?: any[]
}

export interface Customer {
	id?: number
	name: string
	subdomain: string
	created_at?: Date
	partner_id?: number
	loading?: boolean
	vanity?: string
	slug?: string
	billing?: any[]
	has_order_item_quantities?: boolean
	secure_password_reset?: boolean
	sso_xml_admin?: any[]
	sso_xml?: any[]
	sso_attributes?: any[]
	active?: boolean
	is_sso_jit_enabled?: boolean
	sso_attributes_admin?: any[]
	sso_required_attributes?: any[]
	sso_enabled?: boolean
	address?: any
	customer_number?: number
}

export interface Pagination {
	total?: number
	action: TableActions
	search?: any
	from?: string
	pageSize: number
	activeIndex: number
	sortBy: Sorting
}

export const phoneValidatorPattern = Validators.pattern(
	'(1[.-])?(\\(\\d{3}\\)[.-]|(\\d{3}[.-]?)){2}\\d{4}'
)

export const postalCodeValidatorPattern = Validators.pattern(
	/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
)

export const zipCodeValidatorPattern = Validators.pattern(
	/^[0-9]{5}(?:-[0-9]{4})?$/
)
