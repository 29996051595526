import { Injectable } from '@angular/core'
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from '@angular/router'
import { AppState } from '../store/reducers'
import { select, Store } from '@ngrx/store'
import { selectIsLoggedIn } from '../store/reducers/user.reducer'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private store: Store<AppState>) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		return this.store.pipe(
			select(selectIsLoggedIn),
			map((selectIsLoggedIn) => {
				if (!selectIsLoggedIn) {
					this.router.navigate(['auth/login'])
					// queryParams: { returnUrl: state.url }
					return false
				} else {
					return true
				}
			})
		)
	}
}
