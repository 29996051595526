<div>
	<img
		class="mb-20 no-max"
		src="/assets/images/podium_loyalty_logo_white.svg"
		[alt]="'Podium brand logo' | translate"
	/>
	<h1 translate>Link Expired</h1>
	<h2 class="mb-30 h3" translate>
		It appears the link you have has expired.
		<br />
		Please click below to login or reset your password.
	</h2>
	<a
		[routerLink]="'/auth/login' | translateLink"
		class="light underline"
		translate
	>
		Return to login screen
	</a>
</div>
