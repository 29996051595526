import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import { forOwn, mapValues, isEmpty, findIndex, find } from 'lodash-es'
import { AppService } from '../../app.service'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { changeCurrent } from '../../store/actions/app.actions'
import { LayoutState } from '../../store/reducers/layout.reducer'
import { Store } from '@ngrx/store'

@Component({
	selector: 'source-selector',
	templateUrl: './source-selectors.component.html',
	styleUrls: ['./source-selectors.component.scss']
})
export class SourceSelector implements OnInit, OnChanges {
	@Input() sourcesNeeded: any
	@Input() title: string
	@Input() selectTitle: string = _(
		'Select a Partner, Customer and Program to begin'
	)
	@Output() onSourcesChanged = new EventEmitter()
	public allowList: boolean
	public resources: any

	constructor(
		public appService: AppService,
		private store$: Store<LayoutState>
	) {
		this.resources = {
			partner: {
				next: 'customer',
				list: JSON.parse(this.appService.getFromLocal('partners'))
			},
			customer: {
				filterBy: 'partner',
				next: 'program',
				list: JSON.parse(this.appService.getFromLocal('customer'))
			},
			program: {
				filterBy: 'customer',
				list: JSON.parse(this.appService.getFromLocal('program'))
			}
		}

		forOwn(this.resources, (val, key) => {
			let sourceFromLocal = this.appService.getFromLocal(`current_${key}`)
			if (sourceFromLocal) {
				val.selected = parseInt(sourceFromLocal)
			}
		})
	}

	getSourceList(currentSource, nextSource) {
		return this.appService.getSources(currentSource.next, {
			with_paginator: 0,
			filter: JSON.stringify({
				[`${nextSource.filterBy}_id`]: currentSource.selected
			})
		})
	}

	showContent() {
		let allow: boolean = true

		forOwn(this.sourcesNeeded, (val, key) => {
			if (!this.resources[key].selected) {
				allow = false
			}
		})
		if (allow) {
			this.onSourcesChanged.emit()
			this.allowList = true
		}
	}

	onChange(source) {
		this.appService.saveToLocal(
			`current_${source}`,
			this.resources[source].selected
		)
		this.store$.dispatch(
			changeCurrent({
				source: source,
				value: this.resources[source].selected,
				fromLocal: false
			})
		)

		let emitValues = (nextSource) => {
			if (nextSource.next) {
				recursiveFetching(nextSource)
			} else {
				this.appService.current = mapValues(this.resources, (v) => v.selected)
				if (this.allowList) {
					this.onSourcesChanged.emit()
				}
			}
		}

		let recursiveFetching = (currentSource = this.resources[source]) => {
			const nextSource = this.resources[currentSource.next]
			if (nextSource) {
				if (!nextSource.hidden) {
					this.getSourceList(currentSource, nextSource).subscribe(
						(res: any) => {
							nextSource.list = res.data
							this.appService.saveToLocal(currentSource.next, nextSource.list)
							nextSource.selected = nextSource.list[0]?.id
							this.appService.saveToLocal(
								`current_${currentSource.next}`,
								nextSource.selected
							)
							this.store$.dispatch(
								changeCurrent({
									source: currentSource.next,
									value: nextSource.selected,
									fromLocal: false
								})
							)
							emitValues(nextSource)
						}
					)
				} else {
					nextSource.list = null
					this.appService.removeFromLocal(currentSource.next)
					this.appService.removeFromLocal(`current_${currentSource.next}`)
					emitValues(nextSource)
				}
			} else {
				this.appService.current = mapValues(this.resources, (v) => v.selected)
				if (this.allowList) {
					this.onSourcesChanged.emit()
				}
			}
		}
		recursiveFetching()
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Do this only on program details section
		if(changes.sourcesNeeded?.currentValue?.program?.adding){

			if(changes.sourcesNeeded?.currentValue?.program?.id){
				let program_idx = findIndex(this.resources.program.list, {id: changes.sourcesNeeded?.currentValue?.program?.id})
				//If the program list is not present then call the API
				if(!this.resources.program.list?.length || program_idx < 0){
					this.appService.getSources("program", {
						with_paginator: 0,
						filter: JSON.stringify({
								[`customer_id`]: this.resources.customer.selected
							}
						)
					}).subscribe((res: any)=>{
						//Update the list of programs
						this.resources.program.list = res.data
						this.appService.saveToLocal("program", this.resources.program.list)
					})
				}
				//Show the program in the selector
				this.resources.program.hidden = false
				this.resources.program.selected = changes.sourcesNeeded.currentValue.program.id
				this.appService.saveToLocal('current_program',this.resources.program.selected)
				this.appService.current = mapValues(this.resources, (v) => v.selected)
			} else if(!changes.sourcesNeeded?.currentValue?.program?.id){
				//If the program is null hide it
				this.resources.program.hidden = true
				this.resources.program.selected = null
				this.appService.removeFromLocal('current_program')
				this.appService.current = mapValues(this.resources, (v) => v.selected)
			}
		}

	}

	ngOnInit(): void {
		let allow: boolean = true
		if (this.sourcesNeeded) {
			forOwn(this.resources, (val, key) => {
				if (this.sourcesNeeded[key]) {
					if (!val.selected) {
						allow = false
					} else {
						val.hidden = false
					}
					if (this.sourcesNeeded[key].list) {
						val.list = this.sourcesNeeded[key].list
					}
					if (val.selected && val.next) {
						const nextSource = this.resources[val.next]
						if (!nextSource.list) {
							this.getSourceList(val, nextSource).subscribe((res: any) => {
								nextSource.list = res.data
								this.appService.saveToLocal(val.next, nextSource.list)
							})
						}
					}
				} else {
					val.hidden = true
				}
			})
		}

		this.allowList = allow
	}
}
