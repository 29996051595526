import { Component, Input } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'

@Component({
	selector: 'error-field',
	templateUrl: './error-field.component.html',
	styleUrls: ['./error-field.component.scss']
})
export class ErrorFields {
	@Input() error: FormControl | AbstractControl
	@Input() inputName: string
	@Input() inputKey: string
	@Input() availableTypes?: string[]

	constructor() {}

}
