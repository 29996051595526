<div class="input-container multi-select">
	<label [for]="id + '-select'" [class]="labelClass">
		{{ label | translate }}  <span *ngIf="showOptional && !isRequired && label" translate>(optional)</span>
		<span *ngIf="isRequired && label" class="asterisk">* <span class="sr-only">{{' required field' | translate}}</span></span>
	</label>
	<!-- 
		tabindex="0"
		[tabIndex]="-1"
		this is super confused, tabindex = '0' will set the ng-select focusable,
 		while [tabIndex]="-1" will set the -1 tabindex to the 'input' inside the ng-select, so the input is not focusable,
		so in this case, we can focus on the ng-select instead of focus on the input, mainly for WA, screen reader
	 -->
	<ng-select
		[id]="id"
		[class]="(isValid ? ' ' : 'is-invalid ') + css + ' custom-ng-select custom'"
		[items]="items"
		[(ngModel)]="value"
		[bindLabel]="bindLabel"
		[labelForId]="id + '-select'"
		[bindValue]="bindValue"
		[multiple]="multiple"
		[closeOnSelect]="!multiple"
		[clearable]="clearable"
		[disabled]="isDisabled"
		[notFoundText]="'No items found' | translate"
		selectAccessible
		[placeholder]="placeholder || ('Enter ' | translate) + label"
		(blur)="onTouched()"
		#select
		(change)="selectItem($event)"
		[attr.aria-invalid]="!isValid"
		[inputAttrs]="{
			'aria-describedby': id + '-error ' + id + '-description ',
			'aria-invalid': !isValid
		}"
	>
		<ng-template ng-option-tmp let-item="item" let-search="searchTerm">
			<div><span [ngOptionHighlight]="search">{{
				_.isObject(item[bindLabel])
					? (item[bindLabel] | getLangParam | async)
					: (item[bindLabel] | translate)
				}}</span></div>
		</ng-template>
		<ng-template ng-label-tmp let-item="item" let-clear="clear">
			<span class="ng-value-label">
				{{
					_.isObject(item[bindLabel])
						? (item[bindLabel] | getLangParam | async)
						: (item[bindLabel] | translate)
				}}
			</span>
			<span
				*ngIf="multiple"
				class="ng-value-icon right"
				role="button"
				[attr.aria-describedby]="item.id + '-value-label'"
				(click)="clear(item); $event.stopPropagation()"
				(keydown.enter)="
					clear(item); $event.preventDefault(); $event.stopPropagation()
				"
				(keydown.space)="
					clear(item); $event.preventDefault(); $event.stopPropagation()
				"
			>
				<span class="sr-only" translate></span>
				<span aria-hidden="true">×</span>
			</span>
		</ng-template>
	</ng-select>
	<div class="sr-only" *ngIf="!disabled" [id]="id + '-description'">
		<span *ngIf="multiple" translate>Multiple selection</span>
		<span translate>
			Press enter to see the options and use arrows to navigate
		</span>
	</div>
	<div *ngIf="!items?.length" class="sr-only" [id]="'no-items-found-' + id">
		{{'No items found' | translate}}
	</div>
	<error-field
		class="error-field"
		[inputKey]="id"
		[inputName]="inputName || label"
		[error]="theControl"
	></error-field>
</div>
