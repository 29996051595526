import { Injectable } from '@angular/core'
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from '@angular/router'
import { AppState } from '../store/reducers'
import { select, Store } from '@ngrx/store'
import { selectIsLoggedIn } from '../store/reducers/user.reducer'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanActivate {
	constructor(private router: Router, private store: Store<AppState>) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> {
		// not logged in so redirect to login page with the return url
		return this.store.pipe(
			select(selectIsLoggedIn),
			map((selectIsLoggedIn) => {
				if (selectIsLoggedIn) {
					this.router.navigate(['home'])
					return false
				} else {
					return true
				}
			})
		)
	}
}
