import { createAction, props } from '@ngrx/store'
import { ConfigurationType } from '../reducers/program-configuration-type.reducer'

export const loadConfigurationTypes = createAction(
	'[ConfigurationTypes List] Load ConfigurationTypes',
	props<{ params?: any }>()
)

export const loadCollectionSuccess = createAction(
	'[ConfigurationTypes Effect] Load ConfigurationTypes Success',
	props<{ data: ConfigurationType[]; total?: number; from?: string }>()
)
