import {
	Component,
	ElementRef,
	Input,
	Self,
	ViewChild
} from '@angular/core'
import {
	AbstractControl,
	ControlValueAccessor,
	NgControl,
	ValidationErrors,
	Validator
} from '@angular/forms'
import { find } from 'lodash-es'
import { closestByClass } from '../helper.functions'

const regEx = {
	email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
}

@Component({
	selector: 'multi-input',
	templateUrl: './multi-input.component.html',
	styleUrls: ['./multi-input.component.scss']
})
export class MultiInputComponent implements ControlValueAccessor, Validator {
	public items: any[]
	public expanded: boolean = false
	@Input() selectedItems = []
	@Input() id: string
	@Input() label: string
	@Input() helperText: string
	@Input() placeholder: string
	@Input() type: string
	@ViewChild('myInput') myInput: ElementRef

	constructor(@Self() private controlDirective: NgControl) {
		controlDirective.valueAccessor = this
	}

	ngOnInit() {
		this.controlDirective.control.setValidators([this.validate.bind(this)])
		this.controlDirective.control.updateValueAndValidity()
	}

	validate(c: AbstractControl): ValidationErrors | null {
		if (!c.value?.length) {
			return {
				required: {}
			}
		} else if (
			this.type !== 'text' &&
			find(c.value, (value) => !value.match(regEx[this.type]))
		) {
			return {
				[this.type]: {}
			}
		} else {
			return null
		}
	}

	public onChange: any = () => {}
	public onTouched: any = () => {}

	writeValue(obj: any[]): void {
		if (obj && obj.length) {
			this.selectedItems = obj
		}
	}

	get isRequired() {
		try {
			return this.controlDirective.control.validator(
				this.controlDirective.control
			).required
		} catch (err) {
			return false
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn
	}

	onBlur($event) {
		$event.stopImmediatePropagation()
		$event.preventDefault()

		if (
			!$event.relatedTarget ||
			!closestByClass($event.relatedTarget, 'r-multi-input')
		) {
			this.onTouched($event)
		}
	}

	addValue() {
		let email = this.myInput.nativeElement.value.trim()
		if (email.length) {
			this.selectItem(email)
			this.myInput.nativeElement.value = ''
		}
	}

	get theControl() {
		return this.controlDirective.control
	}

	selectItem(item) {
		this.selectedItems = [...this.selectedItems, item]
		this.onChange(this.selectedItems)
	}

	removeItem(item) {
		this.selectedItems = this.selectedItems.filter((sItem) => sItem !== item)
		this.onChange(this.selectedItems)
	}

	executeKeydown($event) {
		switch ($event.keyCode) {
			case 188: // ,
				this.addValue()
				return false
		}
	}
}
