import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { findIndex } from 'lodash-es'

@Component({
	selector: 'custom-table',
	templateUrl: './table.component.html',
	styleUrls: ['table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
	@Input() ths: any[]
	@Input() css: string
	@Input() noThumbnail: boolean
	@Input() total: BehaviorSubject<number>
	@Input() description: string
	@Input() infinite: boolean
	@Input() sync: boolean = false
	@Input() collection: BehaviorSubject<any[]>
	@Input() currentPage: BehaviorSubject<number>
	@Input() pageSize: BehaviorSubject<number>
	@Input() loading: Observable<boolean>
	@Output() onSort = new EventEmitter()
	@Output() onAdd = new EventEmitter()
	@ViewChild('table') customTable: ElementRef
	public sort_index = null

	private page = 0
	private isScrollLoad = false
	public previous_list_length = null
	constructor() {}

	sortBy(column, index) {
		this.onSort.emit(column)
		this.sort_index = index
	}

	getSortDirection(direction) {
		if (direction) {
			return direction === 'desc' ? _('descending') : _('ascending')
		} else {
			return _('none')
		}
	}

	addMore(isScroll = false) {
		this.onAdd.emit()
		this.isScrollLoad = isScroll
	}

	ngOnInit(): void {
		this.currentPage.subscribe((count) => {
			this.page = count
		})
		let sorted_idx = findIndex(this.ths, (s) => {
			return s.sorting
		})
		if (sorted_idx) {
			this.sort_index = sorted_idx
		}
	}

	ngAfterViewInit(): void {
		this.collection.subscribe((list) => {
			//Add role alert only after 1st page of result is loaded
			if (this.previous_list_length != null && list?.length == 0) {
				//Do this since when the page loads the SR reads the loading alert and not this one, in this way it can read both
				setTimeout(() => {
					let no_results = document.getElementById('no_results')
					if (no_results) {
						no_results.setAttribute('role', 'alert')
						//Remove it after 500ms so next time a search with 0 results is performed the SR announce the alert again
						setTimeout(() => {
							no_results.removeAttribute('role')
						}, 500)
					}
				}, 200)
			}
			//Move focus only after the 1st time && when use load more button
			if (this.page > 1 && !this.isScrollLoad) {
				//let table: any = document.getElementsByClassName("table r-table")[0]

				setTimeout(() => {
					let table = this.customTable.nativeElement
					let idx_to_add = this.previous_list_length
					let rows = table.querySelectorAll('.item-row')
					if (!rows.length) {
						rows = table.querySelectorAll('tr')
						idx_to_add++
					}
					if (this.previous_list_length && rows[idx_to_add]) {
						//Move focus to the last active element
						rows[idx_to_add].setAttribute('tabindex', -1)
						rows[idx_to_add].focus()
						//Update previous list length
						this.previous_list_length = list.length
					}
				}, 0)
			} else {
				this.previous_list_length = list?.length || 0
			}
		})
	}
}
