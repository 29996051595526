import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { AuthRoutingModule } from './auth/auth-routing.module'
import { AuthGuard } from './helpers/auth.guard'
import { reducers } from './store/reducers'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'
import { EffectsModule } from '@ngrx/effects'
import { AppEffects } from './store/app.effects'
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule
} from '@angular/common/http'
import {
	NavigationActionTiming,
	StoreRouterConnectingModule
} from '@ngrx/router-store'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { AppGuard } from './helpers/app.guard'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { CustomSerializer } from './helpers/custom-route-serializer'
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap'
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { TokenInterceptor } from './helpers/token.interceptor'
import { HttpErrorInterceptor } from './helpers/error.handler'
import { clearState } from './helpers/helper.functions'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core'
import { TranslatedLinks } from './helpers/pipes/translated-links.pipe'
import { CapitalizeFirst } from './helpers/pipes/capitalizefirst.pipe'
import { SSOComponent } from './sso/sso.component'
import { MembersService } from './dashboard/members/members.service'
import { SharedModule } from './helpers/shared.module'
import {DateI18nFormatter, NgbDateCustomParserFormatter} from "./helpers/dateformat";
import {DragDropModule} from "@angular/cdk/drag-drop";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
}

@NgModule({
	declarations: [AppComponent, SSOComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		AuthRoutingModule,
		DragDropModule,
		HttpClientModule,
		StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production
		}),
		EffectsModule.forRoot([AppEffects]),
		StoreRouterConnectingModule.forRoot({
			serializer: CustomSerializer,
			//routerState: RouterState.Full,
			navigationActionTiming: NavigationActionTiming.PostActivation
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			useDefaultLang: true,
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgbModule,
		LoadingBarHttpClientModule,
		SharedModule
	],
	providers: [
		AuthGuard,
		AppGuard,
		TranslatedLinks,
		CapitalizeFirst,
		MembersService,
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		},
		{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
		{ provide: NgbDatepickerI18n, useClass: DateI18nFormatter }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
