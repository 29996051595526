import { createAction, props } from '@ngrx/store'
import { Order } from '../reducers/order.reducer'
import { TableActions } from '../../helpers/shared.interfaces'

export const loadOrders = createAction(
	'[Order List] Load Orders',
	props<{ memberId?: number }>()
)

export const selectOrdersByParams = createAction(
	'[Order List] Select Orders',
	props<{ params?: any }>()
)

export const updateActions = createAction(
	'[Order Actions List] Update Orders',
	props<{ order: number; actions: any[] }>()
)

export const updateOrderAddressSuccess = createAction(
	'[Order Effect] Edit Order Address Success',
	props<{ orderId: number; changes: any }>()
)

export const updateOrderAddress = createAction(
	'[Order Effect] Update Order Address',
	props<{ orderId: number; memberId: number; address: any }>()
)

export const addNoteSuccess = createAction(
	'[Order Effect] Add Note Success',
	props<{ orderId: number; note: any }>()
)
export const addOrderItemNoteSuccess = createAction(
	'[Order Effect] Add Order Item Note Success',
	props<{ orderId: number; orderItemId: number; note: any }>()
)

export const updateNotesPagination = createAction(
	'[Order Effect] Add Note Page',
	props<{ orderId: number }>()
)

export const addNoteToOrder = createAction(
	'[Order Effect] Add Note To Order',
	props<{ orderId: number; note: string }>()
)

export const addOrderItemNoteToOrder = createAction(
	'[Order Effect] Add Order Item Note To Order',
	props<{ orderId: number; orderItemId: number; note: string }>()
)

export const searchOrders = createAction(
	'[Order] Search Orders',
	props<{ search: any; action?: TableActions }>()
)

export const clearOrder = createAction(
	'[Order Effect] Clear Order',
	props<{ orderId: number }>()
)

export const toggleLoading = createAction(
	'[Order Effect] Load Order',
	props<{ orderId: number; loading: boolean }>()
)

export const loadOrdersSuccess = createAction(
	'[Order Effect] Load Orders Success',
	props<{ orders: Order[]; total?: number }>()
)

export const saveOrder = createAction(
	'[Order Effect] Save Orders',
	props<{ payload: any }>()
)

export const saveOrderSuccess = createAction(
	'[Order Effect] Save Orders Success',
	props<{ order: Order }>()
)

export const updateOrder = createAction(
	'[Order Edit] Edit Order',
	props<{ payload: any }>()
)

export const updateOrderSuccess = createAction(
	'[Order Edit] Edit Order Success',
	props<{ id: number; changes: any }>()
)

export const changePage = createAction(
	'[Order Pages] Change Page',
	props<{ page: number }>()
)

export const addPage = createAction('[Order Pages] Add Page')

export const sortTableBy = createAction(
	'[Order Pages] Sort By',
	props<{ column: string }>()
)

export const loadOrder = createAction(
	'[Order Edit] Load Order',
	props<{ orderId: number }>()
)

export const loadOrderSuccess = createAction(
	'[Order Effect] Load Order Success',
	props<{ order: Order }>()
)

export const submitAction = createAction(
	'[Order Effect] Submit action To Order',
	props<{
		orderId: number
		payload: any
		theAction: string
		actionName: string
	}>()
)
export const submitActionSuccess = createAction(
	'[Order Effect] Submit action To Order Success',
	props<{ id: number; changes: any; action: string }>()
)
