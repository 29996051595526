import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
	Self,
	ViewChildren
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { forEach } from 'lodash-es'
import { closestByClass, validateRadio } from '../helper.functions'

@Component({
	selector: 'radio',
	templateUrl: './radio.component.html'
})
export class RadioComponent implements ControlValueAccessor, AfterViewInit {
	private eleMap: Map<any, ElementRef>
	private selectedIndex: number = 0
	@Input() selectedItem = ''
	@Output() onValueChange = new EventEmitter<string>()
	@Input() radioGroup: any[]
	@Input() ariaLabelledby = null
	@Input() group_id = null
	@ViewChildren('labels') labels: QueryList<ElementRef>
	public onChange: any = () => {}
	public onTouched: any = () => {}

	constructor(@Self() private controlDirective: NgControl) {
		controlDirective.valueAccessor = this
	}

	ngOnInit() {
	}

	writeValue(obj: string): void {
		this.selectedItem = obj

	}

	registerOnChange(fn: any): void {
		this.onChange = fn
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn
	}

	selectItem($event, item) {
		$event.stopImmediatePropagation()
		$event.preventDefault()
		if (!item.disabled && !this.isDisabled) {
			this.selectedItem = item.value
			this.onChange(item.value)
			this.onValueChange.emit(item.value)
		}
	}

	ngAfterViewInit(): void {
		this.eleMap = new Map<any, ElementRef>()
		forEach(this.radioGroup, (item) => {
			let currentOption = this.labels.find((f) => {
				return f.nativeElement.getAttribute('for') === item.id
			})
			this.eleMap.set(item, currentOption)
		})
	}

	onBlur($event) {
		$event.stopImmediatePropagation()
		$event.preventDefault()

		if (
			!$event.relatedTarget ||
			!closestByClass($event.relatedTarget, 'radio')
		) {
			this.onTouched($event)
		}
	}

	focusElement() {
		let nextElement = this.eleMap.get(this.radioGroup[this.selectedIndex])
		nextElement.nativeElement.focus()
	}

	goForward($event) {
		if (this.selectedIndex < this.radioGroup.length - 1) {
			this.selectedIndex++
		} else {
			this.selectedIndex = 0
		}
		this.selectItem($event, this.radioGroup[this.selectedIndex])
		this.focusElement()
	}

	goBack($event) {
		if (this.selectedIndex !== 0) {
			this.selectedIndex--
		} else {
			this.selectedIndex = this.radioGroup.length - 1
		}
		this.selectItem($event, this.radioGroup[this.selectedIndex])
		this.focusElement()
	}

	get isDisabled() {
		return this.controlDirective.control.disabled
	}

	executeKeydown($event, item) {
		switch ($event.keyCode) {
			case 13: // enter
				this.selectItem($event, item)
				break
			case 40: //Down Arrow
				$event.stopImmediatePropagation()
				$event.preventDefault()
				this.goForward($event)
				break
			case 37: // Left Arrow
				$event.stopImmediatePropagation()
				$event.preventDefault()
				this.goBack($event)
				break
			case 38: // Up Arrow
				$event.stopImmediatePropagation()
				$event.preventDefault()
				this.goBack($event)
				break
			case 39: // Right Arrow
				$event.stopImmediatePropagation()
				$event.preventDefault()
				this.goForward($event)
				break
		}
	}

	labelId(id) {
		return id.replace(/\s/g, '') + '-label'
	}
}
