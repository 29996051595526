import {
	Component,
	OnInit,
	EventEmitter,
	Input,
	Output,
	Self,
	Optional, ViewChild
} from "@angular/core";
import {
	ControlValueAccessor,
	NgControl,
	AbstractControl
} from '@angular/forms'
import * as _ from 'lodash-es'
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
	selector: 'custom-form-select',
	templateUrl: './custom-form-select.component.html',
	styleUrls: ['./custom-form-select.component.scss']
})
export class CustomFormSelectComponent implements OnInit, ControlValueAccessor {
	@Input() id: string
	@Input() type: 'text' | 'email' | 'number' | 'password' = 'text'
	@Input() label: string
	@Input() showLabel: boolean = true
	@Input() css: string
	@Input() bindValue: string = 'id'
	@Input() bindLabel: string = 'name'
	@Input() inputName: string
	@Input() describedBy: string
	@Input() placeholder: string = ''
	@Input() items: any[] = []
	@Input() disabled: boolean = false
	@Input() showOptional: boolean = true
	@Input() multiple: boolean = false
	@Input() clearable: boolean = false
	@Output() change = new EventEmitter<string>()

	_ = _
	value: any = ''
	@ViewChild('select') select: NgSelectComponent


	constructor(
		// Retrieve the dependency only from the local injector,
		// not from parent or ancestors.
		@Self()
		// We want to be able to use the component without a form,
		// so we mark the dependency as optional.
		@Optional()
		private ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this
		}
	}

	ngOnInit() {}

	/**
	 * Write form value to the DOM element (model => view)
	 */
	writeValue(value: any): void {
		this.value = value
	}

	/**
	 * Write form disabled state to the DOM element (model => view)
	 */
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled
	}

	/**
	 * Update form when DOM element value changes (view => model)
	 */
	registerOnChange(fn: any): void {
		// Store the provided function as an internal method.
		this.onChange = fn
	}

	/**
	 * Update form when DOM element is blurred (view => model)
	 */
	registerOnTouched(fn: any): void {
		// Store the provided function as an internal method.
		this.onTouched = fn
	}

	onChange(value: any) {}
	onTouched() {
		this.select?.close()
	}

	get theControl() {
		return this.ngControl
	}

	get theValidator() {
		if (typeof this.ngControl?.control?.validator === 'function') {
			return this.ngControl.control.validator({} as AbstractControl)
		}
		return null
	}

	get isRequired() {
		return this.theValidator ? this.theValidator.required : false
	}

	get isValid() {
		// when contol set to disabled, this.ngControl.valid became false
		return (
			!this.ngControl?.touched ||
			this.ngControl?.valid ||
			this.ngControl?.disabled
		)
	}

	get isDisabled() {
		return this.ngControl.disabled
	}

	get labelClass() {
		let theClass: string = ''
		if (this.isRequired) {
			theClass += 'required '
		}

		if (!this.showLabel) {
			theClass += 'sr-only '
		}
		return theClass
	}

	selectItem(event) {
		if (event?.length) {
			const result = event.map((e) => e[this.bindValue])
			this.onChange(result)
			this.change.emit(result)
		} else if(event){
			this.onChange(event[this.bindValue])
			this.change.emit(event[this.bindValue])
		} else {
			this.onChange(null)
			this.change.emit(null)
		}
	}
}
