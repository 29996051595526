import { Injectable } from '@angular/core'
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { AppService } from '../app.service'
import { map } from 'rxjs/operators'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private appService: AppService) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.appService.getFromLocal('token')}`,
				locale: this.appService.getFromLocal('lang')
			}
		})
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// do stuff with response and headers you want
					let currentToken = event.headers.get('Authorization')
					if (currentToken) {
						currentToken = currentToken.replace('Bearer ', '')
						if (this.appService.getFromLocal('token') !== currentToken) {
							this.appService.saveToLocal('token', currentToken)
						}
					}
				}
				return event
			})
		)
	}
}
