import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

declare var $: any;

@Component({
	selector: 'query-builder',
	templateUrl: './query-builder.component.html',
	styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements AfterViewInit{
	@Input() id: string = null
	@Input() queryOptions: any = null
	@Output() onRuleChange = new EventEmitter()
	builderRules: any = null
	builderValid: boolean = true

	constructor() {}

	ngAfterViewInit(): void {
		this.initializeBuilder()
	}

	initializeBuilder(){

		$('#' + this.id).queryBuilder(this.queryOptions);

		$('#' + this.id).on('rulesChanged.queryBuilder', (event, rule)=> {
			//Do this to trigger the validation
			this.builderValid = $('#' + this.id).queryBuilder('validate',{skip_empty: true})

			//Emit data
			this.onRuleChange.emit({isValid: this.builderValid, rules: this.builderRules })

		});
	}

	public isValid(): boolean{
		return $('#' + this.id).queryBuilder('validate',{skip_empty: true})
	}

	public getRules(){
		return $('#' + this.id).queryBuilder('getRules',{skip_empty: true})
	}
	public setRules(rules){
		$('#' + this.id).queryBuilder('setRules', rules);
	}

	public reset(){
		$('#' + this.id).queryBuilder('reset');
	}


}
