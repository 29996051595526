import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
	selector: 'custom-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
	@Input() ladda: Observable<boolean>
	@Input() id: string = null
	@Input() label: string
	@Input() chevron: boolean = false
	@Input() disabled: boolean
	@Input() type: string
	@Input() srOnlyText: string
	@Input() css: string
	@Input() link: any[]
	@Input() role: string = null
	@Output() onClick = new EventEmitter()
	@Output() onMiddleClick = new EventEmitter()
	@Input() ariaLabelledby: string = null
	@Input() ariaDescribedby: string = null

	constructor() {}

}
