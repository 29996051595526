import { Directive } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";
import moment from "moment";
import { type } from "../helper.functions";
import { select, Store } from "@ngrx/store";
import { selectUserDateFormat, UserState } from "../../store/reducers/user.reducer";
@Directive({
	selector: '[dateFormatValidator]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useClass: DateValidatorDirective,
			multi: true
		}
	]
})
export class DateValidatorDirective implements Validator {

	validator: ValidatorFn;
	date_format: string

	constructor(
		private userStore$: Store<UserState>,
	) {
		this.validator = this.dateValidator();
		this.userStore$.pipe(
			select(selectUserDateFormat('short', false))
		).subscribe((res)=>{
			this.date_format = res
		})
	}

	validate(c: FormControl) {
		return this.validator(c);
	}

	dateValidator(): ValidatorFn {
		return (control: FormControl) => {
			if (control.value) {

				let user_format_date = moment(control.value, this.date_format,true)
				if(type(control.value) == 'Object'){
					return null
				}
				else if (user_format_date.isValid()) {
					return null
				} else {
					return 	{ 'pattern': true }
				}
			}

			return null
		};
	}
}
