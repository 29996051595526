import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { forOwn, isArray } from 'lodash-es'

@Injectable({
	providedIn: 'root'
})
export class OrdersService {
	private baseUrl: string = `${environment.apiUrl}/admin/order`

	constructor(private http: HttpClient) {}

	getTeamRelationType(programId) {
		return this.http.get(
			`${environment.apiUrl}/admin/program/${programId}/team-relation-type`
		)
	}

	getUserAccounts(member_id, program_id) {
		return this.http.get(`${environment.apiUrl}/admin/account`, {
			params: {
				member_id,
				program_id
			}
		})
	}

	submitAction(payload, orderId, action) {
		if (action === 'cancelItems') {
			return this.http.put(`${this.baseUrl}/${orderId}/cancelItems`, payload)
		} else {
			return this.http.post(`${this.baseUrl}/${orderId}/${action}`, payload)
		}
	}

	addFee(orderId, payload: any) {
		return this.http.post(
			`${environment.apiUrl}/admin/program/${payload.program_id}/order/${orderId}/fee-payment`,
			payload
		)
	}

	updateOrder(payload: any) {
		return this.http.put(`${this.baseUrl}/${payload.id}`, payload)
	}

	saveNote(orderId, payload) {
		return this.http.post(`${this.baseUrl}/${orderId}/note`, payload)
	}
	saveOrderItemNote(orderId, orderItemId, payload) {
		return this.http.post(`${this.baseUrl}/${orderId}/order_item/${orderItemId}/note`, payload)
	}

	getOrder(orderId) {
		return this.http.get(`${this.baseUrl}/${orderId}`)
	}

	getOrders(pagination: any, params?: any) {
		let payload: HttpParams = new HttpParams()
			.set('count', pagination?.pageSize)
			.set('page', pagination?.activeIndex)

		forOwn({ ...params, ...pagination?.search }, (val, key) => {
			if (isArray(val)) {
				val.forEach((item) => {
					payload = payload.append(`${key}[]`, item)
				})
			} else {
				payload = payload.append(key, val)
			}
		})

		if (pagination?.sortBy?.column) {
			payload = payload.append('sort_field', pagination?.sortBy.column)
			payload = payload.append('sort_direction', pagination?.sortBy.dir)
			payload = payload.append(
				'sorting',
				JSON.stringify({
					[payload.get('sort_field')]: payload.get('sort_direction')
				})
			)
		}

		return this.http.get(`${this.baseUrl}`, { params: payload })
	}

	orderRetry(orderId) {
		return this.http.post(`${this.baseUrl}/${orderId}/retry`,null)
	}
}
