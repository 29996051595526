import { Component, HostBinding, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AppState } from './store/reducers'
import { selectBodyClass } from './store/reducers/layout.reducer'
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap'
import {
	distinctUntilChanged,
	filter,
	map, take,
	takeUntil,
	tap
} from 'rxjs/operators'
import * as fromActions from './store/actions/app.actions'
import { forOwn } from 'lodash-es'
import { TranslateService } from '@ngx-translate/core'
import { AppService } from './app.service'
import { changeCurrent, changeLang } from './store/actions/app.actions'
import * as partnerActions from './store/actions/partner.actions'
import { Title } from '@angular/platform-browser'
import { selectRoute } from './store/reducers/route.reducer'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { TranslatedLinks } from './helpers/pipes/translated-links.pipe'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert
	@HostBinding('class.auth') auth: boolean = false

	currentRoute: string
	private baseTitle = 'Podium'
	private unsubscribe$ = new Subject<void>()

	private resources: any = {
		partner: {
			actions: partnerActions
		},
		customer: {},
		program: {}
	}

	constructor(
		private store$: Store<AppState>,
		private appService: AppService,
		private router: Router,
		private pipe: TranslatedLinks,
		private titleService: Title,
		private translate: TranslateService
	) {
		const payload = {}
		forOwn(this.resources, (val, key) => {
			let sources = JSON.parse(this.appService.getFromLocal(`${key}`))
			if (sources && val.actions) {
				this.store$.dispatch(
					val.actions.loadCollectionSuccess({ data: sources, from: 'storage' })
				)
			}
			let selectedSource: string = this.appService.getFromLocal(
				`current_${key}`
			)
			if (selectedSource) {
				payload[key] = selectedSource
				this.store$.dispatch(
					changeCurrent({ source: key, value: selectedSource, fromLocal: true })
				)
			}
		})

		this.appService.current = payload
	}

	ngOnInit(): void {
		let lang = this.appService.getFromLocal('lang')
		if (!lang) {
			lang = this.translate.getBrowserLang()
			lang = /(fr-CA|en-CA)/gi.test(lang) ? lang : 'en-CA'
			this.appService.saveToLocal('lang', lang)
		}
		this.translate.setDefaultLang(lang)
		this.translate.use(lang)
		this.store$.dispatch(changeLang({ lang }))

		this.store$
			.pipe(
				select(selectBodyClass),
				distinctUntilChanged()
				// filter((cssClass) => !!cssClass)
			)
			.subscribe((cssClass) => {
				this.auth =
					cssClass === 'auth' || cssClass === 'sso' || cssClass === 'error'
			})

		this.store$
			.pipe(
				select(selectRoute()),
				distinctUntilChanged(),
				filter((res) => !!res && !!res.state),
				takeUntil(this.unsubscribe$),
				map((res) => res.state)
			)
			.subscribe((val) => {
				if (this.currentRoute !== val.url) {
					this.currentRoute = val.url
					if (this.currentRoute !== '/auth/login') {
						this.cleanAlerts()
					}
				}
				if (val.data && val.data.title) {
					this.titleService.setTitle(`${this.baseTitle} - ${val.data.title}`)
				}
			})
	}

	cleanAlerts() {
		this.store$.dispatch(fromActions.cleanAlerts())
	}

	ngOnDestroy() {
		this.unsubscribe$.next()
		this.unsubscribe$.complete()
	}
}
