import {
	Component,
	OnInit,
	Input,
	Output,
	Self,
	Optional,
	EventEmitter
} from '@angular/core'
import {
	ControlValueAccessor,
	NgControl,
	AbstractControl
} from '@angular/forms'

@Component({
	selector: 'custom-form-toggle',
	templateUrl: './custom-form-toggle.component.html',
	styleUrls: ['./custom-form-toggle.component.scss']
})
export class CustomFormToggleComponent implements OnInit, ControlValueAccessor {
	@Input() id: string
	@Input() label: string
	@Input() showLabel: boolean = true
	@Input() css: string
	@Input() labelledBy: string
	@Input() describedBy: string
	@Input() enableLabel: string = 'Enable'
	@Input() disableLabel: string = 'Disable'
	@Output() inputModelChange = new EventEmitter<boolean>()

	value: boolean
	// disabled: boolean

	constructor(
		// Retrieve the dependency only from the local injector,
		// not from parent or ancestors.
		@Self()
		// We want to be able to use the component without a form,
		// so we mark the dependency as optional.
		@Optional()
		private ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = this
		}
	}

	ngOnInit() {}

	/**
	 * Write form value to the DOM element (model => view)
	 */
	writeValue(value: any): void {
		this.value = value
	}

	/**
	 * Write form disabled state to the DOM element (model => view)
	 */
	// setDisabledState(isDisabled: boolean): void {
	// 	this.disabled = isDisabled
	// }

	/**
	 * Update form when DOM element value changes (view => model)
	 */
	registerOnChange(fn: any): void {
		// Store the provided function as an internal method.
		this.onChange = fn
	}

	/**
	 * Update form when DOM element is blurred (view => model)
	 */
	registerOnTouched(fn: any): void {
		// Store the provided function as an internal method.
		this.onTouched = fn
	}

	onChange(value: any) {}
	onTouched() {}

	get theControl() {
		return this.ngControl
	}

	get theValidator() {
		if (typeof this.ngControl.control.validator === 'function') {
			return this.ngControl.control.validator({} as AbstractControl)
		}
		return null
	}

	// get isRequired() {
	// 	return this.theValidator ? this.theValidator.required : false
	// }

	get isValid() {
		// when contol set to disabled, this.ngControl.valid became false
		return (
			!this.ngControl.touched || this.ngControl.valid || this.ngControl.disabled
		)
	}

	get isDisabled() {
		return this.ngControl.disabled
	}
}
