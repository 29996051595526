import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from '@ngrx/router-store'

export interface RouterStateUrl {
	url: string
	params: Params
	queryParams: Params
	data: Data
	parent: ActivatedRouteSnapshot
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
	serialize(routerState: RouterStateSnapshot): RouterStateUrl {
		let route = routerState.root

		while (route.firstChild) {
			route = route.firstChild
		}

		const {
			url,
			root: { queryParams, parent }
		} = routerState
		const { params, data } = route

		// Only return an object including the URL, params and query params
		// instead of the entire snapshot
		return { url, params, queryParams, data, parent }
	}
}
