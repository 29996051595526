<form
	class="form-signin"
	[formGroup]="loginForm"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
>
	<img
		class="mb-20 no-max"
		src="/assets/images/podium_loyalty_logo_white.svg"
		[alt]="'Podium brand logo' | translate"
	/>
	<h1 class="h2 mb-30" [innerHTML]="'EMPOWERING_LOGIN' | translate"></h1>
	<alert-component></alert-component>

	<custom-form-input
		class="login-input"
		formControlName="user_account"
		type="email"
		[id]="'inputEmail'"
		[placeholder]="'Email Address' | translate"
		css="form-control dark"
		[label]="'Email Address' | translate"
		[showLabel]="false"
	></custom-form-input>

	<custom-form-input
		class="login-input"
		formControlName="password"
		type="password"
		[id]="'inputPassword'"
		[placeholder]="'Password' | translate"
		css="form-control dark mt-20"
		[label]="'Password' | translate"
		[showLabel]="false"
	></custom-form-input>

	<custom-button
		type="submit"
		css="outline secondary large mb-30 mt-20"
		[ladda]="ladda$"
		[label]="'Log in' | translate"
	></custom-button>
	<br />

	<a
		class="light underline"
		translate
		[routerLink]="'/auth/forgot' | translateLink"
	>
		Forgot your password?
	</a>
</form>
