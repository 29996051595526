import { routerReducer, RouterReducerState } from '@ngrx/router-store'
import {
	ActionReducer,
	ActionReducerMap,
	combineReducers,
	createFeatureSelector,
	createSelector
} from '@ngrx/store'
import { RouterStateUrl } from '../../helpers/custom-route-serializer'
import { at } from 'lodash-es'

export const routerStateFeatureKey = 'routerState'

interface RouteState {
	router: RouterReducerState<RouterStateUrl>
}

const reducers: ActionReducerMap<RouteState> = {
	router: routerReducer
}

const reducer: ActionReducer<RouteState> = combineReducers(reducers)

function routeReducer(state: any, action: any) {
	return reducer(state, action)
}

export { routeReducer, RouteState }

export const selectRouteFeature = createFeatureSelector<RouteState>(
	routerStateFeatureKey
)

export const selectRoute = (param?: string) =>
	createSelector(selectRouteFeature, (state: RouteState) => {
		if (param) {
			return at(state.router, param)[0]
		}
		return state.router
	})
