<ng-template #supplierModal let-modal>
  <div class="modal-header justify-content-center">
    <h1 class="modal-title" translate>Supplier Informations</h1>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'Close' | translate"
      (click)="modal.dismiss('Cross click')"
    >
      <span class="icon-close" aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div id="tree-root" class="supplier-modal-body">
      <ol>
        <ng-template #recursiveList let-list="list">
          <li
            *ngFor="let node of list; let i = index"
            [class.has-object]="node.type === 'object'"
            [class.has-array]="node.type === 'array'"
          >
            <strong>{{ node.title }}</strong>
            <span *ngIf="node.type === 'string'">: {{ node.name }}</span>
            <span *ngIf="node.type === 'date'">
							:
              {{
              node.name
                | formatDate: 'long':true:translateService.currentLang
                | async
              }}
						</span>
            <button
              *ngIf="node.nodes && node.nodes.length"
              (click)="node.isCollapsed = !node.isCollapsed"
              [attr.aria-expanded]="!node.isCollapsed"
            >
              <span *ngIf="node.isCollapsed">+</span>
              <span *ngIf="!node.isCollapsed">-</span>
            </button>
            <ol *ngIf="node.nodes" [ngbCollapse]="node.isCollapsed">
              <ng-container
                *ngTemplateOutlet="recursiveList; context: { list: node.nodes }"
              ></ng-container>
            </ol>
          </li>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="recursiveList; context: { list: supplierInfoTree }"
        ></ng-container>
      </ol>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <custom-button
      [id]="'supplier-modal-cancel'"
      (onClick)="modal.dismiss('Cross click')"
      css="outline secondary"
      [label]="'Cancel' | translate"
      ariaLabelled="supplierModal"
    ></custom-button>
  </div>
</ng-template>
<div [id]="item.id">
  <div class="row">
    <div *ngIf="item.product_snapshot?.product_image?.small" class="col-lg-1">
      <img class="product-image" [src]="item.product_snapshot?.product_image?.small">
    </div>
    <div [ngClass]="{'col-lg-6': item.product_snapshot?.product_image?.small, 'col-lg-7': !item.product_snapshot?.product_image?.small}" >
      <!-- basic info -->
      <div>
        <!-- when item is financial redemption -->
        <p *ngIf="isFinancialRedemption; else elseBlock">
          <strong *ngIf="getProductType(item,'name')">{{getProductType(item,'name') | getLangParam | async}}:</strong>
          <span>
						{{
            _.isString(productName)
              ? productName
              : (productName | getLangParam | async)
            }}
            <span *ngIf="sku">{{ sku }}</span>
					</span>
          <br />

          <!-- PO# -->
          <span *ngIf="po"><strong>PO#:</strong> {{ po }}</span>
        </p>

        <ng-template #elseBlock>
          <p>
            <strong *ngIf="item.product_snapshot?.product_type?.name">{{item.product_snapshot?.product_type?.name | getLangParam | async}}:</strong>
            <span [ngClass]="{'font-bold': !item.product_snapshot?.product_type?.name}">
						{{
              _.isString(productName)
                ? productName
                : (productName | getLangParam | async)
              }}
					</span>
            <br />
            <!-- sku -->
            <span *ngIf="sku">
						<strong>SKU#:</strong> {{ sku }}
              <br />
					</span>
          </p>
          <span
            *ngIf="
							( getProductType(item,'code') === 'financial_reward' ||
								getProductType(item,'code') === 'merchandise' ||
								getProductType(item,'code') === 'giftcard' ||
								getProductType(item,'code') === 'egiftcard') &&
							item.payload?.data?.serial_number
						"
          >
						<strong translate>Serial Number:</strong> {{ item.payload.data.serial_number }}
            <br />
					</span>
          <!-- PO# -->
          <span *ngIf="po">
						<strong>PO#:</strong> {{ po }}
            <br />
					</span>

          <!-- egift card email -->
          <span *ngIf="eGiftRecipientEmail">
						<strong translate>Email:</strong>
            {{ eGiftRecipientEmail }}
					</span>
        </ng-template>
      </div>
      <p class="mb-0" *ngIf="showTrackAndCarrierLabel">
        <strong translate>Carrier:</strong>
        {{
        item.carrier
          ? item.carrier
          : ('Not Available' | translate)
        }}
      </p>
      <p class="mb-0" *ngIf="showTrackAndCarrierLabel">
        <strong translate>Tracking: </strong>
        <a *ngIf="item.tracking_number && item.tracking_url" href="{{ item.tracking_url }}" target="_blank">
          {{ item.tracking_number }}
        </a>
        <span *ngIf="item.tracking_number && !item.tracking_url">{{ item.tracking_number }}</span>
        <span *ngIf="!item.tracking_number && !item.tracking_url">{{ 'Not Available' | translate }}</span>
      </p>
      <p class="mb-0" *ngIf="item.payload">
        <strong class="mr-2" translate>Supplier Information:</strong>
        <button class="btn-link"
              (click)="showSupplierInfo(item.payload)">
						<span
              *ngIf="item.payload"
              [attr.aria-label]="'show supplier informations' | translate"
              class="material-icons-outlined primary"
              aria-hidden="true"
            >
								info
						</span>
						<span translate>Info</span>
					</button>
      </p>
    </div>

    <div class="col-lg-5">
      <p class="mt-20 details-sub-col-show">Item Value</p>
      <p>
        <!-- item price -->
        <span>
					<strong translate>Item Price:</strong>
					<span class="float-right">
						{{ itemPrice | number: '1.0' }}
            {{ currency }}
					</span>
					<br />
				</span>

        <!-- saving -->
        <span *ngIf="savings">
					<strong translate>Savings:</strong>
					<span class="float-right">
						{{ '-' + savings | number: '1.0' }}
            {{ currency }}
					</span>
					<br />
				</span>

        <!-- promo codes -->
        <span *ngFor="let promo of promoArray">
					<strong>{{ 'Promo Code:' | translate }} {{ promo.promo_code }}</strong>
					<span class="float-right">
						{{ '-' + promo.value | number: '1.0' }}
            {{ currency }}
					</span>
					<br />
				</span>

        <!-- item total -->
        <strong class="mb-10 d-block">
					<span>
						<strong translate>Item Total:</strong>
						<span class="float-right">
							{{ itemFinalPrice | number: '1.0' }}
              {{ currency }}
						</span>
						<br />
					</span>
        </strong>

        <!-- external cash transaction -->
        <span *ngIf="item.external_cash_transaction">
					<strong translate>External cash transaction:</strong>
					<span class="float-right">
						{{ item.payload.cash_total }} {{ item.payload.cash_currency }}
					</span>
					<br />
				</span>
        <!-- Agent fee -->
        <span *ngFor="let fee of item.additional_fees; let i = index">
          <span class="row" *ngIf="fee.display_fee.show">
            <span class="col-8">
              <strong class="mr-1" translate>
                Agent Order Fee
              </strong>
              <strong>
               ({{fee.name}}):
              </strong>
            </span>
            <span class="col-4 fee-value">
<!--              <span>-->
              <!--                {{-->
              <!--                fee.amount-->
              <!--                  ? fee.fee_transaction?.currency?.name === 'Points'-->
              <!--                    ? (fee.converted_amount | number: '1.0') +-->
              <!--                    ' ' +-->
              <!--                    fee.fee_transaction?.currency?.name-->
              <!--                    : fee.fee_transaction?.currency?.symbol +-->
              <!--                    (fee.amount | number: '1.0') +-->
              <!--                    ' ' +-->
              <!--                    fee.fee_transaction?.currency?.code-->
              <!--                  : '-'-->
              <!--                }}-->
              <!--              </span>-->
              <span>{{fee.display_fee.symbol + (fee.display_fee.value | number: '1.0') + ' ' + fee.display_fee.currency }}</span>
            </span>
            <br />
          </span>
				</span>
      </p>
    </div>
    <div *ngIf="item.product_snapshot?.product_image?.small" class="col-lg-1"></div>
    <div class="mt-10" [ngClass]="{'col-lg-6': item.product_snapshot?.product_image?.small, 'col-lg-7': !item.product_snapshot?.product_image?.small}">
      <p class="mb-0" *ngIf="getProductType(item,'code') === 'financial_reward'">
        <strong translate>Cashed Status:</strong>
        {{
        item.payload?.data?.additional_status &&
        item.payload.data.additional_status === 'cashed'
          ? ('Yes' | translate)
          : ('No' | translate)
        }}
      </p>
      <p class="mb-0" *ngIf="getProductType(item,'code') === 'merchandise' &&
								item.payload?.data?.additional_status &&
								item.payload.data.additional_status === 'cashed'">
				<span>
					<strong translate>Cashed Status:</strong> <span translate>Yes</span>
				</span>
      </p>
      <p *ngIf="((getProductType(item,'code') === 'financial_reward' || getProductType(item,'code') === 'merchandise') && item.payload?.data?.additional_status === 'cashed' && item.payload?.data?.additional_status_updated_at)">
        <strong translate>Cashed Date:</strong>
        <span> {{item.payload?.data?.additional_status_updated_at | formatDate: 'long':false:translateService.currentLang| async}}</span>
      </p>
    </div>
  </div>

  <hr />

  <!-- status -->
  <div class="status-history">
    <div (click)="statusOnClick()">
      <div class="row" [id]="'latest-status-' + orderId">
        <div class="col-auto">
          <span><strong translate>Latest Status:</strong></span>
        </div>
        <div class="col">
					<span>
						{{ (statusArray$ | async)[0].display_name | getLangParam | async }} <span translate>on</span>
            {{
            (statusArray$ | async)[0].created_at
              | formatDate: 'long':true:translateService.currentLang
              | async
            }}
					</span>
          <span *ngIf="(statusArray$ | async)[0].name === 'Backordered'">
						{{
            item.estimated_shipping_date
              ? ', ' +
              ('estimated to ship ' | translate) +
              (item.estimated_shipping_date | formatDate: 'long' | async)
              : ''
            }}
					</span>
          <span *ngIf="(statusArray$ | async)[0].reason">
            <strong translate>Reason:</strong><span> {{(statusArray$ | async)[0].reason | getLangParam | async}}</span>
					</span>
          <button
            *ngIf="(statusArray$ | async).length > 1"
            class="expand-button float-right"
            [class]="{ active: !collapsed.status }"
            type="button"
            [attr.aria-expanded]="!collapsed.status"
            [attr.aria-labelledby]="
							'latest-status-' +
							orderId +
							(collapsed.status ? '' : ' status-history-' + orderId)
						"
          >
            <span class="icon-expand" aria-hidden="true"></span>
            <span class="sr-only" translate>Toggle</span>
          </button>
        </div>
      </div>
    </div>
    <ul
      #collapse="ngbCollapse"
      [(ngbCollapse)]="collapsed.status"
      class="pl-0"
      [id]="'status-history-' + orderId"
    >
      <li *ngFor="let status of statusArray$ | async; let i = index">
        <!-- <p>{{ getItemName(item) }}</p> -->
        <div class="row" *ngIf="i > 0">
          <div class="col-auto">
            <span aria-hidden="false" style="color: transparent"><strong translate>Latest Status:</strong></span>
          </div>
          <div class="col">
            {{ status.display_name | getLangParam | async }} <span translate>on</span>
            {{
            status.created_at
              | formatDate: 'long':true:translateService.currentLang
              | async
            }}

            <span *ngIf="status.name === 'Backordered'">
						{{
              item.estimated_shipping_date
                ? ', ' +
                ('estimated to ship ' | translate) +
                (item.estimated_shipping_date
                  | formatDate: 'long':false:translateService.currentLang
                  | async)
                : ''
              }}
					</span>
          <span *ngIf="status.reason">
            <strong translate>Reason:</strong> <span> {{status.reason | getLangParam | async}}</span>
          </span>
          </div>
        </div>
      </li>
    </ul>
    <hr />
  </div>

  <!-- notes -->
  <div *ngIf="canItemNotes$ | async" class="item-notes">
    <p (click)="collapsed.notes = !collapsed.notes">
			<span [id]="'item-notes'">
				<strong translate>Notes</strong>
			</span>
      <span
        [id]="'order-item-notes-length-' + item.id"
        class="pill-tag ml-15"
      >
            <span class="sr-only" translate>Number of notes:</span>
            <span>{{ (notesArray | async).length }}</span>
        </span>
      <button
        class="expand-button float-right"
        [class]="{ active: !collapsed.notes }"
        type="button"
        [attr.aria-expanded]="!collapsed.notes"
        [attr.aria-labelledby]="'item-notes' + ' ' + 'order-item-notes-length-'+ item.id"
      >
        <span class="icon-expand" aria-hidden="true"></span>
        <span class="sr-only" translate>Order Item Notes Toggle</span>
      </button>
    </p>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed.notes" class="pl-0">
      <div class="mb-15" *ngIf="(notesArray | async).length">
        <div class="mt-15"  *ngFor="let note of notesArray | async; let i = index">
          <div>
            <strong class="mr-2" translate>Date:</strong>
            <pre class="mb-0">{{
              note.created_at
                | formatDate: 'long':true:translateService.currentLang
                | async
              }}</pre>
          </div>
          <div>
            <strong class="mr-2" translate>Note:</strong>
            <pre class="mb-0">{{note.note}}</pre>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="openNoteForm">
      <form
        *ngIf="(canItemNotes$ | async) === 2"
        [formGroup]="noteForm"
        autocomplete="off"
        (ngSubmit)="onSubmitNotes()"
      >
        <fieldset>
          <legend class="sr-only" translate>Item Notes</legend>
          <div class="input-container mb-20">
							<textarea
                [id]="item.id + '-note'"
                rows="4"
                [maxlength]="maxNoteLength"
                [placeholder]="'Enter Notes' | translate"
                class="form-control dark"
                formControlName="note"
                aria-describedby="Note-error"
                [attr.aria-invalid]="
									appService.isFieldValid(noteForm.get('note'))
								"
              ></textarea>
            <div>
              {{
              (noteForm.get('note')?.value?.length || 0) + '/' + maxNoteLength
              }}
            </div>
            <error-field
              inputName="Note"
              [error]="noteForm.get('note')"
            ></error-field>
          </div>
          <div class="clearfix mt-20 d-flex justify-content-end">
            <custom-button
              (onClick)="openNoteForm = false"
              css="outline create mr-20 outline primary"
              [ladda]="arrayNotesLadda"
              [label]="'Cancel' | translate"
            ></custom-button>
            <custom-button
              type="submit"
              css="create full primary"
              [disabled]="!noteForm.valid"
              [ladda]="arrayNotesLadda"
              [label]="'Insert Note' | translate"
            ></custom-button>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="clearfix mt-30 mb-20" *ngIf="!openNoteForm">
      <custom-button
        type="button"
        css="outline create"
        (onClick)="setFocus()"
        [label]="' + Add Note' | translate"
      ></custom-button>
      <!-- [ladda]="notesLadda$" -->
    </div>

  </div>
</div>
