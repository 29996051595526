import {
	Component,
	EventEmitter,
	OnInit,
	Input,
	Output,
	ElementRef,
	ViewChildren,
	QueryList,
	AfterViewInit
} from '@angular/core'
import { cleanSuccess, cleanError } from '../../store/actions/app.actions'
import { selectAlerts } from '../../store/reducers/layout.reducer'
import { AppState } from '../../store/reducers'
import { Observable, Subject } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators'
import { forOwn, isObject } from 'lodash-es'

@Component({
	selector: 'alert-component',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, AfterViewInit {
	@Input() css: string
	@Output() onClose = new EventEmitter()
	private unsubscribe$ = new Subject<void>()
	success$: Observable<any>
	@ViewChildren('closeButtons', { read: ElementRef })
	closeButtons: QueryList<ElementRef>
	alerts: any[]
	isObject = isObject
	constructor(private store$: Store<AppState>) {}

	ngOnInit() {
		this.success$ = this.store$.pipe(
			select(selectAlerts()),
			takeUntil(this.unsubscribe$),
			distinctUntilChanged((prev, current) => {
				if (prev && current) {
					return (
						prev.success === current.success &&
						prev.error === current.error &&
						prev.warning === current.warning
					)
				}
				return false
			}),
			tap((res) => {
				this.alerts = []
				forOwn(res, (group, type) => {
					forOwn(group, (val, key) => {
						// when object has showClose, use that, otherwise, set showClose to false if type is 'error'
						if (val) {
							this.alerts.push({
								type: type === 'error' ? 'danger' : type,
								title: val?.text ? val?.text : val,
								description: val?.description ? val?.description : null,
								html: val?.html ? val?.html : null,
								showClose:
									val?.showClose !== undefined
										? val.showClose
										: true,
								key
							})
						}
					})
				})
			})
		)
	}

	onClosed(alert) {
		if (alert.type === 'success') {
			this.store$.dispatch(cleanSuccess({ key: alert.key }))
		} else if (alert.type === 'danger') {
			this.store$.dispatch(cleanError({ key: alert.key }))
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next()
		this.unsubscribe$.complete()
	}

	ngAfterViewInit(): void {
		this.closeButtons.changes.subscribe(() => {
			if (this.closeButtons.length) {
				window.scroll(0, 0)
				this.closeButtons.get(0).nativeElement.focus()
			}
		})
	}
}
