import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './helpers/auth.guard'
import { AppGuard } from './helpers/app.guard'
import { SSOComponent } from './sso/sso.component'

const routes: Routes = [
	{
		path: 'error',
		component: SSOComponent
	},
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: '',
		loadChildren: () =>
			import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'sso/:token',
		component: SSOComponent
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		canActivate: [AppGuard]
	},
	{
		path: '**',
		redirectTo: 'home'
	}
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: 'legacy'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
