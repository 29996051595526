import { Component, OnInit} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Observable } from 'rxjs'
import {
	customValidators,
	MustMatch,
	ValidateAllFormFields
} from '../../helpers/helper.functions'
import { AppService } from '../../app.service'
import { AuthService } from '../auth.service'
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {appError} from "../../store/actions/app.actions";
import {AppState} from "../../store/reducers";


@Component({
	selector: 'auth-new-password',
	templateUrl: './new-password.component.html',
	styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
	newPassForm: FormGroup<any>
	ladda$: Observable<boolean>
	step: number = 0
	tooltipText = 'pw-tooltip'
	userId: number
	user: Observable<any>
	constructor(
		private formBuilder: FormBuilder,
		public appService: AppService,
		public auth: AuthService,
		private route: ActivatedRoute,
		private store: Store<AppState>,
		private router: Router,
	) {
		window.onbeforeunload = () => {
			localStorage.clear()
		}
	}

	ngOnInit(): void {
		let mod = JSON.parse(this.appService.getFromLocal('modules'))
		if(mod){
			this.userId = mod.id
		}else{
			// redirect if we lost token
			this.router.navigateByUrl('/');
		}
		this.newPassForm = this.formBuilder.group({
				new_password: ['', [Validators.required, customValidators.password]],
				new_password_confirmation: ['', [Validators.required, customValidators.password]]

			},
			{ validator: MustMatch('new_password', 'new_password_confirmation') }
		)
	}


	onSubmit() {
		if (!this.newPassForm.valid) {
			ValidateAllFormFields(this.newPassForm)
			return
		}

		let  payload = {...this.newPassForm.value}
		payload['id'] = this.userId;

		this.auth.updatePassword(payload).subscribe(res=>{
			this.router.navigate(['/'],{ queryParams: {pw_reset:true} }).then( r =>{})
			},
			(err)=>{
				this.store.dispatch(appError({
					error:{
						reset_pw: err
					},
					loadingKey:'loginLoading'
				}))
			}
		)
	}

}
