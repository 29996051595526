import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
	selectUserDateFormat,
	UserState
} from '../../store/reducers/user.reducer'
import { filter, map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import moment from 'moment'

@Pipe({
	name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
	constructor(private store$: Store<UserState>) {}

	transform(
		value: Date,
		format: string,
		showTime: boolean = false,
		lang = localStorage.getItem('lang'),
		showGMT: boolean = false
	): Observable<string> {
		return this.store$.pipe(
			select(selectUserDateFormat(format, showTime)),
			filter((theFormat) => !!theFormat),
			map((theFormat) => {
				if(showGMT){
					return moment(value).tz('GMT').locale(lang).format(theFormat)
				}
				return moment(value).locale(lang).format(theFormat)
			})
		)
	}
}
