<alert-component></alert-component>
<img
		class="mb-20 no-max"
		src="/assets/images/podium_loyalty_logo_white.svg"
		[alt]="'Podium brand logo' | translate"
/>


<div *ngIf="step === 0">
	<h1 translate>Your password has expired</h1>
	<h2 class="mb-30" *ngIf="step === 0" translate>
		Please update your password.
	</h2>
	<a *ngIf="step === 0" role="button" style="color:white" href="javascript:void(0)" (click)="step = 1"><u>Update your password</u></a>

</div>
<form
	*ngIf="step == 1"
	class="form-signin"
	[formGroup]="newPassForm"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
>

	<h1 class="h2 mb-30" translate>
		Please enter a new password for your account.
	</h1>


	<custom-form-input
		class="login-input"
		formControlName="new_password"
		type="password"
		[id]="'inputPassword'"
		[placeholder]="'Enter new password' | translate"
		[label]="'Enter new password' | translate"
		[showLabel]="false"
		css="form-control tooltip-first dark"
		[tooltip]="tooltipText"
	></custom-form-input>

	<custom-form-input
		class="login-input"
		formControlName="new_password_confirmation"
		type="password"
		[id]="'inputRePassword'"
		[placeholder]="'Confirm new password' | translate"
		[label]="'Confirm new password' | translate"
		[showLabel]="false"
		css="form-control dark mt-20"
	></custom-form-input>

	<custom-button
		type="submit"
		css="outline secondary large mb-30 mt-20"
		[ladda]="ladda$"
		[label]="'Submit' | translate"
	></custom-button>
</form>
