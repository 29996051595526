import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { Store } from '@ngrx/store'
import { values } from 'lodash-es'
import { AppState } from '../store/reducers'
import { Injectable } from '@angular/core'
import { appWarning, logoutSuccess } from '../store/actions/app.actions'
import { AppService } from '../app.service'
import { map, tap, catchError } from 'rxjs/operators'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import {authError} from "../auth/store/auth.actions";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<AppState>,
		private appService: AppService,
		private route: Router
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(
				(error: HttpErrorResponse): Observable<any> => {
					if (
						!(error.error instanceof ErrorEvent) &&
						error.status === 401 &&
						!request.url.includes('login') &&
						!this.route.url.includes('sso')
					) {
						return of(this.store.dispatch(logoutSuccess())).pipe(
							map(() => {
								// this.store.dispatch(
								// 	appWarning({
								// 		warning: {
								// 			logout: {
								// 				text: _('Session Expired'),
								// 				description: _(
								// 					'Your session expired due to inactivity. Please log in again to resume your session.'
								// 				),
								// 				showClose: false
								// 			}
								// 		}
								// 	})
								// )
							}),
							tap(() => {
								this.appService.clear(null,true)
							})
						)
					}

					if(error.status === 403 && error.error.code === 'locked' ){
						this.store.dispatch(authError({error:'', loadingKey:'passwordExpired'}))

					}

					let finalError
					if (error.status === 500) {
						finalError = _('Internal Server Error')
					} else  {
						if (error?.error?.message) {
							finalError = error.error.message
						} else if (error?.error?.detail) {
							finalError = values(error.error.detail).join('\n')
						} else {
							finalError = error?.message
						}
					}

					return throwError(finalError)
				}
			)
		)
	}
}
