import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import {
	appError,
	appSuccess,
	appWarning,
	changeBodyClass,
	changeCurrent,
	cleanError,
	cleanSuccess,
	cleanAlerts,
	loading,
	loadModulesSuccess,
	resolveLoading,
	changeLang,
	updateBreadcrumb,
	changeTitle,
	clearLoading,
	appModalAlert
} from '../actions/app.actions'
import {
	map,
	forEach,
	initial,
	isNumber,
	last,
	at,
	set,
	omit,
	reduce,
	find,
	reverse,
	merge,
	isEmpty,
	isArray,
	extend,
	transform,
	cloneDeep
} from 'lodash-es'
import {
	geRouteByModuleName, reports_mapping,
	toTitleCase
} from '../../helpers/helper.functions'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

export const layoutStateFeatureKey = 'layoutState'

export interface LayoutState {
	bodyClass: string
	pageTitle: string
	showAction?: boolean,
	param: string,
	breadCrumb: any[]
	modules: any
	permissions: any
	system_permissions: any
	current: any
	loading: any
	error: any
	success: any
	warning: any
	modalAlert: any
	lang: string
}

const initialLayoutState: LayoutState = {
	bodyClass: undefined,
	breadCrumb: [],
	modules: undefined,
	permissions: undefined,
	system_permissions: undefined,
	pageTitle: '',
	showAction: false,
	param: 'id',
	current: {
		partner: undefined,
		customer: undefined,
		program: undefined
	},
	loading: {},
	error: {},
	warning: {},
	success: {},
	modalAlert: {},
	lang: localStorage.getItem('lang') || undefined
}

export const layoutReducers = createReducer(
	initialLayoutState,
	on(changeBodyClass, (state, action) => {
		return {
			...state,
			bodyClass: action.class
		}
	}),
	on(changeLang, (state, action) => {
		return {
			...state,
			lang: action.lang
		}
	}),
	on(changeTitle, (state, action) => {
		return {
			...state,
			pageTitle: action.title,
			showAction: action.showAction,
			param: action.param
		}
	}),
	on(appError, (state, action) => {
		return {
			...state,
			success: {},
			warning: {},
			alert: {},
			error: {
				...state.error,
				...action.error
			},
			loading: omit(state.loading, action.loadingKey)
		}
	}),
	on(appSuccess, (state, action) => {
		return {
			...state,
			success: action.success,
			warning: {},
			alert: {},
			error: {},
			loading: omit(state.loading, action.loadingKey)
		}
	}),
	on(appWarning, (state, action) => {
		return {
			...state,
			success: {},
			warning: {
				...state.warning,
				...action.warning
			},
			alert: {},
			error: {},
			loading: omit(state.loading, action.loadingKey)
		}
	}),
	on(cleanError, (state, action) => {
		return {
			...state,
			error: action.key ? omit(state.error, action.key) : {}
		}
	}),
	on(cleanSuccess, (state, action) => {
		return {
			...state,
			success: action.key ? omit(state.success, action.key) : {}
		}
	}),
	on(cleanAlerts, (state) => {
		return {
			...state,
			success: {},
			warning: {},
			error: {}
		}
	}),
	on(appModalAlert, (state, action) => {
		return {
			...state,
			modalAlert: {
				...state.modalAlert,
				...action.modalAlert
			},
		}
	}),
	on(changeCurrent, (state, action) => {
		const current = { ...state.current, [action.source]: action.value }

		if (!action.fromLocal) {
			if (action.source === 'partner') {
				current.customer = null
				current.program = null
			} else if (action.source === 'customer') {
				current.program = null
			}
		}
		return {
			...state,
			current: {
				...current
			}
		}
	}),
	on(resolveLoading, (state, action) => {
		return {
			...state,
			loading: omit(state.loading, [action.loadingKey])
		}
	}),
	on(clearLoading, (state, action) => {
		return {
			...state,
			loading: {}
		}
	}),
	on(loadModulesSuccess, (state, action) => {
		function searchingForNavParents(currentNav, history = []) {
			let currentRoute = geRouteByModuleName(currentNav.name['en-CA'])
			let storeNavigation: any = {
				apiNames: currentNav.name,
				permission: currentNav.pivot.type,
				placeholders: currentNav.placeholders
			}
			if (!isEmpty(currentRoute)) {
				extend(storeNavigation, currentRoute)
			}
			if (storeNavigation.name) {
				history.push(storeNavigation)
			}
			const currentParent: any = action.system_permissions.find((permission) => {
				return permission.name['en-CA'] === currentNav.group_name['en-CA']
			})
			if (!isEmpty(currentParent)) {
				return searchingForNavParents(currentParent, history)
			} else {
				currentRoute = geRouteByModuleName(currentNav.group_name['en-CA'])
				storeNavigation = {
					apiNames: currentNav.group_name,
					permission: currentNav.pivot.type
				}

				if (!isEmpty(currentRoute)) {
					extend(storeNavigation, currentRoute)
				}
				if (storeNavigation.name) {
					history.push(storeNavigation)
				}

				return history
			}
		}

		const nav = {}
		const systemPermissions = {}
		forEach(action.system_permissions, function (role: any) {
			const tree = reverse(searchingForNavParents(role))
			// Add also system permissions to the state since it is useful for report permissions
			const permission_name = role.name['en-CA'].replace(/\s|\//g, '_').toLowerCase()
			if(reports_mapping[permission_name]){
				systemPermissions[reports_mapping[permission_name].permission]= role.pivot.type
			} else {
				systemPermissions[permission_name]= role.pivot.type
			}

			function populatingNav(index = 0) {
				if (!index) {
					if (!nav[tree[0].name]) {
						nav[tree[0].name] = tree[index]
					}
					if (tree[index + 1]) {
						populatingNav(index + 1)
					}
				} else {
					let str = ''
					const historyLength = Array(index)
					forEach(historyLength, (val, ai) => {
						str +=
							tree[ai].name +
							(ai < historyLength.length - 1 ? '.children.' : '')
					})
					if (!at(nav, `${str}.children.${tree[index].name}`)[0]) {
						set(nav, `${str}.children.${tree[index].name}`, tree[index])
					}
					if (tree[index + 1]) {
						populatingNav(index + 1)
					}
				}
			}

			populatingNav()
		})
		localStorage.setItem('modules', JSON.stringify(nav))
		return {
			...state,
			modules: nav,
			system_permissions: systemPermissions,
			isInitializing: false
		}
	}),
	on(loading, (state, action) => {
		return {
			...state,
			loading: { ...state.loading, [action.loadingKey]: true }
		}
	}),
	on(updateBreadcrumb, (state, action) => {
		let urls: any[] = action.route.replace('/list', '').split('/')
		urls.shift()
		urls = map(urls, (url, index) => {
			const item: any = {}
			// Don't check query string parameters
			url = url.split('?')[0]
			if (!isNaN(url) || url === _('new')) {
				// Try to get the value from the data object(resolve) else with the static value
				url = at(action.data, action.data.breadcrumb[index])[0] || action.data.breadcrumb[index]
				item.dynamic = true
			}
			item.url = urls.slice(0, index + 1).join('/')

			if (!item.dynamic) {
				let display_label = ''
				// if i add the breadcrumb object for that route set the label to that value

				if (action.data?.breadcrumb && isArray(action.data?.breadcrumb)) {
					display_label = action.data?.breadcrumb[index]
				} else {
					display_label = url
				}
				// Otherwise use the url path to populate the breadcrumb
				if (typeof display_label === 'string' && !item.dynamic) {
					item.label = toTitleCase(display_label.replace(/_|-/g, ' '))
				} else {
					item.label = display_label
				}
			} else {
				item.label = url
				// If breadcrumb is array handle it ['string','string']
				if(isArray(action.data?.breadcrumb[index])){
					const display_label = []
					action.data?.breadcrumb[index].forEach((i)=>{
						const r = at(action.data,i)[0] ? at(action.data,i)[0] : i
						display_label.push(r)
					})
					item.label = display_label
				}
			}
			return item
		})

		// handling the exception for member details page
		// "Members / Member's Name / Details" -> "Members / Member's Name"
		if (
			urls.length &&
			urls[0]?.label === 'Members' &&
			urls[2]?.label === 'Details'
		) {
			// removed the last element from the urls
			urls.pop()
		}

		return {
			...state,
			breadCrumb: urls
		}
	})
)

export const selectLayoutFeature = createFeatureSelector<LayoutState>(
	layoutStateFeatureKey
)

export const selectBodyClass = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => state.bodyClass
)

export const selectLoading = (key) =>
	createSelector(selectLayoutFeature, (state: LayoutState) => {
		return state.loading[key]
	})

export const selectError = (key?: string) =>
	createSelector(selectLayoutFeature, (state: LayoutState) =>
		key ? state.error[key] : state.error
	)

export const selectSuccess = (key?: string) =>
	createSelector(selectLayoutFeature, (state: LayoutState) =>
		key ? state.success[key] : state.success
	)

export const selectAlerts = () =>
	createSelector(selectLayoutFeature, (state: LayoutState) => {
		return isEmpty(state.warning) &&
			isEmpty(state.error) &&
			isEmpty(state.success)
			? null
			: {
					success: state.success,
					warning: state.warning,
					error: state.error
			}
	})

export const selectPageTitle = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => ({ title:state.pageTitle, showAction: state.showAction, param: state.param})
)

export const selectModules = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => state.modules
)

export const selectSystemPermissions = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => state.system_permissions
)

export const selectLang = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => state.lang
)

export const selectPermission = (path) =>
	createSelector(selectLayoutFeature, (state: LayoutState) => {
		return at(state.modules, path)[0]?.permission
	})

export const selectCurrent = (source?: string) =>
	createSelector(selectLayoutFeature, (state: LayoutState) => {
		return source ? state.current[source] : state.current
	})

export const selectBreadcrumb = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => {
		if(!state.breadCrumb){
			return
		}
		return state.breadCrumb.filter((breadcrumb) => !!breadcrumb.label)
	}
)

export const selectModalAlert = createSelector(
	selectLayoutFeature,
	(state: LayoutState) => state.modalAlert
)
