import { createAction, props } from '@ngrx/store'

export const login = createAction(
	'[Auth] Login',
	props<{ email: string; password: string }>()
)

export const authError = createAction(
	'[App] Auth Error',
	props<{ error: string; loadingKey?: string }>()
)

export const authLoading = createAction(
	'[Auth] Loading',
	props<{ loadingKey: string }>()
)

export const forgotPassword = createAction(
	'[Auth] Forgot Password',
	props<{ email: string }>()
)
