<form
	class="form-signin"
	[formGroup]="forgotForm"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
>
	<img
		class="mb-20 no-max"
		src="/assets/images/podium_loyalty_logo_white.svg"
		[alt]="'Podium brand logo' | translate"
	/>
	<h1 class="h3 mb-20" translate>
		Forgot your password?
		<br />
		Enter your email address to reset your password.
	</h1>
	<alert-component></alert-component>

	<custom-form-input
		class="forgot-password-input"
		formControlName="email"
		type="email"
		[id]="'inputEmail'"
		[placeholder]="'Email Address' | translate"
		css="form-control dark"
		[label]="'Email Address' | translate"
		[showLabel]="false"
	></custom-form-input>

	<custom-button
		type="submit"
		[ladda]="ladda$"
		css="outline dark large mb-20 mt-20 d-block mx-auto"
		[label]="'Submit' | translate"
	></custom-button>
	<br />
	<a
		class="light underline"
		translate
		[routerLink]="'/auth/login' | translateLink"
	>
		Return to login screen
	</a>
</form>
