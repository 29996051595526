import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../environments/environment'
import { AbstractControl, FormControl } from '@angular/forms'
import { isString, isArray, forOwn, omit } from 'lodash-es'
import { Store } from '@ngrx/store'
import { AppState } from './store/reducers'
import { Router } from '@angular/router'
import { clearStore } from './store/actions/app.actions'
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Injectable({
	providedIn: 'root'
})
export class AppService {

	private baseUrl: string = `${environment.apiUrl}`
	private _current: any
	private _editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '0',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '2',
		sanitize: false,
		toolbarHiddenButtons: [
			[
				'heading',
			],
			[
				// 'insertImage',
				'insertVideo',
			]
		]
	};

	constructor(
		private http: HttpClient,
		private store$: Store<AppState>,
		private router: Router
	) {}

	clear(returnUrlParam?: string, showMessage:boolean = false) {
		this.clearLocal()
		// let returnUrl
		// if (
		// 	returnUrlParam &&
		// 	!returnUrlParam.split('/').find((param: any) => !isNaN(param))
		// ) {
		// 	returnUrl = returnUrlParam
		// } else {
		// 	returnUrl = '/'
		// }
		//
		// let query_params = {returnUrl: returnUrl}
		let query_params = {}
		if(showMessage){
			query_params['session_expired'] = true
		}
		this.router
			.navigate(['auth/login'], { queryParams: query_params })
			.then((success) => {
				if (success) {
					// window.location.reload()
					this.current = {}
					this.store$.dispatch(clearStore()) // IT REMOVES EVERY ERROR INSTANTLY
				}
			})
	}

	logout() {
		return this.http.post(`${this.baseUrl}/auth/logout`, null)
	}

	set current(current) {
		this._current = current
	}

	get current() {
		return this._current
	}

	get angularEditorConfig(): AngularEditorConfig {
		return this._editorConfig;
	}

	saveToLocal(key: string, data: any) {
		if (isString(data)) {
			localStorage.setItem(key, data)
		} else {
			localStorage.setItem(key, JSON.stringify(data))
		}
	}

	removeFromLocal(key: string) {
		localStorage.removeItem(key)
	}

	clearLocal() {
		const lang = localStorage.getItem('lang')
		localStorage.clear()
		localStorage.setItem('lang', lang)
	}

	getFromLocal(key) {
		return localStorage.getItem(key)
	}

	isFieldValid(field: FormControl | AbstractControl) {
		return (!field.valid && field.touched) || field.untouched
	}

	getAllCustomers(pagination) {
		let params: any = {
			count: pagination.pageSize,
			page: pagination.activeIndex
		}

		if (pagination.sortBy.column) {
			params.sort_field = pagination.sortBy.column
			params.sort_direction = pagination.sortBy.dir
			params.sorting = JSON.stringify({
				[params.sort_field]: params.sort_direction
			})
		}

		return this.http.get(`${this.baseUrl}/customer`, { params })
	}

	getSources(source: any, params?: any) {
		let payload: HttpParams = new HttpParams()

		forOwn(omit(params, 'fromApi'), (val, key) => {
			if (isArray(val)) {
				val.forEach((item) => {
					payload = payload.append(`${key}[]`, item)
				})
			} else {
				payload = payload.append(key, val)
			}
		})

		if (isArray(source)) {
			return this.http.get(`${this.baseUrl}/${source.join('/')}`, {
				params: payload
			})
		}
		return this.http.get(`${this.baseUrl}/${source}`, { params: payload })
	}

	getModules() {
		return this.http.get(`${this.baseUrl}/admin/user/modulePermission`)
	}

	saveProfile(payload: any) {
		if (payload.id) {
			return this.http.put(`${this.baseUrl}/profile/${payload.id}`, payload)
		} else {
			return this.http.post(`${this.baseUrl}`, payload)
		}
	}

	loginWithToken({ token, programId }) {
		return this.http.post(
			`${this.baseUrl}/admin/program/${programId}/login-with-token`,
			null,
			{
				params: {
					token
				}
			}
		)
	}
}
