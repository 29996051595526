import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { loadCollectionSuccess } from '../actions/country.actions'

export const countryStateFeatureKey = 'countryState'

export interface Country {
	alpha_2_code: string
	alpha_3_code: string
	ascii_name: string
	full_name: string
	numeric_code: string
}

export interface CountryState extends EntityState<Country> {
	search: any
}

export const countryAdapter: EntityAdapter<Country> = createEntityAdapter<Country>(
	{
		selectId: (country: Country) => country.numeric_code
	}
)

export const countryInitialState = countryAdapter.getInitialState({
	search: {}
})

export const countryReducers = createReducer(
	countryInitialState,
	on(loadCollectionSuccess, (state, action) => {
		return countryAdapter.upsertMany(action.data, {
			...state,
			search: {
				...state.search,
				from: action.from || 'api',
				total: action.total
			}
		})
	})
)

export const selectCountryFeature = createFeatureSelector<CountryState>(
	countryStateFeatureKey
)

export const selectAllCountries = createSelector(
	selectCountryFeature,
	countryAdapter.getSelectors().selectAll
)
