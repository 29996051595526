import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { of } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable()
export class AuthService {
	private baseUrl: string = `${environment.apiUrl}`

	constructor(private http: HttpClient) {}

	forgotPassword(email) {
		return this.http.post(`${this.baseUrl}/password/email`, { ...email, version: 2 })
	}

	login({ user_account, password }) {
		return this.http.post(`${this.baseUrl}/auth/login`, {
			user_account,
			password,
			type: 'system'
		})
	}

	updatePassword(payload){
		const id = payload.id;
		delete payload.id;
		return this.http.put(`${this.baseUrl}/profile/${id}`, payload)
	}

	newPasswordLegacy(payload){
		return this.http.post(`${this.baseUrl}/password/reset`, payload)
	}

}
