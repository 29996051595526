<div class="modal-header justify-content-center">
	<h1 class='modal-title' id='modal-confirm-title' [innerHTML]="title"></h1>
	<button
		type="button"
		class="close"
		[attr.aria-label]="'Close' | translate"
		(click)="modal.dismiss('Cross click')"
	>
		<span class="icon-close" aria-hidden="true"></span>
	</button>
</div>
<div class="modal-footer justify-content-center">
	<custom-button
		type="submit"
		*ngIf="showCancel"
		(onClick)="modal.dismiss('Cross click')"
		css="outline primary"
		[label]="'Cancel' | translate"
	></custom-button>
	<custom-button
		*ngIf="showConfirm"
		(onClick)="modal.close()"
		css="full primary large ml-20"
		[label]="'Confirm' | translate"
	></custom-button>
</div>
