import { Directive } from '@angular/core'
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms'

@Directive({
	selector: '[requireFile]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: FileValidator, multi: true }
	]
})
export class FileValidator implements Validator {
	static validate(rules) {
		return (c: FormControl) => {
			let file = c.value

			if ((file == null || !file.length) && rules.required) {
				return { required: true }
			} else if (file && file.length) {
				if (rules.types) {
					const extension = (file[0].name || file).split('.')[1].toLowerCase()
					if (rules.types.indexOf(extension.toLowerCase()) < 0) {
						return {
							requiredFileType: true
						}
					}
				}
			}

			return null
		}
	}

	validate(c: FormControl): { [key: string]: any } {
		return FileValidator.validate(c)
	}
}
