import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromAppActions from './actions/app.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { AppService } from '../app.service'
import {
	map,
	mergeMap,
	tap,
	exhaustMap,
	catchError,
	withLatestFrom,
	concatMap,
	repeat
} from 'rxjs/operators'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import * as _ from 'lodash-es'
import { selectBreadcrumb } from './reducers/layout.reducer'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { AppState } from './reducers'
import { loadPartners, searchSources, updateList } from './actions/app.actions'
import { selectPagination } from './reducers/order.reducer'
import {
	addNoteSuccess,
	addNoteToOrder,
	loadOrder,
	loadOrders,
	loadOrdersSuccess,
	loadOrderSuccess,
	updateOrder,
	submitAction,
	submitActionSuccess,
	updateOrderAddress,
	updateOrderAddressSuccess,
	toggleLoading,
	addOrderItemNoteToOrder,
	addOrderItemNoteSuccess
} from './actions/order.actions'
import { OrdersService } from '../dashboard/orders/orders.service'
import { selectUser } from './reducers/user.reducer'
import { updateMemberSuccess } from '../dashboard/members/store/member.actions'
import { TranslatedLinks } from '../helpers/pipes/translated-links.pipe'
import { TranslateService } from '@ngx-translate/core'
import { marker as tr } from '@biesbjerg/ngx-translate-extract-marker'
import * as partnerActions from './actions/partner.actions'
import * as configActions from './actions/program-configuration-type.actions'
import * as countryActions from './actions/country.actions'
import { loadConfigurationTypes } from './actions/program-configuration-type.actions'
import { MembersService } from '../dashboard/members/members.service'

@Injectable()
export class AppEffects {
	navigationEnd$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED),
			mergeMap((action: any) => {
				let currentRoute = action.payload.routerState
				let actions: any[] = [fromAppActions.clearLoading()]
				if (currentRoute.url.includes('auth') && !currentRoute.url.includes('authorized_redeemers')) {
					actions.push(fromAppActions.changeBodyClass({ class: 'auth' }))
				} else {
					actions.push(fromAppActions.changeBodyClass({ class: '' }))
					actions.push(
						fromAppActions.updateBreadcrumb({
							route: currentRoute.url,
							data: currentRoute.data
						})
					)
				}
				return actions
			})
		)
	)

	updateOrderAddress$ = createEffect(() => {
		let payload: any = {}
		return this.actions$.pipe(
			ofType(updateOrderAddress),
			exhaustMap(({ orderId, memberId, address }) => {
				payload.orderId = orderId
				payload.address = address
				return this.membersService.addAddress(memberId, address, orderId).pipe(
					// after updating address, have to call loadOrder again, to show the updated shipping_address
					// TODO: when api returns the updated info on the PUT/POST call in the future, this tap can be removed
					tap(() => {
						return this.store$.dispatch(loadOrder({ orderId: payload.orderId }))
					})
				)
			}),
			mergeMap(() => {
				let actions: any[] = [
					fromAppActions.appSuccess({
						success: {
							update_address: tr('Successfully updated address')
						},
						loadingKey: 'update_address'
					}),
					updateOrderAddressSuccess({
						orderId: payload.orderId,
						changes: payload.address
					})
				]
				return actions
			}),
			catchError((error) => {
				return of(
					fromAppActions.appError({
						error: {
							update_address: error
						},
						loadingKey: 'update_address'
					})
				)
			})
		)
	})

	updateOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(updateOrder),
			exhaustMap((action: any) => {
				return this.orderService.updateOrder(action.payload).pipe(
					mergeMap((res) => {
						let actions: any[] = [
							fromAppActions.appSuccess({
								success: {
									update_order: tr('Success! Order orders has been updated.')
								},
								loadingKey: 'orderLoading'
							})
						]

						actions.push(
							updateMemberSuccess({
								id: action.payload.id,
								changes: _.omit(action.payload, 'id')
							})
						)
						return actions
					}),
					catchError((error) => {
						return of(
							fromAppActions.appError({
								error: { update_order: error },
								loadingKey: 'orderLoading'
							})
						)
					})
				)
			})
		)
	})

	addNoteToOrder$ = createEffect(() => {
		let note
		let orderId
		return this.actions$.pipe(
			ofType(addNoteToOrder),
			withLatestFrom(this.store$.select(selectUser)),
			exhaustMap(([action, user]) => {
				orderId = action.orderId

				note = {
					created_at: new Date(),
					first_name: user.first_name,
					last_name: user.last_name,
					note: action.note
				}

				return this.orderService
					.saveNote(orderId, {
						order_id: orderId,
						notes: [note]
					})
					.pipe(
						mergeMap((res: any) => {
							note.id = res.id
							return [
								addNoteSuccess({ orderId, note }),
								fromAppActions.appSuccess({
									success: { save_note: tr('Success! Note has been added.') },
									loadingKey: 'notesLadda'
								})
							]
						}),
						catchError((error) => {
							return of(
								fromAppActions.appError({
									error: { save_note: error.error, loadingKey: 'notesLadda' }
								})
							)
						})
					)
			})
		)
	})

	addOrderItemNoteToOrder$ = createEffect(() => {
		let note
		let orderId
		let orderItemId
		return this.actions$.pipe(
			ofType(addOrderItemNoteToOrder),
			withLatestFrom(this.store$.select(selectUser)),
			exhaustMap(([action, user]) => {
				orderId = action.orderId
				orderItemId = action.orderItemId
				note = {
					created_at: new Date(),
					first_name: user.first_name,
					last_name: user.last_name,
					note: action.note
				}

				return this.orderService
					.saveOrderItemNote(orderId, orderItemId, {
						order_id: orderId,
						notes: [note]
					})
					.pipe(
						mergeMap((res: any) => {
							note.id = res.id
							return [
								addOrderItemNoteSuccess({ orderId, orderItemId, note }),
								fromAppActions.appSuccess({
									success: {
										save_item_note: tr('Success! Note has been added.')
									},
									loadingKey: 'arrayNotesLadda'
								})
							]
						}),
						catchError((error) => {
							return of(
								fromAppActions.appError({
									error: {
										save_item_note: error.error,
										loadingKey: 'arrayNotesLadda'
									}
								})
							)
						})
					)
			})
		)
	})

	submitAction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(submitAction),
			exhaustMap(({ payload, orderId, theAction, actionName }) => {
				return this.orderService.submitAction(payload, orderId, theAction).pipe(
					mergeMap((res: any) => {
						if (
							res.code === 200 &&
							(!res.failures || (res.failures && res.failures.length === 0))
						) {
							return [
								submitActionSuccess({
									id: orderId,
									changes: res,
									action: theAction
								}),
								fromAppActions.appSuccess({
									success: {
										submit_action: tr('Success! Action has been submitted.')
									},
									loadingKey: 'submitAction'
								}),
								loadOrder({ orderId })
							]
						} else {
							let title = null
							const items = []
							_.forEach(res.failures, (item) => {
								// Old format
								if (item.item_name) {
									items.push(item.item_name)
									title = item.error_message
								} else {
									// new format
									title = item.message
									const orderItem = _.find(payload.selectedItems, (o: any) => {
										return o.order_item_number === item.external_id
									})
									if (orderItem) {
										if (
											orderItem.product_snapshot?.translated_names[
												this.translate.currentLang
											]
										) {
											items.push(
												orderItem.product_snapshot.translated_names[
													this.translate.currentLang
												]
											)
										} else {
											items.push(orderItem.product_snapshot?.name)
										}
									}
								}
							})
							// when res.code is 'action_not_allowed' and action is 'return' or 'trace', need to overwrite the title
							if (res.code === 'action_not_allowed') {
								if (
									actionName === 'instantly refund' ||
									actionName === 'cancel'
								) {
									title = tr(
										'The following item(s) are not eligible for a refund:'
									)
								} else if (actionName === 'return') {
									title = tr(
										'The following item(s) are not eligible for a return:'
									)
								} else if (actionName === 'trace') {
									title = tr(
										'The following item(s) are not eligible for a trace:'
									)
								}
							}
							let finalString = ''

							if (items.length) {
								const pleaseRetry = this.translate.instant(
									'Please retry and select only items that are eligible.'
								)
								finalString += '<ul class="list-style-disc">'
								_.forEach(items, (o) => {
									finalString += `<li class="pippo"> ${o} </li>`
								})
								finalString += '</ul>'
								finalString += pleaseRetry
							}

							return [
								fromAppActions.appError({
									error: {
										submit_action: {
											text: title,
											html: finalString
										}
									},
									loadingKey: 'submitAction'
								}),

								submitActionSuccess({
									id: orderId,
									changes: res,
									action: theAction
								})
							]
						}
					}),
					catchError((error) => {
						return of(
							fromAppActions.appError({
								error: {
									submit_action: error
								},
								loadingKey: 'submitAction'
							})
						)
					})
				)
			})
		)
	})

	loadModules$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAppActions.loadModules),
			exhaustMap(() => {
				return this.appService.getModules().pipe(
					mergeMap((data: any) => {
						let details: any = data.detail
						if (details.roles) {
							return [
								fromAppActions.loadModulesSuccess({
									system_permissions: details.roles[0].system_permissions,
									permissions: details.permissions
								}),
								fromAppActions.userUpdated({
									user: {
										id: details.id,
										email: details.email,
										can_login_as: details.can_login_as,
										team: details.roles[0].team,
										is_owner: details.is_owner,
										first_name: details.first_name,
										last_name: details.last_name,
										datepicker_time: details.datepicker_time,
										datepicker_short: details.datepicker_short,
										datepicker_long: details.datepicker_long,
										phone: details.phone,
										title: details.title,
										preferredLanguage: details.preferredLanguage,
										partner_id:details.partner_id
									}
								}),
								fromAppActions.resolveLoading({ loadingKey: 'loginLoading' })
							]
						} else {
							localStorage.setItem('modules', JSON.stringify(details))
							//Dispatch partner call for locked user
							this.store$.dispatch(
								loadPartners({
									params: {
										with_paginator: 0
									}}	)
							)
							return [fromAppActions.clearLoading()]
						}
						// return [
						// 	fromAppActions.loadModulesSuccess({
						// 		system_permissions: details?.roles[0].system_permissions,
						// 		permissions: details.permissions
						// 	}),
						// 	fromAppActions.userUpdated({
						// 		user: {
						// 			id: details.id,
						// 			email: details.email,
						// 			can_login_as: details.can_login_as,
						// 			team: details.roles[0].team,
						// 			is_owner: details.is_owner,
						// 			first_name: details.first_name,
						// 			last_name: details.last_name,
						// 			datepicker_time: details.datepicker_time,
						// 			datepicker_short: details.datepicker_short,
						// 			datepicker_long: details.datepicker_long,
						// 			phone: details.phone,
						// 			title: details.title,
						// 			preferredLanguage: details.preferredLanguage,
						// 			partner_id: details.partner_id
						// 		}
						// 	})
						// ]
					})
				)
			}),
			catchError((err) => {
				return of(fromAppActions.appError({ error: { get_modules: err } }))
			}),
			repeat()
		)
	)

	getCountries$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(countryActions.loadCountries),
			exhaustMap((action) => {
				return this.appService.getSources(['admin', 'country'], action.params)
			}),
			map((res: any) => {
				return countryActions.loadCollectionSuccess({
					data: res.data
				})
			}),
			catchError((err) => {
				return of(fromAppActions.appError({ error: { get_countries: err } }))
			})
		)
	})

	getConfTypes$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadConfigurationTypes),
			exhaustMap((action) => {
				return this.appService.getSources(
					'program-custom-configuration-type',
					action.params
				)
			}),
			map((response: any) => {
				let data: any[] = []
				_.forOwn(response.data, (val) => {
					data = [...data, ...val]
				})
				return configActions.loadCollectionSuccess({
					data
				})
			}),
			catchError((err) => {
				return of(
					fromAppActions.appError({ error: { get_configurations: err } })
				)
			})
		)
	})

	searchSource$ = createEffect(() => {
		let key
		return this.actions$.pipe(
			ofType(searchSources),
			concatMap((action) => {
				key = _.isArray(action.source) ? _.last(action.source) : action.source
				return this.appService.getSources(action.source, action.params)
			}),
			map((response: any) => {
				return updateList({
					key,
					list: _.map(response.data, (item) => {
						if (!item.name) {
							item.name =
								item.display_name || `${item.first_name} ${item.last_name}`
						}
						return item
					})
				})
			}),
			catchError((err) => {
				return of(fromAppActions.appError({ error: { get_sources: err } }))
			})
		)
	})

	loadPartners$ = createEffect(() => {
		const actionParams: any = {}
		return this.actions$.pipe(
			ofType(loadPartners),
			concatMap((action) => {
				actionParams.from = action.params
				return this.appService.getSources('partner', action.params)
			}),
			tap((response: any) => {
				this.appService.saveToLocal('partner', response.data)
			}),
			map((response: any) => {
				return partnerActions.loadCollectionSuccess({
					data: response.data,
					from: actionParams.from
				})
			}),
			catchError((err) => {
				if(err != 'Permission denied.'){
					return of(fromAppActions.appError({ error: { get_partners: err } }))
				}
				return [fromAppActions.clearLoading()]
			}),
			repeat()
		)
	})

	loadOrders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOrders),
			withLatestFrom(
				// this.store$.select(selectCurrent('program')),
				this.store$.select(selectPagination)
			),
			exhaustMap((action) => {
				let act = _.cloneDeep(action)

				let params: any = {
					program_id: this.appService.current.program
				}
				if (act[0].memberId) {
					params.user_id = act[0].memberId
					//Reset search filters when load member's orders
					act[1].search = {}
				}

				return this.orderService.getOrders(act[1], params)
			}),
			mergeMap((res: any) => {
				return [
					loadOrdersSuccess({ orders: res.data, total: res.total }),
					fromAppActions.resolveLoading({ loadingKey: 'advancedLoading' })
				]
			}),
			catchError((error) =>
				of(fromAppActions.appError({ error: { get_orders: error.error } }))
			)
		)
	)

	// TODO: move out the order from here
	loadOrder$ = createEffect(() => {
		const payload: any = {}
		return this.actions$.pipe(
			ofType(loadOrder),
			exhaustMap((action) => {
				payload.orderId = action.orderId
				return this.orderService.getOrder(action.orderId)
			}),
			mergeMap((res: any) => {
				return [loadOrderSuccess({ order: res.data })]
			}),
			catchError((err) =>
				of(
					fromAppActions.appError({
						error: { logout: err }
					}),
					toggleLoading({ orderId: payload.orderId, loading: false })
				)
			),
			repeat()
		)
	})

	logout$ = createEffect(() => {
		let breadcrumb
		return this.actions$.pipe(
			ofType(fromAppActions.logout),
			withLatestFrom(this.store$.select(selectBreadcrumb)),
			tap((data) => {
				breadcrumb = data[1]
			}),
			exhaustMap(() => {
				return this.appService.logout().pipe(
					mergeMap(() => {
						return [
							fromAppActions.resolveLoading({ loadingKey: 'logoutLoading' }),
							fromAppActions.logoutSuccess()
						]
					}),
					tap(() => {
						this.appService.clear(breadcrumb ? _.last(breadcrumb)?.url : null)
					}),
					catchError((err) => {
						return of(
							fromAppActions.appError({
								error: { logout: err },
								loadingKey: 'logoutLoading'
							})
						)
					})
				)
			})
		)
	})

	saveProfile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(fromAppActions.saveProfile),
			exhaustMap((action: any) => {
				return this.appService.saveProfile(action).pipe(
					mergeMap((res: any) => {
						return [
							fromAppActions.saveProfileSuccess({ user: res }),
							fromAppActions.appSuccess({
								success: {
									save_profile: tr('Success! User profile has been updated.')
								},
								loadingKey: 'profileLoading'
							})
						]
					}),
					catchError((err) => {
						return of(
							fromAppActions.appError({
								error: { save_profile: err },
								loadingKey: 'profileLoading'
							})
						)
					})
				)
			}),
			catchError((err) => {
				return of(
					fromAppActions.appError({
						error: { save_profile: err }
					})
				)
			})
		)
	})

	constructor(
		private actions$: Actions,
		private route: ActivatedRoute,
		private store$: Store<AppState>,
		private router: Router,
		private membersService: MembersService,
		private pipe: TranslatedLinks,
		private translate: TranslateService,
		private appService: AppService,
		private orderService: OrdersService
	) {}
}
