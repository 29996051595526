import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { Store } from '@ngrx/store'
import { values } from 'lodash-es'
import { AppState } from '../store/reducers'
import { Injectable } from '@angular/core'
import { appModalAlert, logoutSuccess } from '../store/actions/app.actions'
import { AppService } from '../app.service'
import { tap, catchError } from 'rxjs/operators'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import { authError } from '../auth/store/auth.actions'
import { selectModalAlert } from '../store/reducers/layout.reducer'

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<AppState>,
		private appService: AppService,
		private route: Router
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(
				(error: HttpErrorResponse): Observable<any> => {
					if (
						!(error.error instanceof ErrorEvent) &&
						error.status === 401 &&
						!request.url.includes('login') &&
						!this.route.url.includes('sso')
					) {
						const closeSession = (showWarningOnLoginPage: boolean = false) => {
							const isSSO = !!parseInt(this.appService.getFromLocal('isSSO'), 10)
							this.store.dispatch(logoutSuccess())
							this.appService.clear(isSSO ? null : this.route.url, showWarningOnLoginPage)
							if (isSSO) {
								// Force closing current window
								// this works only if this tab was opened by a script or a link with target="_blank"
								window.close()
							}
						}
						if (!error.url.includes('/modulePermission')) {
							this.store.dispatch(
								appModalAlert({
									modalAlert: {
										sessionExp: {
											showClose: false,
											actions: {
												confirm: {
													onClick: (modal: any) => {
															closeSession()
															modal.close()
													}
												}
											}
										}
									}
								})
							)
							return of(selectModalAlert)
						} else {
							// This for catch token expired error on page refresh
							return of(null).pipe(
								tap(() => {
									closeSession(true)
								})
							)
						}
					}

					if(error.status === 403 && error.error.code === 'locked' ){
						this.store.dispatch(authError({error:'', loadingKey:'passwordExpired'}))
					}

					let finalError
					if (error.status === 500) {
						finalError = _('Internal Server Error')
					} else  {
						if (error?.error?.message) {
							finalError = error.error.message
						} else if (error?.error?.detail) {
							finalError = values(error.error.detail).join('\n')
						} else {
							finalError = error?.message
						}
					}

					return throwError(finalError)
				}
			)
		)
	}
}
