import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on
} from '@ngrx/store'
import { forOwn } from 'lodash-es'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { loadCollectionSuccess } from '../actions/program-configuration-type.actions'

export const configurationTypesStateFeatureKey = 'configurationTypeState'

export interface ConfigurationType {
	id: number
	name: string
}

export interface ConfigurationTypeState extends EntityState<ConfigurationType> {
	search: any
}

export const configurationTypeAdapter: EntityAdapter<ConfigurationType> = createEntityAdapter<ConfigurationType>()

export const configurationTypeInitialState = configurationTypeAdapter.getInitialState(
	{
		search: {}
	}
)

export const configurationTypeReducers = createReducer(
	configurationTypeInitialState,
	on(loadCollectionSuccess, (state, action) => {
		return configurationTypeAdapter.upsertMany(action.data, {
			...state,
			search: {
				...state.search,
				from: action.from || 'api',
				total: action.total
			}
		})
	})
)

export const selectConfigurationTypeFeature = createFeatureSelector<ConfigurationTypeState>(
	configurationTypesStateFeatureKey
)

export const selectAllConfigurationTypes = createSelector(
	selectConfigurationTypeFeature,
	configurationTypeAdapter.getSelectors().selectAll
)

export const selectConfigurationTypeById = (id: number) =>
	createSelector(
		selectConfigurationTypeFeature,
		(state: ConfigurationTypeState) => state.entities[id]
	)
