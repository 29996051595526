import { Component, Input, Output, EventEmitter } from '@angular/core'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

@Component({
	selector: 'toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
	@Input() inputModel: boolean
	@Output() inputModelChange = new EventEmitter<boolean>()
	@Input() enableLabel: string = _('Enable')
	@Input() disableLabel: string = _('Disable')
	@Input() describedBy: string;

	constructor() {}
}
