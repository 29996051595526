import { createAction, props } from '@ngrx/store'
import { User } from '../reducers/user.reducer'
import { Token } from '../../helpers/shared.interfaces'

export const logout = createAction('[Auth] Logout')

export const logoutSuccess = createAction('[Auth] Logout Success')

export const clearStore = createAction('[App] Clear App')

export const loadPartners = createAction(
	'[App] Partners',
	props<{ params?: any }>()
)

export const searching = createAction(
	'[App Search] Searching',
	props<{ key: string; params: any; noSearch: boolean }>()
)

export const updateList = createAction(
	'[App Search] Update List',
	props<{ key: string; list: any[] }>()
)

export const searchSources = createAction(
	'[App] Search Sources',
	props<{ source: string | string[]; params?: any }>()
)

export const resolveLoading = createAction(
	'[App] Loading Done',
	props<{ loadingKey: string }>()
)

export const clearLoading = createAction('[App] Clear Loading Keys')

export const updateBreadcrumb = createAction(
	'[App] Update Breadcrumb',
	props<{ route: string; data: any }>()
)

export const changeLang = createAction(
	'[App] Change Lang',
	props<{ lang: string }>()
)

export const changeTitle = createAction(
	'[App] Change Title',
	props<{ title: string,showAction?: boolean,param?:string }>()
)

export const loadModules = createAction('[App] App Modules')

export const loadModulesSuccess = createAction(
	'[App] App Modules Success',
	props<{ system_permissions: any[]; permissions: any[] }>()
)

export const userUpdated = createAction(
	'[App] User Updated Success',
	props<{ user: User }>()
)

export const updateUserTeam = createAction(
	'[App] User Team Update',
	props<{ allowed_actions: any }>()
)

export const loginSuccess = createAction(
	'[Auth] Login Success',
	props<{ token: Token }>()
)

export const loading = createAction(
	'[App] Loading',
	props<{ loadingKey: string }>()
)

export const changeCurrent = createAction(
	'[App] Can Access',
	props<{ source: string; value: any; fromLocal?: boolean }>()
)

export const addToBreadcrumb = createAction(
	'[App] Add To Breadcrumb',
	props<{ route: { url: number; label: string } }>()
)

export const appError = createAction(
	'[App] App Error',
	props<{ error: any; loadingKey?: string }>()
)

export const appSuccess = createAction(
	'[App] App Success',
	props<{ success: any; loadingKey?: string }>()
)

export const appWarning = createAction(
	'[App] App Warning',
	props<{ warning: any; loadingKey?: string }>()
)

export const changeBodyClass = createAction(
	'[App] Change Class',
	props<{ class: string }>()
)

export const cleanError = createAction(
	'[App] Clean Error',
	props<{ key?: string }>()
)

export const cleanSuccess = createAction(
	'[App] Clean Success',
	props<{ key?: string }>()
)

export const cleanAlerts = createAction('[App] Clean Alerts')

export const NoOpActionsTypes = createAction('[App] No Op action')

export const saveProfile = createAction(
	'[App] Save Profile',
	props<{ payload: any }>()
)

export const saveProfileSuccess = createAction(
	'[App] Profile Save Success',
	props<{ user: User }>()
)
